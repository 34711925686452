import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess:[],
  cities:[],
  addAlbumAudiosSuccessResponse:[],
  photoAlbumresponse:[],
  audioAlbumFileoresponse:[],
  audioAlbumresponse:[],
  getAudioAlbumrespone:[],
  videoAlbumresponse:[],
  SaveAlbumresponse:[],
  SaveCoverImgresponse:[],
  UpdateAlbumresponse:[],
  getVideoAlbumrespone:[],
  videoAlbumFileoresponse:[],
  addAlbumVideoSuccessResponse:[],
  albumsdata:[],
  UpdatePhotoAlbumresponse:[],
  UpdateAudioAlbumresponse:[],
  UpdateVideoAlbumresponse:[],
  DeletePhotoAlbumresponse:[],
  DeletePhotoAlbumDataresponse:[],
  DeleteVideoAlbumDataresponse:[],
  DeleteAudioAlbumDataresponse:[],
  albumsphoto:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getAlbumSuccess(state, action) {
      state.isLoading = false;
      state.albumsdata = action.payload;
    },
    createPhotoAlbumSuccess(state, action) {
      state.isLoading = false;
      state.photoAlbumresponse = action.payload;
    },
    DeletePhotoAlbumSuccess(state, action) {
      state.isLoading = false;
      state.DeletePhotoAlbumresponse = action.payload;
    },
    DeletePhotoAlbumDataSuccess(state, action) {
      state.isLoading = false;
      state.DeletePhotoAlbumDataresponse = action.payload;
    },
    DeleteAudioAlbumDataSuccess(state, action) {
      state.isLoading = false;
      state.DeleteAudioAlbumDataresponse = action.payload;
    },
    DeleteVideoAlbumDataSuccess(state, action) {
      state.isLoading = false;
      state.DeleteVideoAlbumDataresponse = action.payload;
    },
    getAlbumPhotoSuccess(state, action) {
      state.isLoading = false;
      state.albumsphoto = action.payload;
    },
   
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
    
    addAlbumAudiosSuccess(state, action) {
      state.isLoading = false;
      state.addAlbumAudiosSuccessResponse = action.payload;
    },
    addAlbumPhotosSuccess(state, action) {
      state.isLoading = false;
      state.SaveAlbumresponse = action.payload;
    },
   UpdateCoverImgSuccess(state, action) {
      state.isLoading = false;
      state.SaveCoverImgresponse = action.payload;
    },
    updateAlbumPhotosSuccess(state, action) {
      state.isLoading = false;
      state.UpdateAlbumresponse = action.payload;
    },
    createAudioAlbumSuccess(state, action) {
      state.isLoading = false;
      state.audioAlbumresponse = action.payload;
    },
    UpdatePhotoAlbumSuccess(state, action) {
      state.isLoading = false;
      state. UpdatePhotoAlbumresponse = action.payload;
    },
    UpdateAudioAlbumSuccess(state, action) {
      state.isLoading = false;
      state. UpdateAudioAlbumresponse = action.payload;
    },
    UpdateVideoAlbumSuccess(state, action) {
      state.isLoading = false;
      state. UpdateVideoAlbumresponse = action.payload;
    },
    getAudioAlbumSuccess(state, action) {
      state.isLoading = false;
      state.getAudioAlbumrespone = action.payload;
    },
    audioAlbumFileSuccess(state, action) {
      state.isLoading = false;
      state.audioAlbumFileoresponse = action.payload;
    },

    createVideoAlbumSuccess(state, action) {
      state.isLoading = false;
      state.videoAlbumresponse = action.payload;
    },
    getVideoAlbumSuccess(state, action) {
      state.isLoading = false;
      state.getVideoAlbumrespone = action.payload;
    },
    videoAlbumFileSuccess(state, action) {
      state.isLoading = false;
      state.videoAlbumFileoresponse = action.payload;
    },
    addAlbumVideoSuccess(state, action) {
      state.isLoading = false;
      state.addAlbumVideoSuccessResponse = action.payload;
    },

    userAgentUpdateSuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    
  },
});


// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------
 
export function createAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/create_talent_album/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.createPhotoAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function DeletePhotoAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_talent_albums/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.DeletePhotoAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function DeletePhotoAlbumData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_album_data/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.DeletePhotoAlbumDataSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function DeleteAudioAlbumData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_album_data/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.DeleteAudioAlbumDataSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function DeleteVideoAlbumData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_album_data/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.DeleteVideoAlbumDataSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function getAlbums(data) {
    const tokenVal = window.localStorage.getItem("accessToken")
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_talent_albums/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function getAlbumPhotos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_album_photos/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getAlbumPhotoSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function addAlbumPhotos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/add_album_photos/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.addAlbumPhotosSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }
  export function UpdateisCoverPhoto(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_cover_image/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.UpdateCoverImgSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function updateAlbumPhotos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/add_album_photos/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.updateAlbumPhotosSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function createAudioAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/create_talent_album/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.createAudioAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function UpdatePhotoAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_talent_album/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.UpdatePhotoAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function UpdateAudioAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_talent_album/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.UpdateAudioAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function UpdateVideoAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_talent_album/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.UpdateVideoAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function getAudioAlbums (data) {
    const tokenVal = window.localStorage.getItem("accessToken")
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_talent_albums/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getAudioAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function getAudioAlbumPhotos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_album_photos/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.audioAlbumFileSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }
  
  export function addAlbumAudios(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/add_album_photos/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.addAlbumAudiosSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function createVideoAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/create_talent_album/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.createVideoAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function getVideoAlbums (data) {
    const tokenVal = window.localStorage.getItem("accessToken")
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_talent_albums/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getVideoAlbumSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function getVideoAlbumFiles(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_album_photos/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.videoAlbumFileSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }
  
  export function addAlbumVideos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/add_album_photos/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.addAlbumVideoSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }


  export function clearHasErrorAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearaddAlbumPhotos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.addAlbumPhotosSuccess([]));
      
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  export function clearaddAlbumUpdatePhotos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        
        dispatch(slice.actions.UpdateCoverImgSuccess([]));
          
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearupdateAlbumPhotos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.updateAlbumPhotosSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearUpdatePhotoAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.UpdatePhotoAlbumSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearUpdateAudioAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.UpdateAudioAlbumSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearaddAlbumAudiosSuccess(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.addAlbumAudiosSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearaddAlbumVideoSuccess(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.addAlbumVideoSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearUpdateVideoAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.UpdateVideoAlbumSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearDeletePhotoAlbum(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.DeletePhotoAlbumSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearDeletePhotoAlbumData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.DeletePhotoAlbumDataSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  

  export function clearDeleteAudioAlbumData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.DeleteAudioAlbumDataSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearDeleteVideoAlbumData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.DeleteVideoAlbumDataSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function cleargetAlbumPhotoSuccess(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getAlbumPhotoSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearcreateAlbumResp(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.createPhotoAlbumSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function clearcreateAudioAlbumResp(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.createAudioAlbumSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function clearcreateVideoAlbumResp(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.createVideoAlbumSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  