import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
//
import { useDispatch, useSelector } from '../../../redux/store';
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
};

export function NavListRoot({ list, isCollapse }) {
  const { pathname } = useLocation();
 
  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);
  const [leftMenuName, setLeftMenuName] = useState([]);
  const hasChildren = list.children;
  useEffect(() => {
    if(localStorage.getItem('leftMenu') && localStorage.getItem('leftMenu')!=="")
    {
      setLeftMenuName(JSON.parse(localStorage.getItem('leftMenu')));
    }
  },[localStorage.getItem('leftMenu')]) 
 
 // localStorage.removeItem('leftMenu');
 
  if (hasChildren) {
    return (
      <>
        <NavItemRoot item={list} isCollapse={isCollapse} active={active} open={open} onOpen={() => setOpen(!open)} />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              
                {  (list.children || []).map((item) => (
                <NavListSub key={item.title} list={item} />
              )) }
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
};

function NavListSub({ list }) {
   const { checkTkMenu } = useSelector((state) => {
    return {
      checkTkMenu : state && state.checkMenu && state.checkMenu.getMunuCheckData && state.checkMenu.getMunuCheckData.response && state.checkMenu.getMunuCheckData.response.user_industries_primary
  };
}); 

  const { pathname } = useLocation();
  const [disableMenu,setDisableMenu] = useState(true)
  const active = getActive(list.path, pathname);
  const [blurredMenu,setBlurredMenu] = useState(false)
  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  const dumArray = [
    {type : "Skillset"},
    {type : "Profile Intro"}
  ]

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} sx={{color:active && active === true ? '#FFC300' : ''}} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }} >
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title} item={item} active={getActive(item.path, pathname)} sx={{color:active && active === true ? '#FFC300' : ''}} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  // if(list && list.title === "Profile" ){
  //   if(checkTkMenu && checkTkMenu.isSelected === true){
  //    console.log("tryeeeeeeeee",list)
  //   }
  // }
 
  return <NavItemSub item={list} active={active} blur={blurredMenu} checkTkMenu={checkTkMenu}/>;
}
