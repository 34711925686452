import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess:[],
  pinCode:[],
  cities:[],
  profileImage:[],
 dashboardIntro:[],
  UpdatedRes:[],
  updateProfilePicRes:[],
  profileImagelog:[],
  isVerifySuccsData:[],
  talentlogoutRes:[],
  termCondRes:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    isVerifySuccess(state, action) {  
      state.isLoading = false;
      state.isVerifySuccsData = action.payload;
    }, 

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
    getStateSuccess(state, action) {
      state.isLoading = false;
      state.statess = action.payload;
    },
    getPinSuccess(state, action) {
      state.isLoading = false;
      state.pinCode = action.payload;
    },
    setProfileImage(state, action) {
      state.isLoading = false;
      state.profileImage = action.payload;
    },
    setDashboardSuccess(state, action) {
      state.isLoading = false;
      state.dashboardIntro = action.payload;
    },
    setProfileForLogoutLogo(state, action) {
      state.isLoading = false;
      state.profileImagelog = action.payload;
    },
    getCitySuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },
    ProfileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.UpdatedRes = action.payload;
    },
    updateProfilePicSucc(state, action) {
      state.isLoading = false;
      state.updateProfilePicRes = action.payload;
    },
    // TermCond
    TermCondSuccess(state, action) {
      state.isLoading = false;
      state.termCondRes = action.payload;
    },
    talentlogoutSucc(state,action) {
      state.isLoading = false;
      state.talentlogoutRes = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
  
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

export function getIsVerifySlug() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get('/is_verify_slug/');
      dispatch(slice.actions.isVerifySuccess(response.data)); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearisVerifySucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.isVerifySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getProfileData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/personal_information/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getEventsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function setAvatarImage(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_profile_pic/', data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.setProfileImage(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function setDashboardData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const tokenVal = window.localStorage.getItem("accessTokenTalent")
      const response = await axios.get('/get_talent_dashboard/', tokenVal);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.setDashboardSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function setAvatarProfileLogout(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setProfileForLogoutLogo(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearGetProfileData() {
  const tokenVal = window.localStorage.getItem("accessTokenTalent")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEventsSuccess([]));
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getState(countryData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const CoutryData = {
      country_name : countryData
    }
    try {
      const response = await axios.post('/get_states/', CoutryData);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getStateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getPinNo(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_pincode_location/', data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getPinSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getCity(countryAndstateId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_cities/', countryAndstateId);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getCitySuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_personal_information/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.ProfileUpdateSuccess(response.data))
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateProfilePic(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_profile_pic/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.updateProfilePicSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function talentLogout(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/logout/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.talentlogoutSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getTermCondData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const tokenVal = window.localStorage.getItem("accessTokenTalent")
      const response = await axios.get('/get_terms_conditions/', tokenVal);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.TermCondSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ClearTermAndCond() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.TermCondSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearUpdateProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ProfileUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPindata(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPinSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPreviousImg(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setProfileImage([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearDashboardSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setDashboardSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function createEvent(newEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/new', newEvent);
//       dispatch(slice.actions.createEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function updateEvent(eventId, updateEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/update', {
//         eventId,
//         updateEvent,
//       });
//       dispatch(slice.actions.updateEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function deleteEvent(eventId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post('/api/calendar/events/delete', { eventId });
//       dispatch(slice.actions.deleteEventSuccess({ eventId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function selectRange(start, end) {
//   return async () => {
//     dispatch(
//       slice.actions.selectRange({
//         start: start.getTime(),
//         end: end.getTime(),
//       })
//     );
//   };
// }
