import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  saveForgot:[],
  saveResetPassRes:[],
  passSaveForgot:[]
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    saveResponseForgot(state, action) {
        state.isLoading = false;
        state.saveForgot = action.payload;
    },
    passSaveResponseForgot(state, action) {
      state.isLoading = false;
      state.passSaveForgot = action.payload;
    },
    saveResetResp(state, action) {
      state.isLoading = false;
      state.saveResetPassRes = action.payload;
  },
   // CREATE EVENT
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function SaveForgotReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/check_forgot_pass_user/',data);
      dispatch(slice.actions.saveResponseForgot(response.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      
      // if( error === "Something went wrong" ){
      //   // for handling the dispatch we use 502 as a dummy
      //   dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      // }

      if((error && error.status !== "") && (error.status !== undefined) ){
        // setError('afterSubmit', error);
        dispatch(slice.actions.hasError(error));
      } else{
        // setError('afterSubmit', {status:"",message:"Something went wrong"});
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function PassDataSaveForgotPass(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.passSaveResponseForgot(data));
    } catch (error) {
      dispatch(slice.actions.passSaveResponseForgot(error));
    }
  };
}

export function ResetNewPassReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/forgot_password/',data);
      dispatch(slice.actions.saveResetResp(response.data));
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        // setError('afterSubmit', error);
        dispatch(slice.actions.hasError(error));
      } else{
        // setError('afterSubmit', {status:"",message:"Something went wrong"});
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      
    }
  };
}

export function ClearResetNewPassReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveResetResp([]));
    } catch (error) {
      dispatch(slice.actions.saveResetResp(error));
    }
  };
}

export function ClearSaveForgotReq(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.saveResponseForgot([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }