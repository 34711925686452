import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  talentData: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  getSearchSuccessRes:[],
  catData:[],
  createAdvanceSearchRes:[],
  modalGetData:[]
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getCatSucc(state, action) {
      state.isLoading = false;
      state.catData = action.payload;
    }, 
   // CREATE EVENT
   getSearchSuccess(state, action) {
    state.isLoading = false;
    state.getSearchSuccessRes = action.payload;     
},
createAdvanceSearchSuccs(state, action) {
    state.isLoading = false;
    state.createAdvanceSearchRes = action.payload;     
},
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getSearchData() { 
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.get('/get_all_keywords/');
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getSearchSuccess(response.data))
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

export function getCatData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_talent_type/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getCatSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
} 

export function createAdvanceSearch(data){
    return async ()=>{
      dispatch (slice.actions.startLoading());
  
      try {
        const response = await axios.post('/advanced_search/', data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.createAdvanceSearchSuccs(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      }catch (error){
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    }
  }

  export function clearAdvanceSearchResponse() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.createAdvanceSearchSuccs([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function cleargetSearch() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getSearchSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }