import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import ThemeLayout from '../layouts/themeLayout';
import ProfileThemeLayout from '../layouts/ProfileThemeLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import { PATH_AUTH, PATH_DASHBOARD } from './paths';
// components
import LoadingScreen from '../components/LoadingScreen';
import useResponsive from '../hooks/useResponsive';



// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [isDesktop, setIsDesktop] = useState(true);
  const UserHaveAgent = localStorage.getItem('agentIdLogin');
  const AfterLoginPathWithAgent = `${PATH_DASHBOARD.user.AgentInviReqDetails}/${UserHaveAgent}`;

  const currentTab = useResponsive('up', 'sm');
  // const isDesktop = useResponsive('up', 'sm');
  useEffect(() => {
    if (currentTab && currentTab !== '' && currentTab !== undefined && currentTab === true || currentTab === false) {
      setIsDesktop(currentTab);
    }
  }, [currentTab]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    })
  }, [width])

  console.log(isDesktop, '54542', currentTab);

  return useRoutes([
    {
      // path: '',
      children: [
        { element: <Navigate to="/login" replace />, index: true },
        {

          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),

        },
        {
          path: 'register',
          element: (
            <Register />
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'invite', element: (<Register />), },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-confirm-password', element: <ResetConfirmPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'user-done', element: <UserDone /> },
        { path: 'location-pdf', element: <LocationPdf /> },
      ],
    },
    // User Profile
    {
      path: '/:userId',
      children: [
        {
          path: '',
          element: (
            isDesktop && isDesktop === true ? <LocationDetailsPage /> : <LocationDetailsPageMobile />
          ),
        },
      ],

    },
    {
      path: '/profile-details/:slugname',
      children: [
        {
          path: '',
          element: (
            isDesktop && isDesktop === true ? <ProfileDetailsPage /> : <ProfileDetailsPageMobile />
          ),
        },
      ],

    },
    // {
    //   path: '/:userId',
    //   children: [
    //     {
    //       path: '',
    //       element: (
    //         <LocationDetailsPage />
    //       ),
    //     },
    //   ],

    // },
    // User Profile
    {
      path: '/resumePreview/:resumeId',
      children: [
        {
          path: '',
          element: (
            <ResumePreview />
          ),
        },
      ],

    },
    //  { path: 'resumePreview/:resumeId', element: <ResumePreview/> },
    // Dashboard Routes
    {
      // path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
        // <DashboardLayout />
      ),
      children: [
        // { element: <Navigate to={UserHaveAgent && UserHaveAgent!=='' || UserHaveAgent!==undefined || UserHaveAgent!==null ?AfterLoginPathWithAgent: PATH_AFTER_LOGIN} replace />, index: true },
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'dashboard', element: <GeneralApp /> },
        {
          path: 'dashboard',
          element: isDesktop ? <GeneralApp /> : <GeneralAppMobile />
        },
        {
          path: 'profile',
          children: [
            { element: <Navigate to="/profile/profile" replace />, index: true },
            // { path: 'personal-info', element: <UserCreate /> },
            // { path: 'category', element: <UserCategory /> },
            // { path: 'category/sub-category', element: <UserSubCategory /> },
            // { path: 'category/sub-category/talent-type', element: <TalentType /> },
            // { path: 'language-industry', element: <LanguageInd /> },
            // { path: 'profession/:isAddMore', element: <Profession /> }, 
            // { path: 'profession-industry', element: <ProfessionIndustry /> },
            // { path: 'profession-category/:flag/:professionId/:professionRoleType', element: <ProfessionCategory /> },
            // { path: 'profession-category/:flag/:professionId', element: <ProfessionCategory /> },
            // { path: 'profession-industry/:professionId/:professionRoleType', element: <ProfessionIndustry /> },
            // { path: 'profession-industry/:professionId', element: <ProfessionIndustry /> },
            // { path: 'profession-data', element: <ProfessionData /> },
            // { path: 'education-training', element: <EduAndTraining /> },
            // { path: 'physical', element: <PhysicalApp /> },
            // { path: 'physical/:professionId', element: <PhysicalApp /> },
            // { path: 'skillset', element: <SkillsetInterest /> },
            // { path: 'skillset/:isFlag/:professionId', element: <SkillsetInterest /> },
            // { path: 'skillset/:professionId', element: <SkillsetInterest /> },

            { path: 'personal-info', element: isDesktop ? <UserCreate /> : <UserCreateMobile /> },
            { path: 'category', element: isDesktop ? <UserCategory /> : <UserCategoryMobile /> },
            { path: 'category/sub-category', element: isDesktop ? <UserSubCategory /> : <UserSubCategoryMobile /> },
            { path: 'category/sub-category/talent-type', element: isDesktop ? <TalentType /> : <TalentTypeMobile /> },
            { path: 'language-industry', element: isDesktop ? <LanguageInd /> : <LanguageIndMobile /> },
            { path: 'profession/:isAddMore/:professionId', element: isDesktop ? <Profession /> : <ProfessionMobile /> },
            { path: width > 600 ? 'profession-category/:flag/:professionId' : 'profession-category/:flag/:professionId/:pageFlag', element: isDesktop ? <ProfessionCategory /> : <ProfessionCategoryMobile /> },
            { path: width > 600 ? 'profession-industry/:professionId' : 'profession-industry/:professionId/:pageFlag', element: isDesktop ? <ProfessionIndustry /> : <ProfessionIndustryMobile /> },
            { path: 'profession-data', element: isDesktop ? <ProfessionData /> : <ProfessionDataMobile /> },
            { path: 'education-training', element: isDesktop ? <EduAndTraining /> : <EduAndTrainingMobile /> },
            { path: width > 600 ? 'physical/:professionId' : 'physical/:professionId/:pageFlag', element: isDesktop ? <PhysicalApp /> : <PhysicalAppMobile /> },
            { path: 'skillset/:isFlag/:professionId', element: isDesktop ? <SkillsetInterest /> : <SkillsetInterestMobile /> },
            { path: 'Crop', element: <CropImage /> },
            {
              path: 'portfolio',
              children: [
                // { path: 'portfolio', element: <PortMain /> 
                { element: <Navigate to="/profile/portfolio/imageUpload" replace />, index: true },
                { path: 'imageUpload', element: isDesktop ? <PortMain /> : <PortMainMobile /> },
                // { path: 'photos', element: <Photos /> },
                { path: 'photos', element: isDesktop ? <Photos /> : <PhotosMobile /> },
                // { path: 'photo-album', element: <Album /> },
                { path: 'photo-album', element: isDesktop ? <Album /> : <AlbumMobile /> },
                // { path: 'photo-album-organizer', element: <Albumphotoorganization /> },
                { path: 'photo-album-organizer', element: isDesktop ? <Albumphotoorganization /> : <AlbumphotoorganizationMobile /> },
                // { path: 'imageAlbumUpload', element: <AlbumUpload /> },
                { path: 'imageAlbumUpload', element: isDesktop ? <AlbumUpload /> : <AlbumUploadMobile /> },
                // { path: 'photo-album-add', element: <AlbumListPage /> },
                { path: 'photo-album-add', element: isDesktop ? <AlbumListPage /> : <AlbumListPageMobile /> },
                // { path: 'photo-album-edit', element: <EditPhotoAlbum /> },
                { path: 'photo-album-edit', element: isDesktop ? <EditPhotoAlbum /> : <EditPhotoAlbumMobile /> },
                // { path: 'photoUpload', element: <PhotoUpload /> },
                { path: 'photoUpload', element: isDesktop ? <PhotoUpload /> : <PhotoUploadMobile /> },
                // audio
                // { path: 'audioalbumUpload', element: <AudioAlbumUpload /> },
                { path: 'audioalbumUpload', element: isDesktop ? <AudioAlbumUpload /> : <AudioAlbumUploadMobile /> },
                // { path: 'audioUpload', element: <AudioUpload /> },
                { path: 'audioUpload', element: isDesktop ? <AudioUpload /> : <AudioUploadMobile /> },
                // { path: 'audios', element: <AudioGallery /> },
                { path: 'audios', element: isDesktop ? <AudioGallery /> : <AudioGalleryMobile /> },
                // { path: 'audio-album', element: <AudioAlbum /> },
                { path: 'audio-album', element: isDesktop ? <AudioAlbum /> : <AudioAlbumMobile /> },
                // { path: 'audio-album-add', element: <AlbumAudioListPage /> },
                // { path: 'audio-album-add/:id', element: <AlbumAudioListPage />},
                { path: 'audio-album-add', element: isDesktop ? <AlbumAudioListPage /> : <AlbumAudioListPageMobile /> },
                // { path: 'audio-album-add/:id', element: isDesktop ? <AlbumAudioListPage /> : <AlbumAudioListPageMobile /> },
                // { path: 'audio-album-edit', element: <AlbumAudioEdit /> },
                { path: 'audio-album-edit', element: isDesktop ? <AlbumAudioEdit /> : <AlbumAudioEditMobile /> },
                // { path: 'audio-album-organizer', element: <AudioAlbumOrganization /> },
                { path: 'audio-album-organizer', element: isDesktop ? <AudioAlbumOrganization /> : <AudioAlbumOrganizationMobile /> },
                // video
                // { path: 'videos', element: <Videos /> },
                { path: 'videos', element: isDesktop ? <Videos /> : <VideosMobile /> },
                // { path: 'videoUpload', element: <VideoUpload /> },
                { path: 'videoUpload', element: isDesktop ? <VideoUpload /> : <VideoUploadMobile /> },
                // { path: 'videoUploadalbum', element: <UploadAlbumVideo /> },
                { path: 'videoUploadalbum', element: isDesktop ? <UploadAlbumVideo /> : <UploadAlbumVideoMobile /> },
                // { path: 'video-album', element: <VideosAlbum /> },
                { path: 'video-album', element: isDesktop ? <VideosAlbum /> : <VideosAlbumMobile /> },
                // { path: 'video-album-add', element: <VideoAlbumList /> },
                { path: 'video-album-add', element: isDesktop ? <VideoAlbumList /> : <VideoAlbumListMobile /> },
                // { path: 'video-album-edit', element: <VideoAlbumEdit /> },
                { path: 'video-album-edit', element: isDesktop ? <VideoAlbumEdit /> : <VideoAlbumEditMobile /> },
                // { path: 'video-album-organizer', element: <VideosAlbumOrganization /> },
                { path: 'video-album-organizer', element: isDesktop ? <VideosAlbumOrganization /> : <VideosAlbumOrganizationMobile /> },
                // Awards
                // { path: 'awards', element: <Awards /> },
                { path: 'awards', element: isDesktop ? <Awards /> : <AwardsMobile /> },
                // { path: 'award-add', element: <AwardUpload /> },
                { path: 'award-add', element: isDesktop ? <AwardUpload /> : <AwardUploadMobile /> },
                // { path: 'awardAlbumOrganization', element: <AwardsAlbumOrganization /> }
                { path: 'awardAlbumOrganization', element: isDesktop ? <AwardsAlbumOrganization /> : <AwardsAlbumOrganizationMobile /> },
              ]
            },
            { path: 'work/:professionId', element: isDesktop ? <WorkCredit /> : <WorkCreditMobile /> },
            // { path: 'work', element: <WorkCredit /> },
            { path: 'work/:professionId', element: isDesktop ? <WorkCredit /> : <WorkCreditMobile /> },
            // { path: 'profile-intro', element: <UserAccount /> },
            { path: 'profile-intro', element: isDesktop ? <UserAccount /> : <UserAccountMobile /> },
            // { path: 'profession-agent/:professionId', element: <AgentProfession /> },
            { path: 'profession-agent/:professionId', element: isDesktop ? <AgentProfession /> : <AgentProfessionMobile /> },
            // { path: 'profession-agents', element: <ListAgentProfession /> },
            { path: 'profession-agents', element: isDesktop ? <ListAgentProfession /> : <ListAgentProfessionMobile /> },
            // { path: 'profession-agent-invitation', element: <AgentProfessionInvitation /> },
            { path: 'profession-agent-invitation', element: isDesktop ? <AgentProfessionInvitation /> : <AgentProfessionInvitationMobile /> },
            // { path: 'profession-agent-invitation-details', element: <AgentProfessionInviReqDetails /> },
            { path: 'profession-agent-invitation-details', element: isDesktop ? <AgentProfessionInviReqDetails /> : <AgentProfessionInviReqDetailsMobile /> },

            // { path: 'agent-invitation-details/:agentId', element:<AgentInviDetails /> },
            // { path: 'agent-invitation-details/:agentId/:professionId', element: <AgentInviDetails /> },
            { path: 'agent-invitation-details/:agentId/:professionId', element: isDesktop ? <AgentInviDetails /> : <AgentInviDetailsMobile /> },
            // { path: 'agent-details/:agentId', element: <AgentDetails /> },
            { path: 'agent-details/:agentId', element: isDesktop ? <AgentDetails /> : <AgentDetailsMobile /> },
            // { path: 'agent-details-edit/:agentId', element: <EditAgentProfession /> },
            { path: 'agent-details-edit/:agentId', element: isDesktop ? <EditAgentProfession /> : <EditAgentProfessionMobile /> },
            // { path: 'profession-agent-detail/:agentId', element: <ExitAgentProfession /> },
            { path: 'profession-agent-detail/:agentId', element: isDesktop ? <ExitAgentProfession /> : <ExitAgentProfessionMobile /> },
            {
              path: 'main',
              children: [
                // { path: 'portfolio', element: <PortMain /> 
                { element: <Navigate to="/profile/main/agent-info" replace />, index: true },
                // { path: 'agent-info/:professionId/:agentId', element: <UserAgentInfo /> },
                { path: 'agent-info/:professionId/:agentId', element: isDesktop ? <UserAgentInfo /> : <UserAgentInfoMobile /> },
                // { path: 'agent-info', element: < UserAgentInfo/> },
                // { path: 'user-done', element: <UserDonePage /> },
                { path: 'user-done', element: isDesktop ? <UserDonePage /> : <UserDonePageMobile /> },
              ]
            },
            { path: 'user-done', element: isDesktop ? <UserDonePage /> : <UserDonePageMobile /> },
            { path: 'themeChoose', element: <ThemeChoose /> },
            { path: 'job-search', element: <UserJobSearch /> },
            { path: 'job-seek', element: <UserJobSeek /> },
            { path: 'jobApplication', element: <UserJobApplication /> },
            { path: 'recommendations', element: <UserRecommendations /> },
            { path: 'pause-job-search', element: <UserPauseJobSearch /> },
          ],
        },
        {
          path: '/jobs',
          children: [
            { element: <Navigate to="/jobs/job-search" replace />, index: true },

            { path: 'job-search', element: <UserJobSearch /> },
            { path: 'job-seek', element: <UserJobSeek /> },
            { path: 'job-seek-enable/:encId/:slugName', element: <JobSeekEnable /> },
            { path: 'jobApplication', element: <UserJobApplication /> },
            { path: 'jobApplied-dashboard/:jobid', element: <UserAppliedJob /> },
            { path: 'recommendations', element: <UserRecommendations /> },
            { path: 'pause-job-search', element: <UserPauseJobSearch /> },
            // extar tab pages
            { path: 'Reviewed', element: <JobReviewed /> },
            { path: 'Contacting', element: <JobContacting /> },
            { path: 'Rejected', element: <JobRejected /> },
            { path: 'Hired', element: <JobHired /> },
            { path: 'Interested', element: <JobInterested /> },
            { path: 'Hold', element: <JobHold /> },
            { path: 'Interview', element: <JobInterviews /> },
            { path: 'Expired', element: <JobExpired /> },
          ],
        },
        // {
        //   path: 'portfolio',
        //   children: [
        //     { path: 'gallery', element: <Gallery />},
        //     { path: 'photos', element: <Photos />}, 
        //     { path: 'album', element: <Album />}, 
        //     { path: 'Portfolio', element: <Portfolio />},
        //     { path: 'portfolioImageGallery', element: <PortfolioImageGallery />},  
        //     { path: 'audioGallery', element: <AudioGallery />},
        //     { path: 'audioAlbum', element: <AudioAlbum />},
        //     { path: 'audioUpload', element: <AudioUpload />},
        //     { path: 'portfolioAudio', element: <PortfolioAudio />},
        //     { path: 'awards', element: <Awards/>},
        //     { path: 'portfolioAwards', element: <PortfolioAwards/>},
        //     { path: 'albumphotoorganization', element: <Albumphotoorganization/>},
        //     { path: 'videoUpload', element: <VideoUpload/>},
        //     { path: 'photoUpload', element: <PhotoUpload/>},
        //     { path: 'videoGallery', element: <VideoGallery/>},
        //     { path: 'videos', element: <Videos/>},
        //     { path: 'videosAlbum', element: <VideosAlbum/>},
        //     { path: 'videosAlbumOrganization', element: <VideosAlbumOrganization/>},
        //     { path: 'audioAlbumOrganization', element: <AudioAlbumOrganization/>},
        //     { path: 'awardsUpload', element: <AwardUpload/>},
        //     { path: 'awardGallery', element: <AwardGallery/>},
        //     { path: 'awardAlbum', element: <AwardAlbum/>},
        //     { path: 'awardAlbumOrganization', element: <AwardsAlbumOrganization/>}
        //    ],
        // },

        // {
        //   path: '/setting',
        //   element: (
        //     <SettingPageMain />
        //   ),
        // },
        {
          path: 'setting',
          children: [
            { element: <Navigate to="/setting/account" replace />, index: true },
            // { path: 'insProfile', element: <SettingPageMain /> },
            { path: 'account', element: <SettingAccount /> },
            { path: 'notification', element: <SettingNotification /> },
            { path: 'profileVisibility', element: <SettingProfileVisibility /> },
            { path: 'manageAccount', element: <SettingManageAccount /> },
            { path: 'userAgent', element: <SettingUserAgent /> },
            // { path: 'subscription/:name', element: <SubscriptionPlans /> },
            { path: 'subscription', element: <SubscriptionPlans /> },
            // { path: 'subscription/plans', element: <SubscriptionPlans /> },
            // { path: 'subscription/subscriptionTable', element: <SubscriptionTable /> },
            { path: 'subscription/billingInfo', element: <BillingInfo /> },
            { path: 'subscription/invoiceDetails/:InvId', element: <InvoiceDetails /> },
            // { path: 'subscription/invoiceDetails', element: <InvoiceDetails /> },
            { path: 'payment', element: <SettingPayment /> },
          ],
        },
        {
          path: '/my-project',
          element: (
            <MyProjectPage />
          ),
        },
        {
          path: '/my-project/List',
          element: (
            <MyProjectList />
          ),
        },

        {
          path: '/my-project/view/:id/:encId',
          element: (
            <MyProjectView />
          ),
        },
        {
          path: '/activity-log',
          element: (
            <ActivityLogPage />
          ),
        },
        {
          path: '/theme',
          children: [
            { element: <Navigate to="/theme/templates" replace />, index: true },
            { path: 'templates', element: <Templates /> },
            { path: 'resumes', element: <Resumes /> },
            { path: 'resumeCreate', element: <ResumeCreate /> },
            // { path: 'resumeTemplates/:professionName/:resumeName', element: <ResumeTemplates/> },
            { path: 'resumeTemplates/:professionName', element: <ResumeTemplates /> },
            // { path: 'resumeTemplateData/:professionName/:templateId/:slugName/:resumeId', element: <ResumeTemplateData/> },
            // { path: 'resumeTemplateData/:professionName/:resumeName/:templateId/:slugName/:resumeId', element: <ResumeTemplateData/> },

            // { path: 'resumePreview/:resumeId', element: <ResumePreview/> },
            { path: 'coverLetter', element: <CoverLetter /> },
            { path: 'CreateCoverLetter/:coverId/:slugName', element: <CreateCoverLetter /> },

            { path: 'themeCreate', element: <ThemeCreate /> },

            { path: 'themeCreation/:slugName/:tId', element: <ThemePageSecond /> },
            // { path: 'themeView/:slugName/:id/:tId/:TempId', element: <ThemePages /> },
          ]
        },

        {
          path: '/jobs',
          children: [
            { element: <Navigate to="/jobs/job_search" replace />, index: true },
            { path: 'job_search', element: <Quick /> },
            { path: 'advanced', element: <Advanced /> },
            { path: 'saved', element: <Saved /> },
            { path: 'details', element: <Details /> },
            // { path:'quick-search/:searchId/:flag/:searchparameters', element: <QuickList/>},
            { path: 'quick-search/:flag/:searchparameters', element: <QuickList /> },
            // { path:'quick-search', element: <QuickList/>},

          ]
        },
        { path: '/calendar', element: <CalendarMain /> },
        { path: '/subscription/plans', element: <Subscription /> },
        { path: '/networking', element: <Networking /> },
        {
          path: '/inbox',
          children: [
            // { element: <Navigate to="/talent/inbox/inbox" replace />, index: true },
            { path: '', element: <Inbox /> },
            { path: 'notification', element: <Notifications /> },
            { path: 'enquiry', element: <Enquiry /> },

          ]
        },
        {
          path: '/network/',
          children: [
            { path: 'network', element: <Follow /> },
            // { path: 'followers', element: <Followers /> },
            // { path: 'following', element: <Following /> },
            {
              path: "followers",
              children: [
                { element: <Navigate to="/network/followers/talents" replace />, index: true },
                { path: "talents", element: <TalentsFollowers /> },
                { path: "companies", element: <CompaniesFollowers /> },
                { path: "institution", element: <InstitutionFollowers /> },

              ],
            },
            {
              path: "following",
              children: [
                { element: <Navigate to="/network/following/talents" replace />, index: true },
                { path: "talents", element: <TalentsFollowing /> },
                { path: "companies", element: <CompaniesFollowing /> },
                { path: "institution", element: <InstitutionFollowing /> },

              ],
            },
          ],
        },
        {
          path: '/network/connect',
          children: [
            { element: <Connect />, index: true },
            { path: 'new', element: <Connect /> },
            { path: ':conversationKey', element: <Connect /> },
          ],
        },

      ],
    },

    // {
    //   path: '/talent',
    //   element: (
    //     <GuestGuard>
    //       <Login />
    //     </GuestGuard>
    //   ),
    // },
    {
      path: '/theme',
      element: <ThemeLayout />,
      children: [
        { element: <Navigate to="/theme/templates" replace />, index: true },
        { path: 'resumeTemplateData/:professionName/:templateId/:slugName/:resumeId', element: <ResumeTemplateData /> },
        { path: 'resumeTemplateRecreate/:professionName/:templateId/:slug/:slugName/:resumeId', element: <ResumeTemplateRecreate /> },
        { path: 'CoverLetterView/:coverId', element: <CoverLetterView /> },
        // { path: 'themeView/:slugName/:id/:tId/:TempId', element: <ThemePages /> }
        { path: 'themeView/:slugName/:id/:tId/:TempId', element: isDesktop ? <ThemePages /> : <ThemePagesMobile /> }
      ]
    },
    {
      path: '/profile-theme',
      element: <ProfileThemeLayout />,
      children: [
        { element: <Navigate to="/profile-theme" replace />, index: true },
        // { path: 'themeView/:slugName/:id/:tId/:TempId', element: <ThemePages /> }
        { path: 'themeView/:slugName/:id/:tId/:TempId', element: isDesktop ? <ThemePages /> : <ThemePagesMobile /> }
      ]
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetConfirmPassword = Loadable(lazy(() => import('../pages/auth/ResetConfirmPass')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const UserDone = Loadable(lazy(() => import('../pages/auth/UserDone')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCategory = Loadable(lazy(() => import('../pages/dashboard/UserCategory')));
const PhysicalApp = Loadable(lazy(() => import('../pages/dashboard/UserPhysical')));
const SkillsetInterest = Loadable(lazy(() => import('../pages/dashboard/SkillsetInterest')));
const WorkCredit = Loadable(lazy(() => import('../pages/dashboard/WorkCredit')));
const LanguageInd = Loadable(lazy(() => import('../pages/dashboard/LanguageInd')));
const Profession = Loadable(lazy(() => import('../pages/dashboard/Profession')));
const ProfessionCategory = Loadable(lazy(() => import('../pages/dashboard/ProfessionCategory')));
const ProfessionIndustry = Loadable(lazy(() => import('../pages/dashboard/ProfessionIndustry')));
const ProfessionData = Loadable(lazy(() => import('../pages/dashboard/ProfessionData')));
const UserSubCategory = Loadable(lazy(() => import('../pages/dashboard/UserSubCategory')));
const TalentType = Loadable(lazy(() => import('../pages/dashboard/TalentType')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const AgentProfession = Loadable(lazy(() => import('../pages/dashboard/ProfessionAgent')));
const ListAgentProfession = Loadable(lazy(() => import('../pages/dashboard/AgentProfessionList')));
const EditAgentProfession = Loadable(lazy(() => import('../sections/@dashboard/user/UserSettingAgentEdit')));
const ExitAgentProfession = Loadable(lazy(() => import('../pages/dashboard/AgentExitsProfession')));
const AgentProfessionInvitation = Loadable(lazy(() => import('../pages/dashboard/AgentProfessionInvitation')));
const AgentProfessionInviReqDetails = Loadable(lazy(() => import('../pages/dashboard/AgentProfessionInvitationDetails')));
const AgentInviDetails = Loadable(lazy(() => import('../pages/dashboard/AgentInvitationDetails')));
const AgentDetails = Loadable(lazy(() => import('../pages/dashboard/AgentDetails')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const UserAppliedJob = Loadable(lazy(() => import('../pages/dashboard/jobs/UserAppliedJob')));

const EduAndTraining = Loadable(lazy(() => import('../pages/dashboard/EduAndTraining')));
const LocationPdf = Loadable(lazy(() => import('../pages/dashboard/PdfPage')));
// const PortFolio = Loadable(lazy(() => import('../pages/dashboard/Portfolio')));
const PortFolio = Loadable(lazy(() => import('../pages/ComingSoon')));
const UserAgentInfo = Loadable(lazy(() => import('../pages/dashboard/UserAgentInfo')));
const UserDonePage = Loadable(lazy(() => import('../pages/dashboard/UserDonePage')));
// job routing
const UserJobSearch = Loadable(lazy(() => import('../pages/dashboard/jobs/UserJobSearch')));
const UserJobSeek = Loadable(lazy(() => import('../pages/dashboard/jobs/UserJobSeek')));
const UserJobApplication = Loadable(lazy(() => import('../pages/dashboard/jobs/UserAppJobListView')));
// const UserJobApplication = Loadable(lazy(() => import('../pages/dashboard/jobs/UserJobApplication')));
const UserRecommendations = Loadable(lazy(() => import('../pages/dashboard/jobs/UserRecommendations')));
const JobSeekEnable = Loadable(lazy(() => import('../pages/dashboard/jobs/JobSeekEnable')));
const UserPauseJobSearch = Loadable(lazy(() => import('../pages/dashboard/jobs/UserPauseJobSearch')));
// extra tab pages
const JobReviewed = Loadable(lazy(() => import('../pages/dashboard/jobs/JobReviewed')));
const JobContacting = Loadable(lazy(() => import('../pages/dashboard/jobs/JobContacting')));
const JobRejected = Loadable(lazy(() => import('../pages/dashboard/jobs/JobRejected')));
const JobHired = Loadable(lazy(() => import('../pages/dashboard/jobs/JobHired')));
const JobInterested = Loadable(lazy(() => import('../pages/dashboard/jobs/JobInterested')));
const JobHold = Loadable(lazy(() => import('../pages/dashboard/jobs/JobHold')));
const JobExpired = Loadable(lazy(() => import('../pages/dashboard/jobs/JobExpired')));
const JobInterviews = Loadable(lazy(() => import('../pages/dashboard/jobs/JobInterview')));
// const YouAreDone = Loadable(lazy(() => import('../pages/auth/UserDone')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
// const Page500 = Loadable(lazy(() => import('../pages/Page500')));

// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
// const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const Albumphotoorganization = Loadable(lazy(() => import('../pages/dashboard/talent/Albumphotoorganization')));
const AlbumListPage = Loadable(lazy(() => import('../pages/dashboard/talent/AlbumListPage')));
const EditPhotoAlbum = Loadable(lazy(() => import('../pages/dashboard/talent/EditPhotoAlbum')));

const AlbumAudioListPage = Loadable(lazy(() => import('../pages/dashboard/talent/AlbumAudioListPage')));
const AlbumAudioEdit = Loadable(lazy(() => import('../pages/dashboard/talent/AlbumAudioEdit')));

const Templates = Loadable(lazy(() => import('../pages/dashboard/templates/ThemeProfile')));
const Resumes = Loadable(lazy(() => import('../pages/dashboard/templates/Resumes')));
const ResumeCreate = Loadable(lazy(() => import('../pages/dashboard/templates/ResumeCreate')));
const ResumeTemplates = Loadable(lazy(() => import('../pages/dashboard/templates/ResumeTemplates')));
const ResumePreview = Loadable(lazy(() => import('../pages/dashboard/templates/ResumePreview')));
const ResumeTemplateData = Loadable(lazy(() => import('../pages/dashboard/templates/ResumeTemplateData')));
const CoverLetter = Loadable(lazy(() => import('../pages/dashboard/templates/CoverLetter')));
const CreateCoverLetter = Loadable(lazy(() => import('../pages/dashboard/templates/CreateCoverLetter')));
const CoverLetterView = Loadable(lazy(() => import('../pages/dashboard/templates/CoverLetterView')));
const ResumeTemplateRecreate = Loadable(lazy(() => import('../pages/dashboard/templates/ResumeTemplateRecreate')));

const ThemeChoose = Loadable(lazy(() => import('../pages/dashboard/ThemeChoose')));
const ThemeCreate = Loadable(lazy(() => import('../pages/dashboard/templates/ThemeCreate')));
const ThemePageSecond = Loadable(lazy(() => import('../pages/dashboard/templates/themescreens/ThemePage02')));
const ThemePages = Loadable(lazy(() => import('../pages/dashboard/templates/ThemeViewPages')));


const Gallery = Loadable(lazy(() => import('../pages/dashboard/talent/Gallery')));
const Photos = Loadable(lazy(() => import('../pages/dashboard/talent/Photos')));
const Video = Loadable(lazy(() => import('../pages/dashboard/talent/Video')));
const Album = Loadable(lazy(() => import('../pages/dashboard/talent/Album')));
const Portfolio = Loadable(lazy(() => import('../pages/dashboard/talent/Portfolio')));

const PortfolioImageGallery = Loadable(lazy(() => import('../pages/dashboard/talent/PortfolioImageGallery')));
const AudioGallery = Loadable(lazy(() => import('../pages/dashboard/talent/AudioGallery')));
const PortfolioAudio = Loadable(lazy(() => import('../pages/dashboard/talent/PortfolioAudio')));
const Awards = Loadable(lazy(() => import('../pages/dashboard/talent/Awards')));
const PortfolioAwards = Loadable(lazy(() => import('../pages/dashboard/talent/PortfolioAwards')));

const PortMain = Loadable(lazy(() => import('../pages/dashboard/UploadImage')));
const AlbumUpload = Loadable(lazy(() => import('../pages/dashboard/UploadAlbumImage')));
const ImageGalleryDemo = Loadable(lazy(() => import('../pages/dashboard/ImageGallery')));

const AudioUpload = Loadable(lazy(() => import('../pages/dashboard/AudioUpload')));
const AudioAlbumUpload = Loadable(lazy(() => import('../pages/dashboard/AudioAlbumUpload')));
const AudioAlbum = Loadable(lazy(() => import('../pages/dashboard/talent/AudioAlbum')));
const VideoUpload = Loadable(lazy(() => import('../pages/dashboard/UploadVideo')));
const UploadAlbumVideo = Loadable(lazy(() => import('../pages/dashboard/UploadAlbumVideo')));
const VideoGallery = Loadable(lazy(() => import('../pages/dashboard/talent/VideoGallery')));
const Videos = Loadable(lazy(() => import('../pages/dashboard/talent/Videos')));
const VideosAlbum = Loadable(lazy(() => import('../pages/dashboard/talent/VideoAlbum')));
const VideoAlbumList = Loadable(lazy(() => import('../pages/dashboard/talent/VideoAlbumList')));
const VideoAlbumEdit = Loadable(lazy(() => import('../pages/dashboard/talent/VideoAlbumEdit')));
const VideosAlbumOrganization = Loadable(lazy(() => import('../pages/dashboard/talent/VideoAlbumOrganization')));
const AudioAlbumOrganization = Loadable(lazy(() => import('../pages/dashboard/talent/AudioAlbumOrganization')));
const PhotoUpload = Loadable(lazy(() => import('../pages/dashboard/UploadImage')));
const AwardUpload = Loadable(lazy(() => import('../pages/dashboard/AwardsUpload')));
const AwardAlbum = Loadable(lazy(() => import('../pages/dashboard/talent/AwardAlbum')))
const AwardGallery = Loadable(lazy(() => import('../pages/dashboard/talent/AwardsGallery')))
const AwardsAlbumOrganization = Loadable(lazy(() => import('../pages/dashboard/talent/AwardAlbumOrganization')))

const SettingPageMain = Loadable(lazy(() => import('../pages/dashboard/SettingPage')));
const MyProjectPage = Loadable(lazy(() => import('../pages/dashboard/myProjectPage')))
const MyProjectList = Loadable(lazy(() => import('../pages/dashboard/MyProjectList')))
const MyProjectView = Loadable(lazy(() => import('../pages/dashboard/myProjectView')))
const CalendarMain = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

const Inbox = Loadable(lazy(() => import('../pages/dashboard/InBox/JobInbox')));
const Notifications = Loadable(lazy(() => import('../pages/dashboard/InBox/Notifications')));
const Enquiry = Loadable(lazy(() => import('../pages/dashboard/InBox/Enquiry')));
const Subscription = Loadable(lazy(() => import('../pages/dashboard/subscription/Subscription')));
const jobs = Loadable(lazy(() => import('../pages/dashboard/UploadImage')));

const LocationDetailsPage = Loadable(lazy(() => import('../pages/dashboard/ProfileView')));
const ProfileDetailsPage = Loadable(lazy(() => import('../pages/dashboard/ProfileDetailsView')));

const Quick = Loadable(lazy(() => import('../pages/dashboard/jobs/UserJobSearch')));
const Advanced = Loadable(lazy(() => import('../pages/dashboard/jobs/JobSearchTemplate/Advanced')));
const Saved = Loadable(lazy(() => import('../pages/dashboard/jobs/JobSearchTemplate/Saved')));
const Details = Loadable(lazy(() => import('../pages/dashboard/jobs/JobSearchTemplate/JobDetails')));
const Connect = Loadable(lazy(() => import('../pages/dashboard/Connect')));
const QuickList = Loadable(lazy(() => import('../pages/dashboard/jobs/QuickSearchList')));

const Followers = Loadable(lazy(() => import('../pages/dashboard/connectnetwork/Followers')));
const Following = Loadable(lazy(() => import('../pages/dashboard/connectnetwork/Following')));
const TalentsFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/FollowNetwork/Talents")));
const CompaniesFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/FollowNetwork/Companies")));
const InstitutionFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/FollowNetwork/Institutions")));
const TalentsFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/FollowNetwork/UnTalents")));
const CompaniesFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/FollowNetwork/UnCompanies")));
const InstitutionFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/FollowNetwork/UnInstitutions")));
const Follow = Loadable(lazy(() => import('../pages/dashboard/connectnetwork/Follow')));

const Networking = Loadable(lazy(() => import('../pages/dashboard/Networking')));
const CropImage = Loadable(lazy(() => import('../pages/dashboard/CropMain')));

const ActivityLogPage = Loadable(lazy(() => import('../pages/dashboard/activitylog/ActivityLogPage')));

// Mobile paths 

const GeneralAppMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/GeneralApp')));
const UserCreateMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UserCreate')));
const EduAndTrainingMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/EduAndTraining')));
const ProfessionMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/Profession')));
const ProfessionDataMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/ProfessionData')));
const LanguageIndMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/LanguageInd')));
const ProfessionCategoryMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/ProfessionCategory')));
const ProfessionIndustryMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/ProfessionIndustry')));
const SkillsetInterestMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/SkillsetInterest')));
const WorkCreditMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/WorkCredit')));
const PhysicalAppMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UserPhysical')));
const PortMainMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UploadImage')));
const PhotosMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/Photos')));


const AlbumMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/Album')));
const AlbumphotoorganizationMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/Albumphotoorganization')));
const AlbumUploadMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UploadAlbumImage')));
const AlbumListPageMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/AlbumListPage')));
const EditPhotoAlbumMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/EditPhotoAlbum')));
const PhotoUploadMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UploadImage')));

const AudioAlbumUploadMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AudioAlbumUpload')));
const AudioUploadMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AudioUpload')));
const AudioGalleryMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/AudioGallery')));
const AudioAlbumMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/AudioAlbum')));
const AlbumAudioListPageMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/AlbumAudioListPage')));
const AlbumAudioEditMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/AlbumAudioEdit')));
const AudioAlbumOrganizationMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/AudioAlbumOrganization')));

const VideosMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/Videos')));
const VideoUploadMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UploadVideo')));
const UploadAlbumVideoMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UploadAlbumVideo')));
const VideosAlbumMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/VideoAlbum')));
const VideoAlbumListMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/VideoAlbumList')));
const VideoAlbumEditMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/VideoAlbumEdit')));
const VideosAlbumOrganizationMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/VideoAlbumOrganization')));

const AwardsMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/Awards')));
const AwardUploadMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AwardsUpload')));
const AwardsAlbumOrganizationMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/talent/AwardAlbumOrganization')))
// const WorkCreditMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/WorkCredit')));
const UserAccountMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UserAccount')));
const AgentProfessionMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/ProfessionAgent')));
const ListAgentProfessionMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AgentProfessionList')));
const AgentProfessionInvitationMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AgentProfessionInvitation')));
const AgentProfessionInviReqDetailsMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AgentProfessionInvitationDetails')));
const AgentInviDetailsMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AgentInvitationDetails')));
const AgentDetailsMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AgentDetails')));
const EditAgentProfessionMobile = Loadable(lazy(() => import('../mobile/sections/@dashboard/user/UserSettingAgentEdit')));
const ExitAgentProfessionMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/AgentExitsProfession')));
const UserAgentInfoMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UserAgentInfo')));
const UserCategoryMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UserCategory')));
const UserSubCategoryMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UserSubCategory')));
const TalentTypeMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/TalentType')));
const LocationDetailsPageMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/ProfileView')));
const ProfileDetailsPageMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/ProfileDetailsView')));
const UserDonePageMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/UserDonePage')));
const ThemePagesMobile = Loadable(lazy(() => import('../mobile/pages/dashboard/templates/ThemeViewPages')));
// --------------------------------------
const SettingProfileVisibility = Loadable(lazy(() => import('../sections/@dashboard/user/profile/ProfileVisibility')));
const SettingAccount = Loadable(lazy(() => import('../sections/@dashboard/user/profile/Account')));
const SettingNotification = Loadable(lazy(() => import('../sections/@dashboard/user/profile/Notification')));
const SettingUserAgent = Loadable(lazy(() => import('../sections/@dashboard/user/profile/TalentAgent')));
const SettingManageAccount = Loadable(lazy(() => import('../sections/@dashboard/user/profile/manageAccount')));

const SubscriptionPlans = Loadable(lazy(() => import('../pages/dashboard/subscription/Subscription')));
const BillingInfo = Loadable(lazy(() => import('../pages/dashboard/subscription/BillingInfo')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/subscription/InvoiceDetails')));
const SubscriptionTable = Loadable(lazy(() => import('../pages/dashboard/subscription/SubscriptionTable')));
const SettingPayment = Loadable(lazy(() => import('../sections/@dashboard/user/profile/PaymentHistory')));



