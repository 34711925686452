import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useSnackbar } from 'notistack';

import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import DateFormat from 'src/components/DateFormat';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { getRecentNotification, clearHasError, ViewAllRecentNotification, ClearViewAllRecentNoti } from '../../../redux/slices/DashboardNotification';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProfileData, clearGetProfileData, talentLogout } from '../../../redux/slices/createProfile';
import { clearProjectList } from '../../../redux/slices/Projects';
import useAuth from '../../../hooks/useAuth';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {

  const id = useParams();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const { user, logout } = useAuth();


  const [notifications, setNotifications] = useState(_notifications);
  const [dashboardNotification, setDashboardNotification] = useState([]);
  const [notificationType, setnotificationType] = useState([]);


  const { notificationData, ViewnotificationData, LoginError } = useSelector((state => {
    return {

      notificationData: state && state.DashboardNotification && state.DashboardNotification.DashboardNotificationSucc && state.DashboardNotification.DashboardNotificationSucc.response,

      ViewnotificationData: state && state.DashboardNotification && state.DashboardNotification.ViewAllRecentNotiRespSucc,

      LoginError: state && state.DashboardNotification && state.DashboardNotification.error
    }
  }))

  console.log(LoginError, 'notificationData244548')
  console.log(ViewnotificationData, 'ViewnotificationData548')

  useEffect(() => {
    if (ViewnotificationData && ViewnotificationData.status === 200) {
      dispatch(getRecentNotification());
      dispatch(ClearViewAllRecentNoti());
      navigate(PATH_DASHBOARD.inBox.inbox, { replace: true });
    }
  }, [ViewnotificationData])

  // const totalUnRead = notificationData.filter((item) => item.isUnRead === true).length;
  // const totalUnRead = dashboardNotification.length; 
  const totalUnRead = dashboardNotification.length > 0 ? dashboardNotification.length : "0";


  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setDashboardNotification(
      notificationData.map((notificationData) => ({
        ...notificationData,
        isUnRead: false,
      }))
    );
  };

  useEffect(() => {
    dispatch(ClearViewAllRecentNoti());
    dispatch(getRecentNotification());
  }, [])

  // const handleIconClick = () => {
  //   dispatch(getRecentNotification());
  // };

  useEffect(() => {
    if (notificationData && notificationData.notifications && notificationData.notifications.length > 0) {
      setDashboardNotification(notificationData.notifications);
    } else {
      setDashboardNotification([]);
    }
  }, [notificationData]);

  const handleButtonClick = () => {
    // Navigate to the desired page
    // navigate(PATH_DASHBOARD.inBox.inbox, { replace: true });
    setOpen(null);
    dispatch(ViewAllRecentNotification());
  };

  useEffect(() => {
    if (LoginError && LoginError.status && LoginError.status === 409) {
      if (LoginError.message && LoginError.message === 'Invalid username or password') {
        try {
          const tkEncID = localStorage.getItem('userEncId');
          const payload = {
            "enc_id": tkEncID,
            "device_type": "web"
          };
          dispatch(talentLogout(payload));
          logout();
          navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
          localStorage.removeItem('statususer');
          dispatch(clearGetProfileData())
          dispatch(clearProjectList())
          console.log(LoginError, 'runnlogout 123');

        } catch (error) {
          console.error(error);
          enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }

        dispatch(clearHasError())
      }
    }
  }, [LoginError])



  const handleNotificationClick = (notification) => {
    if (notification.notification_type === 'job') {
      // Navigate to the job page
      navigate(PATH_DASHBOARD.inBox.inbox, { replace: true });
      setOpen(null);
    } else if (notification.notification_type === 'notification') {
      // Navigate to the notification page
      navigate(PATH_DASHBOARD.inBox.notification, { replace: true });
      setOpen(null);
    }
  };



  console.log(dashboardNotification, '54dd6554')

  return (
    <>




      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error" >
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead}  messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar className='notificationPopover' style={{ maxHeight: '20rem' }}>
          <List>
            {dashboardNotification.length === 0 ? (
              // Render a card or message when no data is found
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  No notifications found.
                </Typography>
              </Box>
            ) : (
              // Render the list of notifications
              dashboardNotification.map((notification, index) => (
                <Box onClick={() => handleNotificationClick(notification)}>

                  <NotificationItem key={index} dashboardNotification={notification} />
                </Box>
              ))
            )}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleButtonClick}>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}



function NotificationItem({ dashboardNotification }) {
  // const { avatar, title } = renderContent( dashboardNotification);
  // const { icon_image, job_title, message } = renderContent(dashboardNotification);
  // console.log(notification, '5548541')
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,

        borderBottom: "1px solid eee",
        mt: '1px',
        ...(dashboardNotification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        {/* <Avatar sx={{ bgcolor: 'background.neutral' }}>{dashboardNotification.icon_image}
        
        </Avatar> */}
        <Avatar src={dashboardNotification.icon_image} alt='NotificationIcon' />


        {
          console.log(dashboardNotification.icon_image, 'dfdsjlfdjsf')
        }
      </ListItemAvatar>

      <ListItemText
        primary={
          <div>

            <Typography
              variant="body1"
              className='firstLetter'
              fontSize={15}
              fontWeight={600}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '200px', // Set a specific max width
              }}
            >
              {dashboardNotification.display_name}

            </Typography>


            <Tooltip
              title={dashboardNotification && dashboardNotification.message && dashboardNotification.message.length > 50 ? dashboardNotification.message : ''}
              sx={{
                cursor: dashboardNotification && dashboardNotification.message && dashboardNotification.message.length > 50 ? 'pointer' : 'default',
              }}
            >
              <div>
                {/* Add the third data here */}
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    marginTop: 1,
                    maxWidth: '200px', // Set a specific max width
                  }}
                >
                  {dashboardNotification && dashboardNotification.message && dashboardNotification.message.length > 50
                    ? dashboardNotification.message.substring(0, 50) + '...'
                    : dashboardNotification && dashboardNotification.message}
                </Typography>
              </div>
            </Tooltip>
          </div>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            <DateFormat date={dashboardNotification.created_at} />
          </Typography>
        }
      />



    </ListItemButton>
  );
}



