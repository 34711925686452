import React from 'react'
import { Box, Typography, Stack } from '@mui/material';
// assets
import { LoadingButton } from '@mui/lab';
import { UploadIllustration } from '../../assets';

const AwardsBlock = () => {
    return (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
          {/* <UploadIllustration sx={{ width: 220 }} /> */}
    
          <Box sx={{ p: 3 ,textAlign:"center"}}>
            <Typography sx={{ mb: 0.75, color: '#44577a', fontSize: 14 }}>&nbsp;</Typography>
            <Box sx={{ }}>
            <LoadingButton type="button" variant="contained">
             &nbsp;
            </LoadingButton>
    
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 20MB
            </Typography> */}
          </Box>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 20MB
            </Typography> */}
          </Box>
        </Stack>
      );
}

export default AwardsBlock


