import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, } from '@mui/material';
import Badge from '@mui/material/Badge';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { useDispatch, useSelector } from '../../../redux/store';
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import CartCount from './CartCount';
import ProfileProgressbar from '../../../mobile/pages/dashboard/MatComponent/ProfileProgressbar';
import { clearGetProfileData, clearDashboardSucc } from '../../../redux/slices/createProfile';
import { clearLanguageSucc } from '../../../redux/slices/languageIndustry';
import { clearEducationSucc } from '../../../redux/slices/eduAndTraining';
import { clearTalentProfessions } from '../../../redux/slices/talentIndustries';
import { clearPortfoliImgResp } from '../../../redux/slices/uploadImages';
import { clearProfileIntro } from '../../../redux/slices/profileIntro';

// clearGetProfileData(createProfile) clearLanguageSucc(languageIndustry) clearEducationSucc(eduAndTraining) clearTalentProfessions(talentIndustries) clearPortfoliImgResp(uploadImages) clearProfileIntro(profileIntro) clearDashboardSucc(createProfile)

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const [alltablist, setAlltablist] = useState([]);
  const isDesktop = useResponsive('up', 'lg');
  const dispatch = useDispatch();
  const [ProfileCompletionTabs, setProfileCompletionTabs] = useState([]);

  useEffect(() => {
    dispatch(clearGetProfileData());
    dispatch(clearLanguageSucc());
    dispatch(clearDashboardSucc());
    dispatch(clearEducationSucc());
    dispatch(clearTalentProfessions());
    dispatch(clearPortfoliImgResp());
    dispatch(clearProfileIntro());
  }, []);

  console.log(isDesktop, 'isDesktop67576')

  const { languageTypeData, educationTypeData, getProfessionsData, portfolio, profileMainData,
    currentProfile, dashboardResponse } = useSelector((state) => {
      return {
        currentProfile: state && state.profile && state.profile.events && state.profile.events.response,
        // language tab
        languageTypeData: state && state.langIndustry && state.langIndustry.languageData && state.langIndustry.languageData.response,
        // education tab
        educationTypeData: state && state.educationAndTrainig && state.educationAndTrainig.educationData && state.educationAndTrainig.educationData,
        // profession tab
        getProfessionsData: state && state.industries && state.industries.talentprofessionsData
          && state.industries.talentprofessionsData.responseData,
        // portfolio
        portfolio: state && state.portfolio && state.portfolio.events && state.portfolio.events.response,
        // Profile Intro
        profileMainData: state && state.intro && state.intro.profileIntroData && state.intro.profileIntroData.response,
        //dashboard tab
        dashboardResponse: state && state.profile && state.profile.dashboardIntro && state.profile.dashboardIntro.response,
      };
    });

  console.log(educationTypeData, '75457457457457')
  // console.log(currentProfile, languageTypeData, educationTypeData, getProfessionsData, portfolio, profileMainData, dashboardResponse, '75457457457457')

  useEffect(() => {
    if (currentProfile && currentProfile !== undefined) {
      if (Object.keys(currentProfile).length > 0) {
        if (currentProfile.profile_completion_tabs && currentProfile.profile_completion_tabs.length > 0) {
          setProfileCompletionTabs(currentProfile.profile_completion_tabs);
        }
        else {
          setProfileCompletionTabs([])
        }
      }
    }
  }, [currentProfile]);

  useEffect(() => {
    if (languageTypeData && languageTypeData !== undefined) {
      if (Object.keys(languageTypeData).length > 0) {
        if (languageTypeData.profile_completion_tabs && languageTypeData.profile_completion_tabs.length > 0) {
          setProfileCompletionTabs(languageTypeData.profile_completion_tabs);
        }
        else {
          setProfileCompletionTabs([])
        }
      }
    }
  }, [languageTypeData])

  useEffect(() => {
    if (educationTypeData && educationTypeData !== undefined) {
      if (Object.keys(educationTypeData).length > 0) {
        if (educationTypeData.profile_completion_tabs && educationTypeData.profile_completion_tabs.length > 0) {
          setProfileCompletionTabs(educationTypeData.profile_completion_tabs);
        }
        else {
          setProfileCompletionTabs([])
        }
      }
    }
  }, [educationTypeData])

  useEffect(() => {
    if (getProfessionsData && getProfessionsData !== undefined) {
      if (Object.keys(getProfessionsData).length > 0) {
        if (getProfessionsData.profile_completion_tabs && getProfessionsData.profile_completion_tabs.length > 0) {
          setProfileCompletionTabs(getProfessionsData.profile_completion_tabs);
        }
        else {
          setProfileCompletionTabs([])
        }
      }
    }
  }, [getProfessionsData])

  useEffect(() => {
    if (portfolio && portfolio !== undefined) {
      if (Object.keys(portfolio).length > 0) {
        if (portfolio.profile_completion_tabs && portfolio.profile_completion_tabs.length > 0) {
          setProfileCompletionTabs(portfolio.profile_completion_tabs);
        }
        else {
          setProfileCompletionTabs([])
        }
      }
    }
  }, [portfolio])

  useEffect(() => {
    if (profileMainData && profileMainData !== undefined) {
      if (Object.keys(profileMainData).length > 0) {
        if (profileMainData.profile_completion_tabs && profileMainData.profile_completion_tabs.length > 0) {
          setProfileCompletionTabs(profileMainData.profile_completion_tabs);
        }
        else {
          setProfileCompletionTabs([])
        }
      }
    }
  }, [profileMainData])

  useEffect(() => {
    if (dashboardResponse && dashboardResponse !== undefined) {
      if (Object.keys(dashboardResponse).length > 0) {
        if (dashboardResponse.profile_completion_tabs && dashboardResponse.profile_completion_tabs.length > 0) {
          setProfileCompletionTabs(dashboardResponse.profile_completion_tabs);
        }
        else {
          setProfileCompletionTabs([])
        }
      }
    }
  }, [dashboardResponse])


  useEffect(() => {
    if (ProfileCompletionTabs && ProfileCompletionTabs.length > 0) {
      const CompletionTabsList = [
        { title: 'update_personal_information' },
        { title: 'save_language_industries_location' },
        { title: 'education_and_training' },
        { title: 'save_talent_industry_profession' },
        { title: 'upload_portfolio' },
        { title: 'save_profile_intro' },
      ];

      const resultArray = CompletionTabsList.map(completionTab => {
        const matchingProfileTab = ProfileCompletionTabs.find(
          profileTab => profileTab.title === completionTab.title
        );
        if (matchingProfileTab) {
          return { rating: '100', code: '#1baa5a' };
        } else {
          return { rating: '0', code: '#1baa5a' };
        }
      });
      const ObjectTitleArray = resultArray.sort((a, b) => b.rating - a.rating);

      console.log(ObjectTitleArray, 'ObjectTitleArray5t7656', resultArray)
      setAlltablist(ObjectTitleArray);
    }
  }, [ProfileCompletionTabs])


  console.log(alltablist, 'yt56yt5')
  return (
    <RootStyle className="headerWrapper" isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>

      <Toolbar
        className='headerInner'
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
          position: "relative"
        }}
      >

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} className="navbar-toggle" sx={{ color: 'text.primary' }}>
            <Iconify icon="mi:menu" color='#0b4029' />
          </IconButtonAnimate>
        )}

        <Logo />
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 3.5 }} >
          {/* <LanguagePopover /> */}
          <NotificationsPopover />
          <Box sx={{ marginRight:{xs:"15px !important", sm:"0px !important"} }}>
            <CartCount />
          </Box>

          {/* <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </Toolbar>
      {!isDesktop && (<Box sx={{
        fontSize: '15px',
        mt: '-15px',
        // mt:'-16px'
      }}>
        {alltablist && alltablist.length > 0 ? alltablist.map((item, index) => (
          <ProfileProgressbar currentUser={item.rating} code={item.code} />
        )) : <ProfileProgressbar currentUser='80%' code='#ffffff' />}
      </Box>)}
    </RootStyle>
  );
}
