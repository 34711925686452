import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  AwardsSetData: [],
  AwardsGetData:[],
  AwardsEditData:[],
  AwardsDeleteData:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    UploadAwardsSucc(state, action) {
      state.isLoading = false;
      state.AwardsSetData = action.payload;
    },

    getAwardsData(state, action) {
        state.isLoading = false;
        state.AwardsGetData = action.payload;
      },
    

    EditAwardsData(state, action) {
        state.isLoading = false;
        state.AwardsEditData = action.payload;
      },

      deleteAwardsData(state, action) {
        state.isLoading = false;
        state.AwardsDeleteData = action.payload;
      },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function UploadAwardsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/upload_awards/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.UploadAwardsSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function ClearUploadAwardsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UploadAwardsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAwardData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_talent_portfolio/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getAwardsData(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function EditAwardData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_talent_portfolio/',data,{headers: {"Content-type": "multipart/form-data"}});
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.EditAwardsData(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function ClearEditAwardData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.EditAwardsData([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function DeleteAwardsData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_talent_portfolio/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.deleteAwardsData(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
        
      }
    };
  }

  export function ClearDeleteAwardsData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.deleteAwardsData([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }