import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
//
import BlockContent from './BlockContent';
import PortfolioRejectionFiles from './PortfolioRejectionFiles';
import UploadImage from '../../images/PortfolioUploadImage.png';
import MultiFilePreview from './MultiFilePreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: theme.palette.background.neutral,
    border: '4px dashed #d3e1ea',
    // border: `1px dashed ${theme.palette.grey[500_32]}`,
    // '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadPortfolioMultiPhotosMobile.propTypes = {
    error: PropTypes.bool,
    showPreview: PropTypes.bool,
    files: PropTypes.array,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadPortfolioMultiPhotosMobile({
    error,
    showPreview = false,
    files,
    onRemove,
    onRemoveAll,
    onOpenModel,
    helperText,
    sx,
    ...other
}) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
        // accept: {
        //   'image/png': ['.png'],
        // }
    });
    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        // bgcolor: 'error.lighter',
                        bgcolor: 'red'
                    }),
                }}
            >
                <input {...getInputProps()} />

                {/* <BlockContent /> */}
                <Box >
                    <Box component='img' src={UploadImage} alt='Upload Image' className='PortfolioUploadImg' />

                    <Box sx={{ p: 1.2 }}>
                        <Typography gutterBottom variant="body1" color={'#44577a'}>  Browse or Drag & Drop Image Files  </Typography>

                        <Typography variant="body2" sx={{ color: '#44577a', mt: 2 }} textAlign={'center'} >
                            Allowed formats *.jpg ,*.jpeg,*.png<br />
                            Max Image Size 20MB
                        </Typography>
                    </Box>
                </Box>
            </DropZoneStyle>

            {fileRejections.length > 0 && <PortfolioRejectionFiles fileRejections={fileRejections} />}

            {/* <MultiFilePreview  onOpenModel={onOpenModel} files={files} showPreview={showPreview} onRemove={onRemove} onRemoveAll={onRemoveAll} /> */}

            {helperText && helperText}
        </Box>
    );
}
