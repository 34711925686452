import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';


const initialState = {
  isLoading: false,
  error: null,
  saveSearchData: [],
  SavedDeleteData: [],
  EditSaveJobData:[],
  SendEditSaveJobData:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'SavedSearch',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getSavedSearchSucc(state, action) {
        state.isLoading = false;
        state.saveSearchData = action.payload;
       
    },
    
    deleteSavedData(state, action) {
      state.isLoading = false;
      state.SavedDeleteData = action.payload;
    },

    EditSavedData(state, action) {
      state.isLoading = false;
      state.EditSaveJobData = action.payload;
    },
    UpdateSavedData(state, action) {
      state.isLoading = false;
      state.UpdateSaveJobData = action.payload;
    },
    SendEditSavedData(state, action) {
      state.isLoading = false;
      state.SendEditSaveJobData = action.payload;
    },
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getSavedSearchData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_all_search/',data);
      // const response = await axios.get('/get_all_search/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getSavedSearchSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function DeleteSavedSearchsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_search/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.deleteSavedData(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function CleardeleteSavedData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteSavedData([]));   
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function editSavedSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_all_search/',{
        params: {
          id: data
        }
      });
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.EditSavedData(response.data));  
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function ClearEditSavedSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.EditSavedData([]));   
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function UpdateSavedSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_search/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.UpdateSavedData(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ClearUpdateSavedSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UpdateSavedData([]));   
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SendEditSavedSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SendEditSavedData(data));   
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSendEditSavedSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SendEditSavedData([]));   
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

