import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path) {
  return path.includes('https');
}

export function getActive(path, pathname) { 

  //Profession tab
  const currentUrl = window.location.href;
  const last = currentUrl.split("/");
  const EncIdcategory = last[6];
  const EncIdLocation = last[5];
  const EncIdPhysical = last[5];
  const EncIdSkills = last[6];
  const EncIdWork = last[5];
  const EncId = last[6];
    
  if(
    //main profession
      (path==="/profile/profession/show" && pathname==="/profile/profession-data") 
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname==="/profile/profession-data")

   //add profession
   || (path==="/profile/profession/show" && pathname==="/profile/profession/add")
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname==="/profile/profession/add/MzU2MwKg==")

   //add all tabs
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/profession-category/add/${EncIdcategory}`) 
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/profession-industry/${EncIdLocation}`) 
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/physical/${EncIdPhysical}`) 
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/work/${EncIdWork}`) 
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/main/agent-info/${EncId}`) 

   //slills
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/add/${EncIdSkills}`)

   //update skills
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/experienced/${EncIdSkills}`)
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/interested/${EncIdSkills}`)
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/dancing/${EncIdSkills}`)
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/singing/${EncIdSkills}`)
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/music/${EncIdSkills}`)
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/special/${EncIdSkills}`)
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/keywords/${EncIdSkills}`)
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/softwares/${EncIdSkills}`)

   //update profession
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/profession-category/update/${EncIdcategory}`) 
   || (path==="/profile/profession/show/MzU2MwKg==" && pathname===`/profile/skillset/update/${EncIdSkills}`)


  //  || (path==="/profile/profession/show" && pathname==="/profile/skillset") 
  //  || (path==="/profile/profession/show" && pathname==="/profile/work") 
  //  || (path==="/profile/profession/show" && pathname==="/profile/physical") 
  //  || (path==="/profile/profession/show" && pathname==="/profile/main/agent-info")
  //  || (path==="/profile/profession/show" && pathname==="/profile/profession/update") 
   )
  {
    return true;
  }
  
  //Job Search
  if(
    (path==="/jobs/job-search" && pathname==="/jobs/job-search") ||
    (path==="/jobs/job-search" && pathname==="/jobs/advanced")  ||
    (path==="/jobs/job-search" && pathname==="/jobs/saved")
  )
  {
    return true;
  }

  //Job Applications
  if(
    (path==="/jobs/jobApplication" && pathname==="/jobs/jobApplication") ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Reviewed")  ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Contacting") ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Interview") ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Rejected") ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Hired") ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Interested") ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Hold") ||
    (path==="/jobs/jobApplication" && pathname==="/jobs/Expired") 
  )
  {
    return true;
  }


    return path ? !!matchPath({ path, end: false }, pathname) : false;
}
