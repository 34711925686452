// routes
import { useEffect, useState } from 'react';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};



// const menus = ["Personal Information", "Category and Profession", "Education and Training", "Portfolio","Work Credits","Profile Intro"];
const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------

  {
    // subheader: 'management',
    items: [
      // MANAGEMENT : USER
      { title: 'Dashboard', path: PATH_DASHBOARD.app, icon: ICONS.dashboard, isDisabled: 'No' },
      {
        title: 'Profile',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        isDisabled: 'No',
        children: [
          { title: 'Personal Information', path: PATH_DASHBOARD.user.general.profile },
          // { title: 'Category & Profession', path: PATH_DASHBOARD.user.category },
          { title: 'Languages', path: PATH_DASHBOARD.user.langAndInd },
          { title: 'Education & Training', path: PATH_DASHBOARD.user.EduAndTraining },
          // { title: 'Physical Appearance', path: PATH_DASHBOARD.user.physical },
          // { title: 'Skillset', path: PATH_DASHBOARD.user.skillset },
          { title: 'Profession', path: `${PATH_DASHBOARD.user.profession}/show/MzU2MwKg==` },
          { title: 'Portfolio', path: PATH_DASHBOARD.user.portfolio.root },
          // { title: 'Work Credits', path: PATH_DASHBOARD.user.work },
          { title: 'Profile Intro', path: PATH_DASHBOARD.user.profileIntro },
          //  { title: 'Agent Info', path: PATH_DASHBOARD.user.agent.root },
        ],
      },
      { title: 'Theme Templates', path: PATH_DASHBOARD.templates.root, isDisabled: 'No' },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, isDisabled: 'No' },
      {
        title: 'Jobs', path: PATH_DASHBOARD.jobs.root, isDisabled: 'No',
        children: [
          { title: 'Job Search', path: PATH_DASHBOARD.jobs.job_search },
          { title: 'Job Seek', path: PATH_DASHBOARD.jobs.job_seek },
          { title: 'Job Applications', path: PATH_DASHBOARD.jobs.jobApplication },
          { title: 'Recommendations', path: PATH_DASHBOARD.jobs.recommendations },
          // { title: 'Pause Job Search', path: PATH_DASHBOARD.jobs.pause_job_search },
        ],
      },
      {
        title: 'Connect Network',
        path: PATH_DASHBOARD.connectnetwork.root,
        icon: ICONS.user,
        isDisabled: 'No',
        children: [
          { title: 'Followers', path: PATH_DASHBOARD.connectnetwork.followers.root },
          { title: 'Following', path: PATH_DASHBOARD.connectnetwork.following.root },
          // { title: 'Network', path: PATH_DASHBOARD.connectnetwork.network },
        ],
      },
      { title: 'Inbox', path: PATH_DASHBOARD.inBox.root, isDisabled: 'No' },
      // { title: 'Networking', path: PATH_DASHBOARD.networking },
      // { title: 'Settings', path: PATH_DASHBOARD.setting, isDisabled: 'No' },
      { title: 'Settings', path: PATH_DASHBOARD.setting.root, isDisabled: 'No' },
      { title: 'My Project', path: PATH_DASHBOARD.myProject, isDisabled: 'No' },
      // { title: 'My Project', path: "", isDisabled: 'No' },
      // { title: 'Subscription', path: PATH_DASHBOARD.subscription, isDisabled: 'No' },
      // { title: 'Connect', path: PATH_DASHBOARD.connect.root, icon: ICONS.chat },
      // { title: 'My Project', path: "", isDisabled: 'Yes' },
      { title: 'Activity', path: PATH_DASHBOARD.activityLog, isDisabled: 'No' }
    ],
  }

];

export default navConfig;
