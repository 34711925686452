import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess: [],
  cities: [],
  AgentInfolistResp: [],
  saveAgentInfoResp: [],
  CreateAgentSuccResp: [],
  AgentInfoResp: [],
  saveAgentInfoOnToggleResp: [],
  DeleteSettingAgentResp: [],
  saveAgentInfoDataResp: [],
  SpecificAgentSuccResp: [],
  deleteProWiseAgentResp: [],
  allProfAgentSuccResp: [],
  createdAgentSuccResp: [],
  InvitetalentSuccResp: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    saveAgentInfoSuccess(state, action) {
      state.isLoading = false;
      state.saveAgentInfoResp = action.payload;
    },
    saveAgentInfoDataSuccess(state, action) {
      state.isLoading = false;
      state.saveAgentInfoDataResp = action.payload;
    },
    saveAgentInfoOnToggleSuccess(state, action) {
      state.isLoading = false;
      state.saveAgentInfoOnToggleResp = action.payload;
    },
    SpecificAgentSuccess(state, action) {
      state.isLoading = false;
      state.SpecificAgentSuccResp = action.payload;
    },
    getAllProfAgentSuccess(state, action) {
      state.isLoading = false;
      state.allProfAgentSuccResp = action.payload;
    },
    getCreatedAgentSuccess(state, action) {
      state.isLoading = false;
      state.createdAgentSuccResp = action.payload;
    },
    InvitetalentSuccess(state, action) {
      state.isLoading = false;
      state.InvitetalentSuccResp = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteProWiseAgentSucc(state, action) {
      state.isLoading = false;
      state.deleteProWiseAgentResp = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // GET EVENTS
    getAgentInformationSuccess(state, action) {
      state.isLoading = false;
      state.AgentInfoResp = action.payload;
    },

    // GET Agent List
    getAgentlistSuccess(state, action) {
      state.isLoading = false;
      state.AgentInfolistResp = action.payload;
    },

    // Delete Agent List
    DeleteSettingAgentSuccess(state, action) {
      state.isLoading = false;
      state.DeleteSettingAgentResp = action.payload;
    },

    // GET Agent
    CreateAgentSuccess(state, action) {
      state.isLoading = false;
      state.CreateAgentSuccResp = action.payload;
    },


    userAgentUpdateSuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getAgentInfo(data) {
  const tokenVal = window.localStorage.getItem("accessToken");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_agent_representation/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEventsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getAgentInformation(data) {
  const tokenVal = window.localStorage.getItem("accessToken");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dev_get_agent_representation/', data);
      console.log(response,'response4636346')
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAgentInformationSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getAgentList(data) {
  const tokenVal = window.localStorage.getItem("accessToken");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_user_agent/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAgentlistSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function DeleteSettingAgent(data) {
  const tokenVal = window.localStorage.getItem("accessToken");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_user_agent/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteSettingAgentSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function CreateAgent(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  const tokenVal = window.localStorage.getItem("accessToken");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenVal}`,
        },
        body: JSON.stringify(data)
      }
      const data1 = await fetch(`${employerUrlApi}create_agent/`, config);
      // const response = await axios.post('/create_agent/',data);
      const response = await data1.json();
      if (response && response.status === 200) {
        dispatch(slice.actions.CreateAgentSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveAgentInfo(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dev_save_agent_representation/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveAgentInfoSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveAgentInfoData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_agent_representation/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveAgentInfoDataSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function deleteProWiseAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_agent_representation/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteProWiseAgentSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveAgentInfoOnToggle(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_agent_representation/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveAgentInfoOnToggleSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getSpecificAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_verify_agent/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SpecificAgentSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      console.log(error, '%%%response%%%%')
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getAllProfessionAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_agent_profession/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAllProfAgentSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCreatedAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_create_agent/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCreatedAgentSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getInvitetalent(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  const tokenVal = window.localStorage.getItem("accessToken");
  console.log(data,'data6744748')
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.post('/update_invite_talent_status/', data)
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${tokenVal}`,
        },
        body: JSON.stringify(data)
      }
      const response = await fetch(`${employerUrlApi}update_invite_talent_status/`, config);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InvitetalentSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearInvitetalent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.InvitetalentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearCreatedAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCreatedAgentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleargetAllProfessionAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAllProfAgentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearSaveAgentInfoOnToggle(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveAgentInfoOnToggleSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleardeleteProWiseAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteProWiseAgentSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearSaveAgentInfo(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveAgentInfoSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearSpecificAgentInfo(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SpecificAgentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearCreateAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CreateAgentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearAgentList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAgentlistSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearDeleteAgent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteSettingAgentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearAgentInformation(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAgentInformationSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}