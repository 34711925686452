import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  getProjectList: [],
  getProjectView: [],
  getTalentId: [],
  getPaymentHistoryResp:[],
  isOpenModal: false,
};

const slice = createSlice({
  name: 'Project Data',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProjectListSucc(state, action) {
      state.isLoading = false;
      state.getProjectListResp = action.payload;
    },
    getTalentIdSucc(state, action) {
      state.isLoading = false;
      state.getTalentId = action.payload;
    },
    getPaymentHistoryDataSucc(state, action) {
      state.isLoading = false;
      state.getPaymentHistoryResp = action.payload;
    },
    getProjectDetailsViewSucc(state, action) {
      state.isLoading = false;
      state.getProjectView = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------



// export function getProjectList(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/api/employer/get_talent_projects/');
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.getProjectListSucc(response.data));
//         }else{
//         dispatch(slice.actions.hasError(response.data)) 
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }

export function getProjectList(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  const tokenVal = window.localStorage.getItem("accessToken");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenVal}`,
        },
        body: JSON.stringify(data)
      }
      const data1 = await fetch(`${employerUrlApi}get_talent_projects/`, config);
      // const response = await axios.post('/create_agent/',data);
      const response = await data1.json();
      if (response && response.status === 200) {
        dispatch(slice.actions.getProjectListSucc(response));
      } else {
        dispatch(slice.actions.hasError(response))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearProjectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProjectListSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function gettalentId(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_talent_id/');
      if (response && response.data && response.status === 200) {
        dispatch(slice.actions.getTalentIdSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getProjectDetailsView(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  const tokenVal = window.localStorage.getItem("accessToken");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenVal}`,
        },
        body: JSON.stringify(data)
      }
      const data1 = await fetch(`${employerUrlApi}talent_project_details/`, config);
      // const response = await axios.post('/create_agent/',data);
      const response = await data1.json();
      if (response && response.status === 200) {
        dispatch(slice.actions.getProjectDetailsViewSucc(response));
      } else {
        dispatch(slice.actions.hasError(response))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// export function getProjectDetailsView(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try{
//         const responces = await axios.post('/talent_project_details/',data);
//         if(responces && responces.data && responces.data.status === 200){
//           dispatch(slice.actions.getProjectDetailsViewSucc(responces.data));
//         } else {
//           dispatch(slice.actions.hasError(responces.data))
//         }
//     } catch (error) {
//       if((error & error.status !=="") && (error.status!== undefined)){
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   }
// }

export function getPaymentHistory(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  const tokenmy =localStorage.getItem('accessToken');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenmy}`,
        },
        body: JSON.stringify({
          "unique_key": data.unique_key,
          "project_enc_id": data.project_enc_id,
          "enc_id": data.enc_id
        })
      }

      const fetchResponse = await fetch(`${employerUrlApi}get_project_inovice/`, config);
      // const fetchResponse = await fetch('http://43.204.70.133/api/employer/following/', config);
      const data1 = await fetchResponse.json();
      // dispatch(slice.actions.getEmployerFollowRes(data1));
      if (data1 && data1.status === 200) {
        dispatch(slice.actions.getPaymentHistoryDataSucc(data1));
      } else {
        dispatch(slice.actions.hasError(data1))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };

}


// export function getPaymentHistory(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/get_project_inovice/', data);
//       if (response && response.data && response.data.status === 200) {
//         dispatch(slice.actions.getPaymentHistoryDataSucc(response.data));
//       } else {
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if ((error && error.status !== "") && (error.status !== undefined)) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
//       }
//     }
//   };
// }


export function cleargetPaymentHistoryData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPaymentHistoryDataSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




