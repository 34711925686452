import * as Yup from 'yup';
import { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { useDispatch, useSelector } from '../../../redux/store';
import { setFlagForLogin } from '../../../redux/slices/Followers';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { AuthContext } from '../../../contexts/JWTContext';
// ----------------------------------------------------------------------

export default function LoginForm() {

  const [width, setWidth] = useState(window.innerWidth);
  const { login } = useAuth();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [agentId, setAgentId] = useState('');
  const [ReturnagentId, setReturnAgentId] = useState('');

  const currentUrl = window.location.href;
  const segment = currentUrl ? currentUrl.split('/').slice(-2)[0] : []
  const slugSegment = currentUrl ? currentUrl.substring(currentUrl.lastIndexOf("/") + 1) : []

  const last = currentUrl.split("/");
  const segment1 = currentUrl.substring(currentUrl.lastIndexOf('/') + -1);

  // useEffect(() => {
  //   if (slugSegment !== 'login' && slugSegment !== 'onal-info') {
  //     const agentIdSlug = slugSegment ? slugSegment.substring(slugSegment.lastIndexOf("?") + 5) : []
  //     console.log('currentUrl43', agentIdSlug)
  //     setAgentId(agentIdSlug)
  //   }
  // }, [slugSegment]);

  const [agtParam, setAgtParam] = useState('');
  const [AgentIDVal, setAgentIDVal] = useState('');
  const [encodedEncID, setEncodedEncID] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(new URL(currentUrl).search);
    const agtValue = params.get('agt');

    setAgtParam(agtValue);

    if (agtValue) {
      const [AgentIDVal, data] = agtValue.split('/');
      setAgentIDVal(AgentIDVal);
      setEncodedEncID(data);
    }
  }, []);

  console.log('LoginForm64563637', AgentIDVal, encodedEncID)

  useEffect(() => {
    if (AgentIDVal !== 'invite' && AgentIDVal !== '') {
      setAgentId(AgentIDVal);
    }
  }, [AgentIDVal]);

  const context = useContext(AuthContext);
  const { LoginFlag } = useSelector((state) => {
    return {
      LoginFlag: state && state.Follow && state.Follow.followLoginRes
    }
  });

  const IdAgent = localStorage.setItem('agentIdLogin', agentId);

  useEffect(() => {
    if (IdAgent && IdAgent !== '') {
      setReturnAgentId(IdAgent);
    }
  }, [IdAgent]);


  // const usernameSchema = Yup.string().test(
  //   'username',
  //   'Invalid Email or Phone number',
  //   function (value) {
  //     if (!value) {
  //       return false; // Username is required
  //     }
  //     const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  //     const mobileRegex = /^[0-9]{10}$/;

  //     if (emailRegex.test(value) || mobileRegex.test(value)) {
  //       return true;
  //     }

  //     return false;
  //   }
  // );

  const LoginSchema = Yup.object().shape({
    // username: usernameSchema.required('Email or Phone number is required'),
    username: Yup.string().required('Email or Phone number is required').min(7, 'Enter valid username').max(50, 'must be less than 50 characters'),
    password: Yup.string()
      .required("Password is required")
      .min(6, 'Password length should be a minimum of 6 characters')
      .max(24, 'Password Must be less than 24 characters')
      .matches(/^\S*(\s\S*)?$/, 'Whitespace is not allowed.')
  });

  const defaultValues = {
    username: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  // const [errorMsg,setErrorMsg] = useState([])

  const onSubmit = async (data) => {
    try {
      await login(data.username, data.password);
      if (LoginFlag === true) {
        navigate(-1)
        dispatch(setFlagForLogin([]))
      }
      const IdOfAgent = localStorage.getItem('agentIdLogin');
      if (LoginFlag.length === 0 && IdOfAgent !== '') {
        navigate(PATH_DASHBOARD.app, { replace: true });
        // navigate(PATH_DASHBOARD.general.app, { replace: true });
      }
      else {
        navigate(PATH_DASHBOARD.app, { replace: true });
        // navigate(PATH_DASHBOARD.general.app, { replace: true });
      }
    } catch (error) {
      console.error(error);
      if ((error && error.status !== "") && (error.status !== undefined)) {
        setError('afterSubmit', error);
      } else {
        setError('afterSubmit', { status: "", message: "Something went wrong" });
      }
    }
  };

  const onHandleError = async (e) => {
    await trigger(e.target.name);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box>
          <Box className='loginMessage'>
            {!!errors.afterSubmit && <Alert className='alertMessage' severity="error">{errors.afterSubmit.message}</Alert>}
          </Box>
          <Box mt={2}>
            <Box>
              {width > 768 ?
                <RHFTextField className="loginInputHolder" name="username"
                  // label="Email or Phone Number" 
                  placeholder="Email or Phone Number"
                  onBlur={e => onHandleError(e)} autocomplete="off" />
                :
                <RHFTextField className="loginInputHolder" name="username"
                  // label="Email or Phone Number"
                  placeholder="Email or Phone Number"
                  onBlur={e => onHandleError(e)} autocomplete="off" />
              }
            </Box>
            <Box mt={2}>
              {width > 768 ?
                <RHFTextField
                  className="loginInputHolder"
                  name="password"
                  placeholder="Password"
                  // label="Password"
                  onBlur={e => onHandleError(e)}
                  type={showPassword ? 'text' : 'password'}
                  autocomplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                :
                <RHFTextField
                  className="loginInputHolder"
                  name="password"
                  placeholder="Password"
                  // label="Password"
                  onBlur={e => onHandleError(e)}
                  type={showPassword ? 'text' : 'password'}
                  autocomplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            </Box>
            <Box mt={2}>
              <LoadingButton className="btnCustom" fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Login
              </LoadingButton>
            </Box>
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7.5} md={7.5} lg={8}>
                {agentId && agentId !== '' ?
                  <Link component={RouterLink} to={`${PATH_AUTH.registerUnprotected}?agt=${agentId}`}>
                    New to TalentKind? Join now!
                  </Link>
                  :
                  <Link component={RouterLink} to={PATH_AUTH.register}>
                    New to TalentKind? Join now!
                  </Link>
                }
              </Grid>
              <Grid item xs={12} sm={4.5} md={4.5} lg={4}>
                <Link component={RouterLink} to={PATH_AUTH.resetPassword}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}
