import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  educationData: [],
  EducationPostData:[],
  EducationDeleteData:[],
  EducationupData:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEducationSucc(state, action) {
      state.isLoading = false;
      state.educationData = action.payload;
    },
    postEducationSucc(state, action) {
        state.isLoading = false;
        state.EducationPostData = action.payload;
      },
      upEducationSucc(state, action) {
        state.isLoading = false;
        state.EducationupData = action.payload;
      },

      DeleteEduSucc(state, action) {
        state.isLoading = false;
        state.EducationDeleteData = action.payload;
      },
   
   // CREATE EVENT
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getEducationAndTrainingData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_education_and_training/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getEducationSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function SaveEducationAndTrainingData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.post('/education_and_training/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.postEducationSucc(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
};
}

export function UpdateEducationAndTrainingData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/education_and_training/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.upEducationSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
     if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
};
}

export function DeleteEducationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_education_training/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.DeleteEduSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.postEducationSucc([]));
      dispatch(slice.actions.upEducationSucc([]));
      dispatch(slice.actions.DeleteEduSucc([]));
      dispatch(slice.actions.hasError([]));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearEducationSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEducationSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}