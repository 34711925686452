import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  jobAppliedData: [],
  jobAppliedDataId: [],
  jobReviewedData: [],
  jobContactingData: [],
  jobRejectedData: [],
  jobInterestedData: [],
  jobHoldData: [],
  jobHiredData: [],
  jobInterviewData: [],
  jobExpiredData: [],
  sendMessageData: [],
  getMessageData:[],
  Sheduleinterview:[],
  jobJobsStatusData:[]
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    getAppliedJobsSucc(state, action) {
      state.isLoading = false;
      state.jobAppliedData = action.payload;
    },

    getAppliedJobsSuccId(state, action) {
      state.isLoading = false;
      state.jobAppliedDataId = action.payload;
    },

    getJobsStatusSucc(state, action) {
      state.isLoading = false;
      state.jobJobsStatusData = action.payload;
    },

    RescheduleInterviewSucc(state, action) {
      state.isLoading = false;
      state.Sheduleinterview = action.payload;
    },


    getReviewedJobsSucc(state, action) {
      state.isLoading = false;
      state.jobReviewedData = action.payload;
    },

    getContactingJobsSucc(state, action) {
      state.isLoading = false;
      state.jobContactingData = action.payload;
    },

    getRejectedJobsSucc(state, action) {
      state.isLoading = false;
      state.jobRejectedData = action.payload;
    },

    getInterestedJobsSucc(state, action) {
      state.isLoading = false;
      state.jobInterestedData = action.payload;
    },

    getHoldJobsSucc(state, action) {
      state.isLoading = false;
      state.jobHoldData = action.payload;
    },

    getHiredJobsSucc(state, action) {
      state.isLoading = false;
      state.jobHiredData = action.payload;
    },

    getInterviewJobsSucc(state, action) {
      state.isLoading = false;
      state.jobInterviewData = action.payload;
    },

    getExpiredJobsSucc(state, action) {
      state.isLoading = false;
      state.jobExpiredData = action.payload;
    },

    sendMessageSucc(state, action) {
      state.isLoading = false;
      state.sendMessageData = action.payload;
    },

    getMessageSucc(state, action) {
      state.isLoading = false;
      state.getMessageData = action.payload;
    },


    // CREATE EVENT

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function GetAppliedJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAppliedJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// ----------------------------------------------------------------------

export function GetStatusJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_job_applications_status/');
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobsStatusSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

//----------------AppliedJob-------------------------------
export function GetAppliedJobDataID(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_application_id/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAppliedJobsSuccId(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

//----------------Resedule Interview-------------------------------
export function SaveRescheduleInterview(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_talent_reschedule/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.RescheduleInterviewSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------Reviwed-Job-Api----------------------

export function GetReviewedJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getReviewedJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------Contacting-Job-Api----------------------

export function GetContactingJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getContactingJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------Rejected-Job-Api----------------------

export function GetRejectedJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getRejectedJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------Interested-Job-Api----------------------

export function GetInterestedJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getInterestedJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------Hold-Job-Api----------------------

export function GetHoldJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getHoldJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------Hired-Job-Api----------------------

export function GetHiredJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getHiredJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------Interview-Job-Api----------------------

export function GetInterviewJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getInterviewJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// -------------------JobExpired-Job-Api----------------------

export function GetExpiredJobData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_applications/', data);
      // console.log(response,'abc');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getExpiredJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearJobStatus(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getJobsStatusSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// Clear SaveRescheduleInterview
export function clearRescheduleInterError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.RescheduleInterviewSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


//------------------message--------------


export function SendMessage(data) {
  console.log(data, 'tuok')
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_message/', data);
      console.log(response, 'rrrrrrrrr');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.sendMessageSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function GetMessage(data) {
  console.log(data, 'tuok')
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_job_messages/', data);
      console.log(response, 'rrrrrrrrr');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getMessageSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }



    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


// Clear sendMessageSucc
export function clearSendMessage(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sendMessageSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}