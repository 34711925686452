
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// import Login Image from '../assets/'
import LoginImage from '../images/sign-in-image.jpg';
// ----------------------------------------------------------------------
export default function LoginImageBlock() {

  const LoginImageBlock = (
    <Box sx={{
      margin: 9,
    }}>
      <img src={LoginImage} alt="Login Graph" className='loginImage' />      
    </Box>
  ); 
  return <RouterLink to="/">{LoginImageBlock}</RouterLink>;
}
