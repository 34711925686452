import React from 'react'
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

const AudioBlockContent = () => {
    return (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
          <UploadIllustration sx={{ width: 220 }} />
    
          <Box sx={{ p: 3 }}>
            <Typography gutterBottom variant="h5">
              Drag & Drop Files
            </Typography>
    
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {/* Allowed formats *.jpg ,*.jpeg,*.png<br /> */}
              Max Audio Size 20MB
            </Typography>
          </Box>
        </Stack>
      );
}

export default AudioBlockContent


