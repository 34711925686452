import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  getMunuCheckData: [],
 };

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getForMenuSucc(state, action) {
      state.isLoading = false;
      state.getMunuCheckData = action.payload;
    },
   // CREATE EVENT
   },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getForMenuCheck(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/check_primary_industry/');
      dispatch(slice.actions.getForMenuSucc(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getForMenuCheckOnce(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getForMenuSucc({"status":200,"message":"Success","response":{"user_industries_primary":{"isSelected":true}}}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
 


