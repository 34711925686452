import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  TalentfollowResponse: [],
  followLoginRes: [],
  followingTalentResponse: [],
  followEmployerResp: [],
  unfollowEmployerResp: [],
  favEmployerResp: [],
  unfavEmployerResp: [],
  followTalentResponse: [],
  followingCompaniesResponse: [],
  followCompaniesResponse: [],
  followBackEmployerResponse: [],
  UnfollowResponse: [],
  followBackRes: [],
  ImagesResponse: [],
  SendEnquirySuccResp: [],
  UnFavTalentSucResp: [],
  FavTalentSucResp: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getFollowRes(state, action) {
      state.isLoading = false;
      state.TalentfollowResponse = action.payload;
    },
    getEmployerFollowRes(state, action) {
      state.isLoading = false;
      state.followEmployerResp = action.payload;
    },
    getEmployerUnFollowRes(state, action) {
      state.isLoading = false;
      state.unfollowEmployerResp = action.payload;
    },
    getEmployerFavRes(state, action) {
      state.isLoading = false;
      state.favEmployerResp = action.payload;
    },
    getEmployerUnFavRes(state, action) {
      state.isLoading = false;
      state.unfavEmployerResp = action.payload;
    },
    getEmployerFollowBackRes(state, action) {
      state.isLoading = false;
      state.followBackEmployerResponse = action.payload;
    },
    FollowBackRes(state, action) {
      state.isLoading = false;
      state.followBackRes = action.payload;
    },
    FollowingTalentRes(state, action) {
      state.isLoading = false;
      state.followingTalentResponse = action.payload;
    },
    FollowingCompaniesRes(state, action) {
      state.isLoading = false;
      state.followingCompaniesResponse = action.payload;
    },
    FollowTalentRes(state, action) {
      state.isLoading = false;
      state.followTalentResponse = action.payload;
    },
    FollowCompaniesRes(state, action) {
      state.isLoading = false;
      state.followCompaniesResponse = action.payload;
    },
    setFollowLogin(state, action) {
      state.isLoading = false;
      state.followLoginRes = action.payload;
    },
    UnFollowTalentFollowing(state, action) {
      state.isLoading = false;
      state.UnfollowResponse = action.payload;
    },
    ImagesRes(state, action) {
      state.isLoading = false;
      state.ImagesResponse = action.payload;
    },
    SendEnquirySuccess(state, action) {
      state.isLoading = false;
      state.SendEnquirySuccResp = action.payload;
    },
    UnFavouriteTalentSucc(state, action) {
      state.isLoading = false;
      state.UnFavTalentSucResp = action.payload;
    },
    FavouriteTalentSucc(state, action) {
      state.isLoading = false;
      state.FavTalentSucResp = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getFollowReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/talent_following/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getFollowRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// export function getEmployerFollowReq(data) {
//   const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const config = {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${data.token}`,
//         },
//         body: JSON.stringify({
//           "following_id": data.following_id,
//           "type": data.type,
//           "enc_talent": data.enc_talent,
//         })
//       }

//       const fetchResponse = await fetch(`${employerUrlApi}following/`, config);
//       // const fetchResponse = await fetch('http://43.204.70.133/api/employer/following/', config);
//       const data1 = await fetchResponse.json();
//       // dispatch(slice.actions.getEmployerFollowRes(data1));
//       if (data1 && data1.status === 200) {
//         dispatch(slice.actions.getEmployerFollowRes(data1));
//       } else {
//         dispatch(slice.actions.hasError(data1))
//       }
//     } catch (error) {
//       if ((error && error.status !== "") && (error.status !== undefined)) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
//       }
//     }
//   };

// }

export function getEmployerFollowReq(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "following_id": data.following_id,
          "type": data.type,
          "enc_employer": data.enc_employer
        })
      }

      const fetchResponse = await fetch(`${employerUrlApi}following/`, config);
      // const fetchResponse = await fetch('http://43.204.70.133/api/employer/following/', config);
      const data1 = await fetchResponse.json();
      // dispatch(slice.actions.getEmployerFollowRes(data1));
      if (data1 && data1.status === 200) {
        dispatch(slice.actions.getEmployerFollowRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };

}


export function getEmployerUnFollowReq(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "following_id": data.following_id,
          "type": data.type,
          "enc_employer": data.enc_employer
        })
      }

      const fetchResponse = await fetch(`${employerUrlApi}unfollow/`, config);
      // const fetchResponse = await fetch('http://43.204.70.133/api/employer/unfollow/', config);
      const data1 = await fetchResponse.json();
      // dispatch(slice.actions.getEmployerFollowRes(data1));
      if (data1 && data1.status === 200) {
        dispatch(slice.actions.getEmployerUnFollowRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };

}

export function getEmployerFavouriteReq(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "talent_enc_id": data.talent_enc_id,
          "type": data.type,
          "employer_enc_id": data.employer_enc_id
        })
      }

      const fetchResponse = await fetch(`${employerUrlApi}save_favourite_talents/`, config);
      // const fetchResponse = await fetch('http://43.204.70.133/api/employer/following/', config);
      const data1 = await fetchResponse.json();
      // dispatch(slice.actions.getEmployerFollowRes(data1));
      if (data1 && data1.status === 200) {
        dispatch(slice.actions.getEmployerFavRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };

}


export function getEmployerUnFavouriteReq(data) {
  const employerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "talent_enc_id": data.talent_enc_id,
          "type": data.type,
          "employer_enc_id": data.employer_enc_id
        })
      }

      const fetchResponse = await fetch(`${employerUrlApi}delete_fev_talent/`, config);
      // const fetchResponse = await fetch('http://43.204.70.133/api/employer/unfollow/', config);
      const data1 = await fetchResponse.json();
      // dispatch(slice.actions.getEmployerFollowRes(data1));
      if (data1 && data1.status === 200) {
        dispatch(slice.actions.getEmployerUnFavRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };

}

// http://43.204.70.133/api/employer/following/
export function ClearFavEmployerReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEmployerFavRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearUnFavEmployerReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEmployerUnFavRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearFollowEmployerReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEmployerFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearUnFollowEmployerReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEmployerUnFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearFollowReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setFlagForLogin(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFollowLogin(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getFollowingTalentRes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_following/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowingTalentRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getFollowingCompaniesRes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_following/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowingCompaniesRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getFollowTalentRes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_followers/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowTalentRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getFollowCompaniesRes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_followers/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowCompaniesRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function setFollowingUnfollow(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/unfollow/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UnFollowTalentFollowing(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function TalentFavourite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/unfollow/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FavouriteTalentSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearTalentFavourite() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.FavouriteTalentSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function TalentUnFavourite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_fev_talent/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UnFavouriteTalentSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearTalentUnFavourite() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UnFavouriteTalentSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSetFollowingUnfollow(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UnFollowTalentFollowing([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SendFollowBackReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/talent_following/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowBackRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearSendFollowBackReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.FollowBackRes([]));
    } catch (error) {
      dispatch(slice.actions.FollowBackRes(error));
    }
  };
}

export function apiForImages(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_images_view_profile/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ImagesRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function followBackEmployer(data) {
  const local_url = process.env.REACT_APP_EMPLOYER_LOCAL_ADDRESS;
  const EmployerUrlApi = process.env.REACT_APP_NEW_API_EMPLOYER;
  // const EmployerUrlApi = process.env.REACT_APP_EMPLOYER_LIVE_ADDRESS;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "following_id": data.following_id,
          "type": data.type
        })
      }
      // const fetchResponse = await fetch('http://43.204.70.133/api/employer/following/', config);
      const fetchResponse = await fetch(`${EmployerUrlApi}following/`, config);
      // const fetchResponse = await fetch('http://43.204.70.133/api/employer/following/', config);
      const data1 = await fetchResponse.json();
      dispatch(slice.actions.getEmployerFollowBackRes(data1));

    } catch (error) {
      dispatch(slice.actions.getEmployerFollowBackRes(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasErrorFollowers(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SendEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_enquiry_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SendEnquirySuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearSendEnquiryError() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SendEnquirySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}