import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  quickSearchData: [],
  createSearchNAmeList: [],
  getSuccessRes: [],
  createQuick: [],
  newCreateQuick: [],
  getQuickResps: [],
  getJobsSuccResponse: [],
  getConfirmJobResponse: [],
  getConfirmViewJobResponse: [],
  getJobRecommendationsResp: [],
  jobApplyDataResp: [],
  ViewSearchJobSuccResp: [],
  getAppliedJobsResponse: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'QuickSearch',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getSuccess(state, action) {
      state.isLoading = false;
      state.getSuccessRes = action.payload;
    },
    createQuickSearchSucc(state, action) {
      state.isLoading = false;
      state.createSearchNAmeList = action.payload;
    },
    createQuicksSearchSuccs(state, action) {
      state.isLoading = false;
      state.createQuick = action.payload;
    },
    newCreateQuicksSearchSuccs(state, action) {
      state.isLoading = false;
      state.newCreateQuick = action.payload;
    },

    createQuickSearchRun(state, action) {
      state.isLoading = false;
      state.createQuickSearch = action.payload;
    },

    getQuickResponseSuccess(state, action) {
      state.isLoading = false;
      state.getQuickResps = action.payload;
    },

    getJobRecommendationsSuccess(state, action) {
      state.isLoading = false;
      state.getJobRecommendationsResp = action.payload;
    },

    jobApplyDataSuccess(state, action) {
      state.isLoading = false;
      state.jobApplyDataResp = action.payload;
    },


    ViewSearchJobSuccess(state, action) {
      state.isLoading = false;
      state.ViewSearchJobSuccResp = action.payload;
    },

    // CREATE EVENT
    getJobsSucc(state, action) {
      state.isLoading = false;
      state.getJobsSuccResponse = action.payload;
    },

    confirmSuccess(state, action) {
      state.isLoading = false;
      state.getConfirmJobResponse = action.payload;
    },
    viewJobSuccess(state, action) {
      state.isLoading = false;
      state.getConfirmViewJobResponse = action.payload;
    },
    getAppliedJobsSucc(state, action) {
      state.isLoading = false;
      state.getAppliedJobsResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------




export function getQuickSearchData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_all_keywords/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSuccess(response.data))
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function CleargetKeywords() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function createSearchNAmeList(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_search/', newEvent);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.createQuickSearchSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearCreateSearchNAmeList(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.createQuickSearchSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSavedSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.createSearchNAmeList([]));
      //  dispatch(slice.actions.createQuicksSearchSuccs([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createQuickSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/quick_search/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.createQuicksSearchSuccs(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  }
}


export function newCreateQuickSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/talent_job_search/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.newCreateQuicksSearchSuccs(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  }
}

// export function newCreateQuickSearch(data){
//   return async ()=>{
//     dispatch (slice.actions.startLoading());
//     try {
//       const response = await axios.post('/new_quick_search/', data);
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.newCreateQuicksSearchSuccs(response.data));
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//     }catch (error){
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   }
// }


// export function getQuickSearchResponse(jobItemId,pageno) {
//   const params = new URLSearchParams();
//   params.append("id", jobItemId);
//   params.append("page", pageno);
//   const data = {
//     params
//   };
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get("/quick_search/", data);
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.getQuickResponseSuccess(response.data));
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//      } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }

export function getQuickSearchResponse(data) {
  // const params = new URLSearchParams();
  // params.append("id", jobItemId);
  // params.append("page", pageno);
  // const data = {
  //   params
  // };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get_quick_search/", data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getQuickResponseSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getJobs(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearcreateQuicksSearchSuccs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.createQuicksSearchSuccs([]));
      dispatch(slice.actions.newCreateQuicksSearchSuccs([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearnewCreateQuicksSuccs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.newCreateQuicksSearchSuccs([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function applyJobConfirm(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_application/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.confirmSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function viewJobUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_views/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.viewJobSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function jobRecommendations(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dev_get_recommendations/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobRecommendationsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function jobApplyData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_pdf/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.jobApplyDataSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ViewSearchJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_views/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ViewSearchJobSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// get_recommendations

export function ClearViewSearchJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ViewSearchJobSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearApplyJobConfirm(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.confirmSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearjobApplyData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.jobApplyDataSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearQuickResponseSuccess(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getQuickResponseSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppliedJobs(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_jobs_applied/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAppliedJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearAppliedJobs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAppliedJobsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}