import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null, 
  getMediaSuccessResponse:[],  
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET EVENTS
    getMediaSuccess(state, action) {
      state.isLoading = false;
      state.getMediaSuccessResponse = action.payload;
    },
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

export function getMediaFlag() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/get_media_flags/');
        dispatch(slice.actions.getMediaSuccess(response.data));
      } catch (error) {
       dispatch(slice.actions.hasError(error));
      }
    };
  }

  
export function ClearMediaFlag() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getMediaSuccess([])); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasErrorMedias(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}