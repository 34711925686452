import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import createProfile from './slices/createProfile'
import talentIndustries from './slices/talentIndustries';
import talentTypeData from './slices/talentTypeData';
import languageIndustry from './slices/languageIndustry';
import eduAndTraining from './slices/eduAndTraining';
import physicalAppearence from './slices/physicalAppearence';
import skillset from './slices/skillset';
import profileIntro from './slices/profileIntro';
import theme from './slices/theme';
import userAgentInfo from './slices/userAgentInfo';
import UserDone from './slices/userDone'
import uploadImages from './slices/uploadImages';
import Audio from './slices/Audio';
import Videos from './slices/Videos';
import Album from './slices/Album';
import Awards from './slices/Awards';
import Medias from './slices/Medias';
import settingsCredit from './slices/settings';
import locationDetails from './slices/locationDetails';
import saveSearchData from './slices/saveSearchData';
import quickSearchdata from './slices/quickSearchdata';
import JobsSlice from './slices/JobsSlice';
import AdvanceSearch from './slices/AdvanceSearch';
import ChecksMenu from './slices/ChecksMenu';
import WorkCredit from './slices/workCredit';
import Followers from './slices/Followers';
import inbox from './slices/inbox';
import ForgotPass from './slices/ForgotPass';
import AppliedJobs from './slices/AppliedJobs';
import OpenDialogue from './slices/OpenDialogue';
import HandlersError from './slices/HandlersError';
import Resume from './slices/Resume';
import Projects from './slices/Projects';
import Subscriptions from './slices/subscription';
import register from './slices/register';
import activitylog from './slices/activitylog';
import DashboardNotification from './slices/DashboardNotification';



// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  skillsetInd: skillset,
  themeProfile: theme,
  langIndustry: languageIndustry,
  educationAndTrainig: eduAndTraining,
  physicalApp: physicalAppearence,
  profile: createProfile,
  register: register,
  industries: talentIndustries,
  talentType: talentTypeData,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  intro: profileIntro,
  kanban: kanbanReducer,
  work: WorkCredit,
  portfolio: uploadImages,
  mediaFlag: Medias,
  portfolioAudio: Audio,
  portfolioVideo: Videos,
  portfolioAwards: Awards,
  albumStatus: Album,
  agentInfo: userAgentInfo,
  doneUser: UserDone,
  settings: settingsCredit,
  profileDetails: locationDetails,
  product: persistReducer(productPersistConfig, productReducer),
  savedSearch: saveSearchData,
  quickSearch: quickSearchdata,
  jobSeek: JobsSlice,
  advanceSearchdata: AdvanceSearch,
  checkMenu: ChecksMenu,
  inboxed: inbox,
  Follow: Followers,
  forgotPas: ForgotPass,
  JobsApplied: AppliedJobs,
  otpModal: OpenDialogue,
  errorsHandle: HandlersError,
  resume: Resume,
  project: Projects,
  subscription: Subscriptions,
  activitylog: activitylog,
  DashboardNotification : DashboardNotification,
});

export { rootPersistConfig, rootReducer };
