import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  profileDetailsResponse: [], 
  profileEnquiryResponse:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.profileDetailsResponse = action.payload;
    }, 

    getEnquirySuccess(state, action) {
      state.isLoading = false;
      state.profileEnquiryResponse = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getProfileDetails(ticketId,data) {  
  console.log(ticketId,data,'ticketId,data')
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/view_profile/${ticketId}`,data) ;
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getProfileSuccess(response));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }
 
  export function postSendEnquiry(data) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/send_enquiry/',data) ;
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getEnquirySuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }
 
  export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }