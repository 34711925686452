import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
import { PATH_DASHBOARD } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

// ----------------------------------------------------------------------
const data11 =[ {"title": 'Personal Information', "path": "PATH_DASHBOARD.user.general.profile" },
{ "title": 'Category & Profession', "path": "PATH_DASHBOARD.user.general.profile" },
{ "title": 'Language & Industry', "path": "PATH_DASHBOARD.user.general.profile" }];
const setSession = (accessToken,user="",menus=[],agentId,agentEncId) => { 
  if (accessToken) {
    localStorage.setItem('accessTokenTalent', accessToken);
    if(agentId && agentId!=='' && agentId!==undefined && agentId!==null){
      localStorage.setItem('agentIdLogin', agentId);
    }
    if(agentEncId && agentEncId!=='' && agentEncId!==undefined && agentEncId!==null){
      localStorage.setItem('agentEncIdLogin', agentEncId);
    }
    if(user && user.slug  && user.slug!=='')
    {
      localStorage.setItem('agentUserSlug', user.slug);
    }
    if(user && user.photo_url)
    {
      localStorage.setItem('userPhotourl', user && user.photo_url);
    }
    if(user && user.enc_id)
    {
      localStorage.setItem('userEncId', user && user.enc_id);
    }
    if(user && user.city && user.city!=='')
    {
      localStorage.setItem('statususer', 'Old');
    }
    else if(user && user.city && user.city ==='')
    {
       localStorage.setItem('statususer', 'New');
    }
    if(user && user.slug)
    {
      localStorage.setItem('userSlug', user.slug);
    }
    if(menus && menus.length > 0)
    {
      localStorage.setItem("leftMenu", JSON.stringify(menus));
    }
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    // localStorage.localStorage.setItem('accessTokenTalent', '');
    // localStorage.localStorage.setItem('userPhotourl', '');
    // localStorage.localStorage.setItem('userDisplayName', '');
    // localStorage.localStorage.setItem('userSlug', '');
    // localStorage.localStorage.setItem('userEncId', '');
    // localStorage.localStorage.setItem('leftMenu', '');
    // localStorage.localStorage.setItem('agentIdLogin', '');
    // localStorage.localStorage.setItem('agentEncIdLogin', '');
    // localStorage.localStorage.setItem('agentUserSlug', '');
    // ======================================
    localStorage.removeItem('accessTokenTalent');
    localStorage.removeItem('userPhotourl');
    localStorage.removeItem('userDisplayName');
    localStorage.removeItem('userSlug');
    localStorage.removeItem('userEncId');
    localStorage.removeItem('leftMenu');
    localStorage.removeItem('agentIdLogin');
    localStorage.removeItem('agentEncIdLogin');
    localStorage.removeItem('agentUserSlug');
    localStorage.removeItem('statususer');
    localStorage.removeItem('userProfessionName');
    localStorage.removeItem('dataKey');
    localStorage.removeItem('agentMappingID');
    localStorage.removeItem('CreateagentData');
    localStorage.removeItem('userAgentId');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, verify, sign };
