import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  languageData: [],
  profressionIndustryData: [],
  IndiaCityData: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  languagePostData: [],
  profressionIndustryPostData: [],

};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getLanguageSucc(state, action) {
      state.isLoading = false;
      state.languageData = action.payload;
    },
    // GET Prof Enc Id
    getProfessionEncNameSucc(state, action) {
      state.isLoading = false;
      state.professionEncName = action.payload;
    },
    PostLanguageSucc(state, action) {
      state.isLoading = false;
      state.languagePostData = action.payload;
    },

    getProfesionIndustrySucc(state, action) {
      state.isLoading = false;
      state.profressionIndustryData = action.payload;
    },
    getIndiaCities(state, action) {
      state.isLoading = false;
      state.IndiaCityData = action.payload;
    },
    PostProfesionIndustrySucc(state, action) {
      state.isLoading = false;
      state.profressionIndustryPostData = action.payload;
    },

    // CREATE EVENT

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

// export function getLanguageIndData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     const tokenVal = window.localStorage.getItem("accessToken")
//     try {
//       const response = await axios.get('/slang_language_industries_location/');
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.getLanguageSucc(response.data));
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }

export function getLanguageIndData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/language_industries_location/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getLanguageSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProfessionEncName(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_encrypt_profession_name/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProfessionEncNameSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// export function SaveLanguageIndustry(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/slang_save_language_industries_location/',data)
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.PostLanguageSucc(response.data));
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }

export function SaveLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.post('/save_industries_language_location/', data)
      const response = await axios.post('/save_language_industries_location/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.PostLanguageSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.PostLanguageSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearProfessionEncName(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProfessionEncNameSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Profession Industry Data

export function getProfessionIndData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_profession_location/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProfesionIndustrySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getIndiaCityData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_city/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getIndiaCities(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function SaveProfessionIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_profession_location/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.PostProfesionIndustrySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearProfessionIndustry() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.PostProfesionIndustrySucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearLanguageSucc() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getLanguageSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearProfesionIndustrySucc() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProfesionIndustrySucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}