
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


export default function ProfileProgressbar(props) {
  const { currentUser, code } = props;
  let colorpercent = '';
  if (code === 'blue') {
    colorpercent = '#0000FF';
  }
  if (code === 'lightblue') {
    colorpercent = '#00CED1';
  }
  if (code === 'yellow') {
    colorpercent = '#ffc455';
  }
  if (code === 'orange') {
    colorpercent = '#FF7F50';
  }
  if (code === 'red') {
    colorpercent = '#FF0000';
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 0,
    // borderRadius: 10,
    borderColor: '#D3D3D3',
    borderCollapse: "#D3D3D3",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: theme.palette.mode === 'light' ? code : '#308fe8',
      // backgroundColor: theme.palette.mode === 'light' ? colorpercent : '#308fe8',
    },
  }));

  console.log(currentUser, 'currentUser656457')

  return (
    // <Box sx={{ flexGrow: 1, position: 'relative' }}>
    <Box sx={{display:'inline'}}>
      <BorderLinearProgress className='progressTextMobile' variant="determinate" value={currentUser} />
    </Box>
  );
}
