import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  uploadAudioResponse:[],
  updateAudioResponse:[],
  getAudiosResponse:[],
  getAudioDeleteResponse:[],
  updateAudioRoute:[],
  audioUploadSuccess:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getAudiosSuccess(state, action) {
      state.isLoading = false;
      state.getAudiosResponse = action.payload;
    },

    getAudioDeleteSuccess(state, action) {
      state.isLoading = false;
      state.getAudioDeleteResponse = action.payload;
    },

    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
     
    updateAudioSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.updateAudioResponse = action.payload;
    },

    uploadPortfolioAudioSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.audioUploadSuccess = action.payload;
    },
    

    AudioRouteSuccess(state, action) {
      state.isLoading = false;
      state.updateAudioRoute = action.payload;
    },
    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

  export function getAudios(data) {
    const tokenVal = window.localStorage.getItem("accessToken")
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_talent_portfolio/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getAudiosSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }


  export function getAudioDelete(data) {
    const tokenVal = window.localStorage.getItem("accessToken")
    console.log(tokenVal)
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_multiple_portfolio/',data);
        console.log(response, 'resss')
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getAudioDeleteSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function updateAudioData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_talent_portfolio/',data,{headers: {"Content-type": "multipart/form-data"}})
         if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.updateAudioSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function uploadPortfolioAudio(data) {
    const tokenVal = window.localStorage.getItem("accessToken")
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await axios.post('/upload_portfolio/',data,{headers: {"Content-type": "multipart/form-data"}})
          if(response && response.data && response.data.status === 200 ){
            dispatch(slice.actions.uploadPortfolioAudioSuccess(response.data));
          }else{
            dispatch(slice.actions.hasError(response.data))
          }
        } catch (error) {
          if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
        }
      };
    }
   
    export function AudioRouteForImage(data) {
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
          dispatch(slice.actions.AudioRouteSuccess(data));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };
    }

    export function ClearAudioSuccess() {
      return async () => {
        dispatch(slice.actions.startLoading());
        try { 
          dispatch(slice.actions.uploadPortfolioAudioSuccess([])); 
          dispatch(slice.actions.updateAudioSuccess([])); 
          dispatch(slice.actions.getAudioDeleteSuccess([])); 
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };
    }

    export function clearHasError(data) {
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
          dispatch(slice.actions.hasError([]));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };
    }

    export function cleargetAudiosSuccess(data) {
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
          dispatch(slice.actions.getAudiosSuccess([]));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };
    }