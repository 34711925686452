import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Link } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH, PATH_PROFILE } from '../../../routes/paths';
import { getProfileData, clearGetProfileData,talentLogout } from '../../../redux/slices/createProfile';
import { useDispatch, useSelector } from '../../../redux/store';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user, logout } = useAuth();
  const { currentUser, UpdateProdileImg } = useSelector((state) => {
    return {
      currentUser: state && state.profile && state.profile.profileImagelog,
      UpdateProdileImg: state && state.profile && state.profile.updateProfilePicRes && state.profile.updateProfilePicRes.response,
    };
  });
  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const [toPhoto, setToPhoto] = useState(PATH_PROFILE.locationsDetails);
  const [updateProfile, setUpdateProfile] = useState([])
  const userSlug = localStorage.getItem('userSlug');
  const userSlugImg = localStorage.getItem('userPhotourl');
  const [disName, setDisName] = useState("");
  useEffect(() => {
    if (userSlug && userSlug !== "" || userSlug !== undefined) {
      setDisName(userSlug)
    }
  }, [userSlug])
  useEffect(() => {
    if (UpdateProdileImg && UpdateProdileImg !== undefined && UpdateProdileImg.avatar_url) {
      setUpdateProfile(UpdateProdileImg.avatar_url)
    }
  }, [UpdateProdileImg])
  // useEffect(() => {
  //   if(userSlug && userSlug!=="" && userSlug!==undefined)
  //   {
  //     setDisName(userSlug)
  //   } 
  // },[userSlug && userSlug!==undefined && userSlug!==""]) 

  useEffect(() => {
    if (open && open === true) {
      setDisName(userSlug)
    }
  }, [open, userSlug])


  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = async () => {
    try {
      const tkEncID = localStorage.getItem('userEncId');
      const payload = {
        "enc_id": tkEncID,
        "device_type": "web"
      };
      dispatch(talentLogout(payload));
      await logout();
      navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
      dispatch(clearGetProfileData())
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (currentUser !== "") {
      setTimeout(() => {
        const userPhotourl = localStorage.getItem('userPhotourl');
        setUpdateProfile(userPhotourl)
      }, 1000);
    }
  }, [currentUser])

  console.log('updateProfile', updateProfile)
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar userPhotourl={updateProfile} />
      </IconButtonAnimate>

      <MenuPopover
        // className='Menupopover'
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}
        {(disName) ?
          <MenuItem >
            <Link sx={{ width: '100%', textAlign: 'start' }} component={RouterLink} to={`${toPhoto}/${disName}`} target='_blank'>
              Profile
            </Link>
          </MenuItem> : null}


        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ my: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
