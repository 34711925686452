// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          // boxShadow: theme.customShadows.card,
          // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px',
          // boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
          // boxShadow: '  rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
          // boxShadow: ' 0px 25px 80px rgba(0, 0, 0, 0.15)',
          boxShadow: ' 0px 3px 8px rgba(0, 0, 0, 0.24)',
          borderRadius: Number(theme.shape.borderRadius) * 2,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  };
}
