import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
// type
import { UploadAvatar, UploadMultiFile, UploadSingleFile, UploadMultiAudioFile, UploadMultiVideoFile, UploadSingleImageGalFile } from '../upload';
import UploadProfileFile from '../upload/UploadProfileFile';
import UploadProfileFileMobile from '../upload/UploadProfileFileMobile';
import UploadThemeProfileFile from '../upload/UploadThemeProfileFile';
import UploadSingleAudioFile from '../upload/UploadSingleAudioFile';
import UploadSingleVideoFileEdit from '../upload/UploadSingleVideoFileEdit';
import UploadPortfolioMultiPhotos from '../upload/UploadPortfolioMultiPhotos';
import UploadPortfolioMultiPhotosMobile from '../upload/UploadPortfolioMultiPhotosMobile';
import UploadPorfoliovideoMultiFile from '../upload/UploadPorfoliovideoMultiFile';
import UploadPorfoliovideoMultiFileMobile from '../upload/UploadPorfoliovideoMultiFileMobile';
import UploadPortfolioAudioMultiFile from '../upload/UploadPortfolioAudioMultiFile';
import UploadPortfolioAudioMultiFileMobile from '../upload/UploadPortfolioAudioMultiFileMobile';
// ----------------------------------------------------------------------

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar error={checkError} {...other} file={field.value} />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}
// ----------------------------------------------------------------------

// RHFUploadSingleFilePortfolio.propTypes = {
//   name: PropTypes.string,
// };

// export function RHFUploadSingleFilePortfolio({ name, ...other }) {
//   const { control } = useFormContext();

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field, fieldState: { error } }) => {
//         const checkError = !!error && !field.value;

//         return (
//           <UploadSingleFilePortfolio 

//             accept="image/*"
//             file={field.value}
//             error={checkError}
//             helperText={
//               checkError && (
//                 <FormHelperText error sx={{ px: 2 }}>
//                   {error.message}
//                 </FormHelperText>
//               )
//             }
//             {...other}
//           />
//         );
//       }}
//     />
//   );
// }

// ----------------------------------------------------------------------

RHFUploadSingleFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleImageGalFile

            accept="image/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
// ----------------------------------------------------------------------

RHFUploadMultiFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            accept="image/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
// ----------------------------------------------------------------------

RHFUploadMultiFilePortfolioPhoto.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiFilePortfolioPhoto({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadPortfolioMultiPhotos
            accept="image/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}



RHFUploadSingleAudioFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleAudioFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept="audio/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadSingleAudioFileEdit.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleAudioFileEdit({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleAudioFile
            accept="audio/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadProfileFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadProfileFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadProfileFile
            accept="image/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadProfileFileMobile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadProfileFileMobile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadProfileFileMobile
            accept="image/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadThemeProfileFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadThemeProfileFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadThemeProfileFile

            accept="image/*"
            file={other.src}
            // file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
// ----------------------------------------------------------------------

RHFUploadMultiAudioFilePortFolio.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiAudioFilePortFolio({ name, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadPortfolioAudioMultiFile
            accept="audio/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

RHFUploadMultiAudioFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiAudioFile({ name, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiAudioFile
            accept="audio/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadMultiVideoFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiVideoFile({ name, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiVideoFile
            accept="video/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadMultiVideoFilePortfolio.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiVideoFilePortfolio({ name, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadPorfoliovideoMultiFile
            accept="video/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadSingleVideoFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleVideoFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept="video/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadSingleVideoFileEdit.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleVideoFileEdit({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleVideoFileEdit
            accept="video/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ------------------Mobile--------------

export function RHFUploadMultiFilePortfolioPhotoMobile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadPortfolioMultiPhotosMobile
            accept="image/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadMultiAudioFilePortFolio.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiAudioFilePortFolioMobile({ name, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadPortfolioAudioMultiFileMobile
            accept="audio/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadMultiVideoFilePortfolioMobile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiVideoFilePortfolioMobile({ name, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadPorfoliovideoMultiFileMobile
            accept="video/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}