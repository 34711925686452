
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
//
import AudioBlockContent from './AudioBlockContent';
import AudioRejectionFiles from './AudioRejectionFiles';
import UploadImage from '../../images/PortfolioUploadImage.png';

import MultiFilePreviewAudio from './MultiFilePreviewAudio';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: theme.palette.background.neutral,
    // border: `1px dashed ${theme.palette.grey[500_32]}`,
    // '&:hover': { opacity: 0.72, cursor: 'pointer' },
    border: '4px dashed #d3e1ea',
}));

const UploadPortfolioAudioMultiFileMobile = ({
    error,
    showPreview = false,
    files,
    onRemove,
    onRemoveAll,
    saveAudio,
    onOpenModel,
    helperText,
    sx,
    ...other
}) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
    });
    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    }),
                }}
            >
                <input {...getInputProps()} />
                {/* <AudioBlockContent /> */}
                <Box >
                    <Box component='img' src={UploadImage} alt='Upload Image' className='PortfolioUploadImg' />

                    <Box sx={{ p: 1.2 }}>
                        <Typography gutterBottom variant="body1" color={'#44577a'}>  Browse or Drag & Drop Audio Files  </Typography>
                        <Typography variant="body2" sx={{ color: '#44577a', mt: 2 }} textAlign={'center'} >
                            Max Audio Size 20MB
                        </Typography>
                    </Box>
                </Box>
            </DropZoneStyle>

            {fileRejections.length > 0 && <AudioRejectionFiles fileRejections={fileRejections} />}

            {/* <MultiFilePreviewAudio  onOpenModel={onOpenModel} files={files} showPreview={showPreview} onRemove={onRemove} onRemoveAll={onRemoveAll}  saveAudio={saveAudio}/> */}

            {helperText && helperText}
        </Box>
    );
}

export default UploadPortfolioAudioMultiFileMobile

