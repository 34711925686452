import React from 'react'
import { Box, Typography, Stack,Grid } from '@mui/material';
// assets
import { LoadingButton } from '@mui/lab';
import { UploadIllustration } from '../../assets';

const AwardsBlockMod = () => {
    return (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
            <Grid container md={12} spacing={2}>
                <Grid item xs={12} md={12} sx={{textAlign:'center'}}>
                  <Box sx={{ml:"auto", mr:"auto",width:"30%" }}>
                    <UploadIllustration sx={{ width: 100,textAlign:'center' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="body2" sx={{ color: 'text.secondary',textAlign:'center'}}>
                      Drag and drop File here...
                    </Typography>
                </Grid>
            </Grid>
    
          {/* <Box sx={{ p: 3 }}>
            <Typography gutterBottom variant="h5">
              Drag & Drop Files 
            </Typography>
    
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Update audio file please click here...
            </Typography>
          </Box> */}
        </Stack>
      );
}

export default AwardsBlockMod;