import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  uploadAudioResponse:[],
  updateVideoResponse:[],
  getVideoDeleteResponse:[],
  getVideosResponse:[],
  updateAudioRoute:[],
  videoUploadSuccess:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getVideosSuccess(state, action) {
      state.isLoading = false;
      state.getVideosResponse = action.payload;
    },

    getVideoDeleteSuccess(state, action) {
      state.isLoading = false;
      state.getVideoDeleteResponse = action.payload;
    },

    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
     
    updateVideoSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.updateVideoResponse = action.payload;
    },

    uploadPortfolioVideoSuccess(state, action) {
      state.isLoading = false;
      state.videoUploadSuccess = action.payload;
    },
    

    AudioRouteSuccess(state, action) {
      state.isLoading = false;
      state.updateAudioRoute = action.payload;
    },
    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

  export function getVideos(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_talent_portfolio/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getVideosSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function getVideosDelete(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_multiple_portfolio/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.getVideoDeleteSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }

  export function uploadPortfolioVideo(data) {
    const tokenVal = window.localStorage.getItem("accessToken")
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await axios.post('/upload_portfolio/',data,{headers: {"Content-type": "multipart/form-data"}})
          if(response && response.data && response.data.status === 200 ){
            dispatch(slice.actions.uploadPortfolioVideoSuccess(response.data));
          }else{
            dispatch(slice.actions.hasError(response.data))
          }
        } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
        }
      };
    }

  export function updateVideoData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_talent_portfolio/',data,{headers: {"Content-type": "multipart/form-data"}})
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.updateVideoSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
      }
    };
  }
  export function ClearVideoSuccess() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try { 
        dispatch(slice.actions.uploadPortfolioVideoSuccess([])); 
        dispatch(slice.actions.updateVideoSuccess([])); 
        dispatch(slice.actions.getVideoDeleteSuccess([])); 
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

   
//     export function AudioRouteForImage(data) {
//       return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//           dispatch(slice.actions.AudioRouteSuccess(data));
//         } catch (error) {
//           dispatch(slice.actions.hasError(error));
//         }
//       };
//     }

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleargetVideosSuccess(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getVideosSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}