import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import VideoBlockContent from './VideoBlockContent';
import VideoRejectionFiles from './VideoRejectionFiles';
import MultiFilePreviewVideo from './MultiFilePreviewVideo'; 

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': { opacity: 0.72, cursor: 'pointer' },
  }));

const UploadPorfoliovideoMultiFile = ({
    error,
    showPreview = false,
    files,
    saveVideo,
    onRemove,
    onRemoveAll,
    onOpenModel,
    helperText,
    sx,
    ...other
  }) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
      });
  return (
    <Box sx={{ width: '100%', ...sx }}>
    <DropZoneStyle
      {...getRootProps()}
      sx={{
        ...(isDragActive && { opacity: 0.72 }),
        ...((isDragReject || error) && {
          color: 'error.main',
          borderColor: 'error.light',
          bgcolor: 'error.lighter',
        }),
      }}
    >
      <input {...getInputProps()} />

      <VideoBlockContent />
    </DropZoneStyle>

    {fileRejections.length > 0 && <VideoRejectionFiles fileRejections={fileRejections} />}

    {/* <MultiFilePreviewVideo  onOpenModel={onOpenModel} files={files} showPreview={showPreview} onRemove={onRemove} onRemoveAll={onRemoveAll}  saveVideo={saveVideo}/> */}

    {helperText && helperText}
  </Box>
  )
}
UploadPorfoliovideoMultiFile.propTypes = {
    error: PropTypes.bool,
    showPreview: PropTypes.bool,
    files: PropTypes.array,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    helperText: PropTypes.node,
    sx: PropTypes.object,
  };
export default UploadPorfoliovideoMultiFile




// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
