import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess:[],
  cities:[],
  account:[],
  updareAccount:[],
  updareVerifyOtpSettings:[],
  verifyMobileOtpSettings:[],
  verifyPasswordMobileOtpSettings:[],
  updareEmail:[],
  updareMobile:[],
  notification:[],
  updateNotification:[],
  updateChangePassword:[],
  deActivate:[],
  deleteAccount:[],
  profileVisibility:[],
  updateProfileVisibility:[],
  AccountTalentHelp :[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  sessionLogoutProfile:'',
  sessionLogoutMobile:'',
  resendOtpProfile:'',
  resendOtpEmail:'',
  changepasswordSettingsResp:[],
  ForgotResendResp:[],
  getDeactivatePauseJob:[],
  getManageAccSuccResp:[],
  deActivatePauseJob:[],
  updateJobStatusResp:[],
  getJobStatusResp:[],
  OtpSendChangeAccountStatusResp:[],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    profileVisibilityUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updateProfileVisibility = action.payload;
    },

    deleteAccountUpdateSuccess(state, action) {
      state.isLoading = false;
      state.deleteAccount = action.payload;
    },

    updateJobStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateJobStatusResp = action.payload;
    },

    getJobStatusSuccess(state, action) {
      state.isLoading = false;
      state.getJobStatusResp = action.payload;
    },

    deActivateUpdateSuccess(state, action) {
      state.isLoading = false;
      state.deActivate = action.payload;
    },

    deActivateUpdateJobPausedSuccess(state, action) {
      state.isLoading = false;
      state.deActivatePauseJob = action.payload;
    },

    changePasswordUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updateChangePassword = action.payload;
    },


    AccounttalenthelpSuccess(state, action) {
      state.isLoading = false;
      state.AccountTalentHelp = action.payload;
    },
    
    notificationUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updateNotification = action.payload;
    },

    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notification = action.payload;
    },

    getProfileVisibilitySuccess(state, action) {
      state.isLoading = false;
      state.profileVisibility = action.payload;
    },

    getAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    },
    accountUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updareAccount = action.payload;
    },
    verifyOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.updareVerifyOtpSettings = action.payload;
    },
    verifyMobileOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.verifyMobileOtpSettings = action.payload;
    },
    verifyPasswordMobileOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.verifyPasswordMobileOtpSettings = action.payload;
    },
    emailUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updareEmail = action.payload;
    },
    mobileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updareMobile = action.payload;
    },
    sessionLogoutSuccess(state, action) {
      state.isLoading = false;
      state.sessionLogoutProfile = action.payload;
    },
    sessionLogoutMobileSuccess(state, action) {
      state.isLoading = false;
      state.sessionLogoutMobile = action.payload;
    },
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
    getStateSuccess(state, action) {
      state.isLoading = false;
      state.statess = action.payload;
    },
    getCitySuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },
    ProfileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },
    resendOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.resendOtpProfile = action.payload;
    },
    resendOtpSettingsDataSuccess(state, action) {
      state.isLoading = false;
      state.resendOtpEmail = action.payload;
    },
    changepasswordSettingsSuccess(state, action) {
      state.isLoading = false;
      state.changepasswordSettingsResp = action.payload;
    },
    ForgotResendSuccess(state, action) {
      state.isLoading = false;
      state.ForgotResendResp = action.payload;
    },
    OtpSendChangeAccountStatusSuccess(state, action) {
      state.isLoading = false;
      state.OtpSendChangeAccountStatusResp = action.payload;
    },
    // CREATE EVENT 
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    getJobPauseSuccess(state, action) {
      state.isLoading = false;
      state.getDeactivatePauseJob = action.payload;
    },
    getManageAccountSuccess(state, action) {
      state.isLoading = false;
      state.getManageAccSuccResp = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getProfileData() {
  const tokenVal = window.localStorage.getItem("accessTokenTalent")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/personal_information/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            `Bearer ${tokenVal}`,
        },
      });
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getEventsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getState(countryData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const CoutryData = {
      country_name : countryData
    }
    try {
      const response = await axios.post('/get_states/', CoutryData);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getStateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function getCity(countryAndstateId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_cities/', countryAndstateId);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getCitySuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_update_account/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.accountUpdateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function verifyEmailOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/verify_otp_settings/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.verifyOtpSettingsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function verifyMobileOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/verify_otp_settings/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.verifyMobileOtpSettingsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function verifyPasswordMobileOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/verify_otp_settings/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.verifyPasswordMobileOtpSettingsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function resendOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/resend_otp_settings/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.resendOtpSettingsDataSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ChangePasswordSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_change_password_send_otp/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.changepasswordSettingsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ForgotResend(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/userResendOtp/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.ForgotResendSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}


export function OtpSendChangeAccountStatus(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/statusotp_settings/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.OtpSendChangeAccountStatusSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ClearOtpSendChangeAccountStatus(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.OtpSendChangeAccountStatusSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function ClearVerifyMobileOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifyMobileOtpSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearVerifyPasswordMobileOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifyPasswordMobileOtpSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearChangePassword(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.changepasswordSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearResendOtpClear(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resendOtpSettingsDataSuccess(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEmailOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/check_mobile_or_email_valid/',data)
      console.log('response',response);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.emailUpdateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateMobleOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/check_mobile_or_email_valid/',data)
      console.log('response',response);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.mobileUpdateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function clearUpdateAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.emailUpdateSuccess([]));
      dispatch(slice.actions.mobileUpdateSuccess([]));
      dispatch(slice.actions.verifyOtpSettingsSuccess([]));
      dispatch(slice.actions.verifyMobileOtpSettingsSuccess([]));
      dispatch(slice.actions.sessionLogoutSuccess(''));
      dispatch(slice.actions.sessionLogoutMobileSuccess(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearverifyOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifyOtpSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logoutProfilrSettings(data) {  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sessionLogoutSuccess(data));
      // await logout();
        // navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logoutMobileSettings(data) {  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sessionLogoutMobileSuccess(data));
      // await logout();
        // navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccountData() {
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/talent_get_account/') ;
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getAccountSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.accountUpdateSuccess([]));
      dispatch(slice.actions.error([]));
      // dispatch(slice.actions.changePasswordUpdateSuccess([]));
      dispatch(slice.actions.notificationUpdateSuccess([]));
      dispatch(slice.actions.deActivateUpdateSuccess([]));
      dispatch(slice.actions.deActivateUpdateJobPausedSuccess([]));
      dispatch(slice.actions.getNotificationSuccess([]));
      dispatch(slice.actions.profileVisibilityUpdateSuccess([]));
      dispatch(slice.actions.getProfileVisibilitySuccess([]));
      dispatch(slice.actions.getJobStatusSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error)); 
    }  
  };
}

export function ClearPassChanged(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.changePasswordUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error)); 
    }  
  };
}

export function ClearJobPausedSuccess(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deActivateUpdateJobPausedSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error)); 
    }
  };
}

export function getNotificationData() {
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/talent_get_notification/') ;
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getNotificationSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_update_notification/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.notificationUpdateSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      } 
    }
  };
}

export function updateChangePassword(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_change_password/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.changePasswordUpdateSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      } 
    }
  };
}




export function AccountTalentHelp() {
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_talent_setting_help/') ;
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.AccounttalenthelpSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}



export function updateDeActivate (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_change_status/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.deActivateUpdateSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ClearUpdateDeActivate (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      dispatch(slice.actions.deActivateUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDeActivateJobPaused (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_job_search_disabled/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.deActivateUpdateJobPausedSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateJobStatusSetting (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_update_jobseek_visibility/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.updateJobStatusSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getJobStatusSetting (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/talent_get_jobseek_visibility/')
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getJobStatusSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateDeleteAccount () {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/talent_delete_account/',tokenVal)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.deleteAccountUpdateSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      } 
    }
  };
}

export function clearUpdateJobStatusSetting (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateJobStatusSuccess([]));
     } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleargetJobStatusSetting (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getJobStatusSuccess([]));
     } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearUpdateDeleteAccount (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteAccountUpdateSuccess([]));
     } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfileVisibility() {
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/talent_get_profile_visibility/') ;
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getProfileVisibilitySuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}


export function updateProfileVisibilityData (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/talent_update_profile_visibility/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.profileVisibilityUpdateSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      
    } catch (error) {
       if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getJobPauseData(data) {
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/talent_job_search_disabled/',data) ;
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getJobPauseSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getManageAccount(data) {
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_manage_account/',data) ;
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getManageAccountSuccess(response));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function clearManageAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getManageAccountSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function cleargetProfilevisibility(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.profileVisibilityUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}