import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  openModalData: [],
  PostPhysicalData:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getOpenOtpModelSucc(state, action) {
      state.isLoading = false;
      state.openModalData = action.payload;
    },
    postPhysicalSucc(state, action) {
        state.isLoading = false;
        state.PostPhysicalData = action.payload;
        // console.log("cdfggfsgf",action.payload)
      },
   
   // CREATE EVENT
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function flagForVerifyOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getOpenOtpModelSucc(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearflagForVerifyOtp(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getOpenOtpModelSucc([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }


