import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  deleteData:[],
  saveDataRes:[],
  workCredUpdate:[],
  skipWorkCredUpdate:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
    DeleteWorkCreditSucc(state, action) {
      state.isLoading = false;
      state.deleteData = action.payload;
    },
    workCreditUpdateSuccess(state, action) {
      state.isLoading = false;
      state.saveDataRes = action.payload;
    },

    workCreditSuccess(state, action) {
      state.isLoading = false;
      state.workCredUpdate = action.payload;
    },

    skipWorkCreditSuccess(state, action) {
      state.isLoading = false;
      state.skipWorkCredUpdate = action.payload;
    },

    // // CREATE EVENT
    // createEventSuccess(state, action) {
    //   const newEvent = action.payload;
    //   state.isLoading = false;
    //   state.events = [...state.events, newEvent];
    // },

    // // UPDATE EVENT
    // updateEventSuccess(state, action) {
    //   const event = action.payload;
    //   const updateEvent = state.events.map((_event) => {
    //     if (_event.id === event.id) {
    //       return event;
    //     }
    //     return _event;
    //   });

    //   state.isLoading = false;
    //   state.events = updateEvent;
    // },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------
 
export function getWorkCredit(data) {
  const tokenVal = window.localStorage.getItem("accessTokenTalent")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_work_credit/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getEventsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateWorkCredit(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/save_work_credit/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.workCreditUpdateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
    }
  };
}
// skip_work_credit


export function skipWorkCredit(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/skip_work_credit/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.skipWorkCreditSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      } 
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
    }
  };
}

export function clearSkipWorkCredit() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.skipWorkCreditSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function workCredit(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/save_work_credit/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.workCreditSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      } 
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
    }
  };
}

export function DeleteWorkCredit(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_work_credit/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.DeleteWorkCreditSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      } 
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
    }
  };
}

export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteWorkCreditSucc([]));
      dispatch(slice.actions.workCreditUpdateSuccess([]));
      dispatch(slice.actions.hasError([]));
      dispatch(slice.actions.workCreditSuccess([]));
      dispatch(slice.actions.skipWorkCreditSuccess([])); 
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}