import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
import { DesktopDatePicker } from '@mui/lab';
// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div id={error?.message &&  "errorDate"}>

          <DesktopDatePicker {...field} fullWidth error={!!error} helperText={error?.message} {...other}>
            .
          </DesktopDatePicker>
          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {/* {error.message} */}
              {/* Date is required */}
              {(name && name === 'year' || other.id==='yearpicker') ? 'Year is required' : 'Date is required' }
            </FormHelperText>
          )}{' '}
        </div>
      )}
    />
  );
}
