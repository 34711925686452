import { useState, useEffect } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Grid, Paper } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/LoginLogo';
import LoginImageBlock from '../../components/LoginImage';
// import Image from '../../components/Image';

// sections
import { LoginForm } from '../../sections/auth/login';
import SocialMedia from './SocialMediaLogin';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { AuthSlider } from '../../components/carousel/index'
import CopyrightIcon from '@mui/icons-material/Copyright';
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
import Logo1 from '../../images/logo.jpg'
import smallLogo from '../../images/TK_loader.png'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(0, 0, 0, 0),
  background: '#f8f8f8',
  borderRadius: '0',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '60%',
  margin: 'auto',
  top: '10%',
  bottom: '0',
  height: 580,
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: '0 0 15px #eee',
  border: '2px solid #eceef1',
  borderRadius: '15px',
  padding: theme.spacing(4),
  left: '0',
  right: '0',
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();
  const [width, setWidth] = useState(1366)
  const navigate = useNavigate();
  const [agentId, setAgentId] = useState('');

  // useEffect(() => {
  //   setWidth(window.innerWidth)
  //   window.addEventListener('resize', () => {
  //     setWidth(window.innerWidth)
  //   })
  // }, [width]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const currentUrl = window.location.href;
  const segment = currentUrl ? currentUrl.split('/').slice(-2)[0] : []
  const slugSegment = currentUrl ? currentUrl.substring(currentUrl.lastIndexOf("/") + 1) : [];


  const [agtParam, setAgtParam] = useState('');
  const [AgentIDVal, setAgentIDVal] = useState('');
  const [encodedEncID, setEncodedEncID] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(new URL(currentUrl).search);
    const agtValue = params.get('agt');

    setAgtParam(agtValue);

    if (agtValue) {
      const [AgentIDVal, data] = agtValue.split('/');
      setAgentIDVal(AgentIDVal);
      setEncodedEncID(data);
    }
  }, []);

  console.log('Login64563637', AgentIDVal, encodedEncID)

  // useEffect(() => {
  //   if (slugSegment !== 'invite') {
  //     // if(slugSegment!=='register'){
  //     const agentIdSlug = slugSegment ? slugSegment.substring(slugSegment.lastIndexOf("?") + 5) : []
  //     console.log(agentIdSlug, 'currentUrl46875687')
  //     const IdAgent = localStorage.setItem('agentIdLogin', agentIdSlug);
  //     // setAgentId(agentIdSlug)
  //   }
  // }, [slugSegment])

  useEffect(() => {
    if (AgentIDVal !== 'invite' && AgentIDVal !== '' && AgentIDVal !== undefined && AgentIDVal !== null) {
      const IdAgent = localStorage.setItem('agentIdLogin', AgentIDVal);
    }
  }, [AgentIDVal]);

  useEffect(() => {
    if (encodedEncID !== 'invite' && encodedEncID !== '' && encodedEncID !== undefined && encodedEncID !== null) {
      const IdAgent = localStorage.setItem('agentMappingID', encodedEncID);
    }
  }, [encodedEncID]);

  const mdUp = useResponsive('up', 'md');

  const UserHaveAgentID = localStorage.getItem('agentIdLogin');
  const UserHaveAgentSlug = localStorage.getItem('agentUserSlug');
  const UserAccesToken = localStorage.getItem('accessTokenTalent');
  console.log(UserHaveAgentID, 'abcd32534534534', UserAccesToken);

  useEffect(() => {
    if (UserHaveAgentID && UserHaveAgentID !== '' && UserHaveAgentID !== undefined && UserHaveAgentID !== null && UserAccesToken && UserAccesToken !== '' && UserAccesToken !== undefined && UserAccesToken !== null && UserHaveAgentID !== 'onal-info') {
      navigate(`${PATH_DASHBOARD.user.AgentDetails}/${UserHaveAgentID}`, { replace: true });
    }
  }, [UserHaveAgentID, UserAccesToken]);

  return (<>
    <Container maxWidth="xxl" style={{ padding: 0 }} id="LoginPageNew"  >
      {/* <Box style={{ position: "relative" }} id="LoginPageNewBox" sx={{ pb: { xs: '15rem', sm: 0 }, height: { xs: , sm: '100%' } }}> */}
      <Box style={{ position: "relative", height: "80vh !important" }} id="LoginPageNewBox" >
        <AuthSlider />
        <Box id="overlay" >
          <header style={{ marginTop: '8px' , width:"fit-content"}}>
            <Box >
            <a href="https://talentkind.com/" >
              <img src={Logo1} alt="logo" className="logoImage" />
            </a>
            </Box>
          </header>
          <Container maxWidth="xxl" style={{ padding: 0 }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={4} md={3} className='Z_index'>
                <Box pl={'4px'} pb={2}>
                  <Box>
                    <Typography className='loginH1' fontSize={{ xs: '1rem', md: '1.25rem' }} gutterBottom align="left">
                      {
                        width > 768 ? <>create a <br />
                          talentkind<br />
                          profile to join<br />
                          the countrywide<br />
                          database<br />
                          of talents and<br />
                          kick start<br />
                          your career<br />
                          in the media<br />
                          arts & the entertainment<br />
                          industry!</> : <>create a talentkind profile to join the countrywide database of talents and kick start your career in the media arts & the entertainment industry!</>
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" gutterBottom className="para" align="justify">Talentkind is India’s first centralised database of talents across the 21 verticals of the Media, Entertainment and Arts industry. It is an exclusive platform where talent connects with the talent seeker. A detailed talent profile at talentkind that includes your skillset, work portfolio, interests and professional goals will not only help in talent’s employability but also enable employers locate talent across India and offer better work opportunities.  </Typography>
                    <Typography variant="body1" gutterBottom className="para" align="justify">Talentkind website and connect app not only acts a job portal for the talents but also doubles as a data repository of the talents, companies, employer organisations and skill providers within the MEA industry.</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={7} md={5.5} xl={4} xxl={3.5} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} className='Z_index'>
                <Box p={width > 600 ? 3 : 1} component={Paper} style={{ width: '100%' }}>
                  <Box>
                    <Typography variant="h4" gutterBottom>
                      Sign in
                    </Typography>
                  </Box>
                  <Box>
                    <LoginForm />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
          {/* <footer className='AuthFooter'>
            <Grid container>
              <Grid item xs={12}>
                <a href="#">
                  <img src={Logo1} alt="logo" className="logoImage" />
                </a>
                <Box mt={{ xs: 1, sm: 0 }} id="footerMenus">
                  <ul>
                    <li><a target='_blank' href="https://talentkind.com/cookie.html">Cookie Policy</a></li>
                    <li><a target='_blank' href="https://talentkind.com/privacy.html">Privacy Policy</a></li>
                    <li><a target='_blank' href="https://talentkind.com/terms.html">Terms of Service</a></li>
                    <li>Copyright &copy; {new Date().getFullYear()} TalentKind</li>
                  </ul>
                </Box>

              </Grid>
            </Grid >
          </footer > */}

          <footer className='AuthFooter'>
            <Grid container >
              <Grid item xs={12}>
                <Box mt={{ xs: 1, sm: 0 }} mb={2} mx={{ xs: 1, sm: 0 }} id="footerMenus">
                  <ul style={{ flexWrap: 'wrap', alignItems: 'end' }}>
                    <li><a href="https://talentkind.com"><img src={smallLogo} alt="logo" style={{ width: '45px' }} /></a></li>
                    <li><a target='_blank' href="https://talentkind.com/cookie.html">Cookie Policy</a></li>
                    <li><a target='_blank' href="https://talentkind.com/privacy.html">| Privacy Policy</a></li>
                    <li><a target='_blank' href="https://talentkind.com/terms.html">| Terms of Service</a></li>
                    <li>| Copyright &copy; {new Date().getFullYear()} TalentKind Solutions LLP</li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </footer>

        </Box >
      </Box >
    </Container >
  </>
  );
}
