import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// import logo from '../assets/'
import Logos from '../images/TK-LOGO.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = true, sx }) {

  const logo = (
    <Box sx={{ width: 200, ...sx }} className="header-logo-container">
      <img src={Logos} alt="logo" />      
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/login" className='logiLoginHolder'>{logo}</RouterLink>;
}
