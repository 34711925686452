import useAuth from '../hooks/useAuth';
// utils

export default function DateFormat({ date }) {
  const { user } = useAuth();
  // return (
  //   // <p  style={{color:'#4a5c7e',fontWeight:'700',marginLeft: '10px'}}>
  //       <b>{date
  //       ? new Date(date).toLocaleDateString('en-us', {
  //           year: 'numeric',
  //           month: 'short',
  //           day: 'numeric',
  //       })
  //   : 'NA'}</b>
  //   // </p>
  // );
  return (
    <>{date
      ? new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, ' ')
      : 'NA'}</>
  );
}
