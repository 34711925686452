import React from 'react'
import Slider from "react-slick";
import { Icon } from '@iconify/react';


const AuthSlider = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        loop: true,
        prevArrow: <Icon icon="ep:arrow-left-bold" color="white" width="110" height="200" />,
        nextArrow: <Icon icon="ep:arrow-left-bold" color="white" width="110" height="200" rotate={2} />,
    };

    return (
        <>
            <Slider {...settings} className="loginSwiper">
                <div className="slide" id="slide1"></div>
                <div className="slide" id="slide2"></div>
                <div className="slide" id="slide3"></div>
            </Slider>
        </>
    )
}

export default AuthSlider