import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, Card, Typography, Grid, Avatar, Link, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
// import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
// import NavbarDocs from './NavbarDocs';
// import NavbarAccount from './NavbarAccount';
import MyAvatar from '../../../components/MyAvatar';
import Logo from '../../../images/ProfileImg.jpg';
import { getProfileData, clearGetProfileData } from '../../../redux/slices/createProfile';
import { useDispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD, PATH_AUTH, PATH_PROFILE } from '../../../routes/paths';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const [profile, setProfile] = useState({});
  const [disName, setdisName] = useState('');
  const [professionName, setProfessionName] = useState('');
  const [firstTimePage, setfirstTimePage] = useState('Yes');
  const [updateProfile, setUpdateProfile] = useState([]);

  //
  const [toPhoto, setToPhoto] = useState(PATH_PROFILE.locationsDetails);
  const userSlug = localStorage.getItem('userSlug');
  const userSlugVerify = localStorage.getItem('userSlugVerify');
  const userSlugImg = localStorage.getItem('userPhotourl');
  const [disslugName, setDisSlugName] = useState("");

  useEffect(() => {
    if (userSlug && userSlug !== "" && userSlug !== undefined && userSlugVerify && userSlugVerify !== "" && userSlugVerify !== null && userSlugVerify !== undefined && userSlugVerify === 1) {
      console.log(userSlugVerify, 'userSlugVerify67567')
      setDisSlugName(userSlug)
    }
  }, [userSlug, userSlugVerify])


  const { currentProfile, currentUser } = useSelector((state) => {
    return {
      currentProfile: state && state.profile && state.profile.events && state.profile.events.response,
      currentUser: state && state.profile && state.profile.profileImagelog,
    };
  });

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (currentProfile && currentProfile !== undefined && currentProfile.user && Object.keys(currentProfile.user).length > 0) {
      setProfile(currentProfile.user);
      setUpdateProfile(currentProfile.user.avatar_url)
      setdisName(currentProfile.user.display_name);
      setProfessionName(currentProfile && currentProfile.primary_profession);
    }
  }, [currentProfile]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (currentUser !== "") {
      setTimeout(() => {
        const userPhotourl = localStorage.getItem('userPhotourl');
        const userName = localStorage.getItem('userDisplayName');
        const userProfessionName = localStorage.getItem('userProfessionName');

        setdisName(userName)
        setUpdateProfile(userPhotourl)
        setProfessionName(userProfessionName)
      }, 1000);
    }
  }, [currentUser])

  const userProfessionName1 = localStorage.getItem('userProfessionName');
  useEffect(() => {
    setTimeout(() => {
      const userProfessionName = localStorage.getItem('userProfessionName');
      setProfessionName(userProfessionName)
    }, 1000);
  }, [userProfessionName1])


  const userPhotourl1 = localStorage.getItem('userPhotourl');
  useEffect(() => {
    if (userPhotourl1 !== "") {
      setTimeout(() => {
        setUpdateProfile(userPhotourl1)
      }, 1000);
    }
  }, [userPhotourl1]);

  const renderContent = (
    <Scrollbar
      className='sideBar'
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        borderRadius: 100,
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              // borderRightStyle: 'solid',
              top: 80,
              pl: 1,
              pr: 1,
              zIndex: 100,
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >


          <Box>
            <Card sx={{ mr: 0.5, ml: 0.3, mt: 2, p: 1, mb: 1.8, minHeight: '6rem' }} className="isLogoBoxShadow">
              <Grid container   >
                <Grid item md={4}>
                  <Box sx={{ mt: 1 }}>
                    <MyAvatar sx={{ height: "55px", width: "55px", textAlign: 'center', borderRadius: "50px" }} userPhotourl={updateProfile} />
                  </Box>
                </Grid>
                {/* <Grid item md=  {6} sx={{ mt: 1 }}> */}
                <Grid item md={disslugName && disslugName !== '' && disslugName !== null ? 6 : 8} sx={{ mt: 1 }}>
                  {/* <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>{disName && disName !== '' ?
                    <span>
                      {disName.length > 16 ? disName.substring(0, 16) : disName}
                    </span>

                    : ''}</Typography> */}


                  {disName && disName !== '' && disName.length > 16 ?
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', cursor: 'pointer' }}>
                      <Tooltip title={disName}>
                        <span style={{ display: 'inline-block' }} className='firstLetter transform'>{disName.substring(0, 16)}</span>
                      </Tooltip>
                    </Typography>
                    :
                    <Typography className='firstLetter transform' sx={{ fontSize: "16px", fontWeight: "bold" }}>{disName && disName !== '' ? disName : ''}</Typography>
                  }
                  <Typography sx={{ fontSize: "13px", color: "#777777", fontWeight: "bold" }}>{professionName && professionName !== '' ?
                    <span>{professionName.length > 14 ? professionName.substring(0, 16) : professionName} </span> : ''} </Typography>
                </Grid>
                {disslugName && disslugName !== '' && disslugName !== null ?
                  <Grid item md={2} sx={{ mt: 1 }} textAlign={'center'}>
                    <Link sx={{ width: '100%', textAlign: 'start' }} component={RouterLink} to={`${toPhoto}/${disslugName}`} target='_blank'>
                      <Icon icon="grommet-icons:share" color="black" width="17" height="17" />
                    </Link>
                  </Grid>
                  : null}
              </Grid>
            </Card>
          </Box>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
