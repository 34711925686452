import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  themeGetData: [],
  resumeGetData: [],
  resumeSingleGetData: [],
  resumeGetDeleteData: [],
  coverLetterGetDeleteData: [],
  saveDataRes: [],
  coverGetData: [],
  SelectedThemeGetData: [],
  DeleteselectedThemeDataResp: [],
  SaveselectedThemegetData: [],
  PublishSaveSelectedDataResp: [],
  singleDefaultThemeSuccResp: [],
  ThemeDataResp: [],
  CheckPlansTalentSuccResp: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getThemesData(state, action) {
      state.isLoading = false;
      state.themeGetData = action.payload;
    },

    getSingleResumesData(state, action) {
      state.isLoading = false;
      state.resumeSingleGetData = action.payload;
    },


    getResumesData(state, action) {
      state.isLoading = false;
      state.resumeGetData = action.payload;
    },

    getResumesDeleteData(state, action) {
      state.isLoading = false;
      state.resumeGetDeleteData = action.payload;
    },

    getCoverLettersDeleteData(state, action) {
      state.isLoading = false;
      state.coverLetterGetDeleteData = action.payload;
    },

    coverLetterSaveSuccess(state, action) {
      state.isLoading = false;
      state.saveDataRes = action.payload;
    },


    getCoverData(state, action) {
      state.isLoading = false;
      state.coverGetData = action.payload;
    },

    SelectedThemeData(state, action) {
      state.isLoading = false;
      state.SelectedThemeGetData = action.payload;
    },

    saveSelectedThemeData(state, action) {
      state.isLoading = false;
      state.SaveselectedThemegetData = action.payload;
    },

    PublishSaveSelectedData(state, action) {
      state.isLoading = false;
      state.PublishSaveSelectedDataResp = action.payload;
    },

    singleDefaultThemeSucc(state, action) {
      state.isLoading = false;
      state.singleDefaultThemeSuccResp = action.payload;
    },

    DeleteSelectedThemeData(state, action) {
      state.isLoading = false;
      state.DeleteselectedThemeDataResp = action.payload;
    },

    getThemeData(state, action) {
      state.isLoading = false;
      state.ThemeDataResp = action.payload;
    },

    CheckPlansTalentThemesData(state, action) {
      state.isLoading = false;
      state.CheckPlansTalentSuccResp = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------


export function getProfileThemeData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_themes/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getThemesData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getSingleResumeData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //   const response = await axios.post('/get_profession_themes/',data);
      const response = await axios.post('/get_single_resume/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSingleResumesData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getResumeData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //   const response = await axios.post('/get_profession_themes/',data);
      const response = await axios.post('/get_resume/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getResumesData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getResumeDeleteData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_resume/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getResumesDeleteData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCoverLetterDeleteData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_cover_letter/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCoverLettersDeleteData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function saveCoverLetterData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/create_cover_letter/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.coverLetterSaveSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCoverData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_profession_cover_letters/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCoverData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function setSelectedTheme(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/set_default_theme/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SelectedThemeData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function setSaveSelectedTheme(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_talent_themes/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveSelectedThemeData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function PublishSaveSelectedTheme(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_talent_themes/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.PublishSaveSelectedData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getSingleDefaultTheme() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_talent_single_themes/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.singleDefaultThemeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function setDeleteSelectedTheme(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_talent_themes/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteSelectedThemeData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getTalentThemes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_talent_themes/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getThemeData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function CheckPlansTalentThemes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/talent_themeplan_check/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.CheckPlansTalentThemesData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearCheckPlansThemes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CheckPlansTalentThemesData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearDeleteCoverLetters() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCoverLettersDeleteData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSingleTheme() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.singleDefaultThemeSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CleargetMiddleTheme() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getThemesData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CleargetTheme() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getThemeData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CleardeleteSelectedTheme() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteSelectedThemeData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearsaveSelectedTheme() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveSelectedThemeData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearPublishSaveSelected() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.PublishSaveSelectedData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSelectedTheme() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SelectedThemeData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearCoverletters() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.coverLetterSaveSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasErrorThemes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}