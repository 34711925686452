import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, CardContent, Card, Grid, Typography, Button, Skeleton } from '@mui/material';
import MyAvatar from '../../../components/MyAvatar';
//
import { useDispatch, useSelector } from '../../../redux/store';

import { getForMenuCheck, getForMenuCheckOnce } from '../../../redux/slices/ChecksMenu';
import { NavListRoot } from './NavList';
import { PATH_DASHBOARD } from '../../../routes/paths'

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const navigate = useNavigate();

  const { currentProfile, currentUser } = useSelector((state) => {
    return {
      currentProfile: state && state.profile && state.profile.events && state.profile.events.response,
      currentUser: state && state.profile && state.profile.profileImagelog,
    };
  });


  const [disName, setdisName] = useState('');
  const [professionName, setProfessionName] = useState('');
  const [updateProfile, setUpdateProfile] = useState([]);

  const dispatch = useDispatch();
  const { checkFirstMenu } = useSelector((state) => {
    return {
      checkFirstMenu: state && state.profile && state.profile.events && state.profile.events.response
    };
  });

  console.log(currentUser, '54534486');
  useEffect(() => {
    if (currentUser !== "") {
      setTimeout(() => {
        const userPhotourl = localStorage.getItem('userPhotourl');
        const userName = localStorage.getItem('userDisplayName');
        const userProfessionName = localStorage.getItem('userProfessionName');

        setdisName(userName)
        setUpdateProfile(userPhotourl)
        setProfessionName(userProfessionName)
        setIsLoading(false);
      }, 1000);
    }
  }, [currentUser])

  console.log(disName, professionName, updateProfile, '55557')

  const [isLoading, setIsLoading] = useState(true)


  return (
    <Box px={2} {...other} >
      <Box component={Button} mt={3} className="sidebar-user-info" onClick={() => navigate(PATH_DASHBOARD.setting)} fullWidth>
        {isLoading ?
          <Skeleton animation="wave" variant="rounded" sx={{ backgroundColor: "#e4e4e4", height: '6rem', width: '100%' }} />
          :
          <Card className='cardRadius'>
            <CardContent style={{ padding: "12px" }}>
              <Grid container>
                <Grid item xs={4}>
                  <Box>
                    <MyAvatar sx={{ height: "55px", width: "55px", textAlign: 'center', borderRadius: "50px" }} userPhotourl={updateProfile} />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }} align="left">{disName && disName !== '' ?
                    <span>{disName.length > 20 ? disName.substring(0, 20) : disName} </span>
                    : ''}</Typography>
                  <Typography sx={{ fontSize: "13px", color: "#777777", fontWeight: "bold" }} align="left">{professionName && professionName !== '' ?
                    <span>{professionName.length > 18 ? professionName.substring(0, 18) : professionName} </span> : ''} </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        }
      </Box>
      {navConfig.map((group) => (
        <List className="mainTitle" key={group.subheader} disablePadding disabled>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle >
          {group.items.map((list) => (
            <NavListRoot className="profileTitle" key={list.title} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
