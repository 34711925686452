import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  industriesData: [],
  UpdateCategoryData:[],
  industriesSecData:[],
  UpdateCategorySecData:[],
  ChangeCategorySecData:[],
  ProfessionDeleteSuccess:[],
  ProfessionDepartData:[],
  ProfessionDeleteData:[],
  PrimaryProfessionData:[],
  ProfessionRoleTypelist:[],
  ClearPrimaryProfessionSuccess:[],
  professionsData:[],
  talentprofessionsData:[],
  onlyprofessionsData:[],
  SaveProfessionData:[],
  SaveFirstProfessionData:[],
  professionSeclectedData:[],
  CategoryprofessionData:[],
  CategoryPrimaryProfSuccResp:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getIndustriesSucc(state, action) {
      state.isLoading = false;
      state.industriesData = action.payload;
    },
    getIndustriesSecondarySucc(state, action) {
      state.isLoading = false;
      state.industriesSecData = action.payload;
    },
    professionDataSuccess(state, action) {
      state.isLoading = false;
      state.professionSeclectedData = action.payload;
    },
    professionCategoryDataSucc(state, action) {
      state.isLoading = false;
      state.CategoryprofessionData = action.payload;
    },
    professionSetPrimarySucc(state, action) {
      state.isLoading = false;
      state.CategoryPrimaryProfSuccResp = action.payload;
    },
    CategoryUpdateSuccess(state, action) {
      state.isLoading = false;
      state.UpdateCategoryData = action.payload;
    }, 
    // GET Profession
    getProfessionsSucc(state, action) {
      state.isLoading = false;
      state.professionsData = action.payload;
    },
    // SAVE Profession
    saveProfessionSuccess(state, action) {
      state.isLoading = false;
      state.SaveProfessionData = action.payload;
    }, 
    // SAVE First Profession
    saveFirstProfessionSuccess(state, action) {
      state.isLoading = false;
      state.SaveFirstProfessionData = action.payload;
    }, 
    // GET Talent Profession
    getTalentProfessionsSucc(state, action) {
      state.isLoading = false;
      state.talentprofessionsData = action.payload;
    },
    // GET Only Talent Profession
    getOnlyProfessionsSucc(state, action) {
      state.isLoading = false;
      state.onlyprofessionsData = action.payload;
    },
    CategoryUpdateSecSuccess(state, action) {
      state.isLoading = false;
      state.UpdateCategorySecData = action.payload;
    },
    CategoryParamsSucc(state, action) {
      state.isLoading = false;
      state.ChangeCategorySecData = action.payload;
    },
    ClearCategoryParamsSucc(state, action) {
      state.isLoading = false;
      state.ChangeCategorySecData = action.payload;
    },
    PrimaryProfessionSuccess(state, action) {
      state.isLoading = false;
      state.PrimaryProfessionData = action.payload;
    },
    ClearPrimaryProfessionSucc(state, action) {
      state.isLoading = false;
      state.ClearPrimaryProfessionSuccess = action.payload;
    },
    ProfessionDeleteSuccesss(state, action) {
      state.isLoading = false;
      state.ProfessionDeleteData = action.payload;
    },
    ClearProfessionDeleteSucc(state, action) {
      state.isLoading = false;
      state.ProfessionDeleteSuccess = action.payload;
    },
    // CREATE EVENT
    ProfessionDepartmentSuccess(state, action) {
      state.isLoading = false;
      state.ProfessionDepartData = action.payload;
    },
    // CREATE EVENT
    GetProfessionRoleTypeSuccess(state, action) {
      state.isLoading = false;
      state.ProfessionRoleTypelist = action.payload;
    },
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getIndustriesData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessTokenTalent")
    try {
      const response = await axios.get('/get_industries_primary/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getIndustriesSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/dev_all_professions/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getProfessionsSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
// export function getProfessions(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/all_professions/');
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.getProfessionsSucc(response.data));
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }

export function getTalentProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_talent_professions/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getTalentProfessionsSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getOnlyProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_professions_list/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getOnlyProfessionsSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getIndustriesSecData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessTokenTalent")
    try {
      const response = await axios.get('/get_industries/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getIndustriesSecondarySucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
// export function professionData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     const profession = data;
//     try {
//       const response = await axios.get('/industry_profession_type/',{
//         params: { profession }
//       });
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.professionDataSuccess(response.data));
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }

export function professionData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const profession = data;
    try {
      const response = await axios.post('/dev_industry_profession_type/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.professionDataSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function professionCategoryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const profession = data;
    try {
      const response = await axios.post('/get_profession_category/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.professionCategoryDataSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function professionSetPrimary(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const profession = data;
    try {
      const response = await axios.post('/set_onscreen_profession/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.professionSetPrimarySucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_talent_industries_primary/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.CategoryUpdateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

// Backup
// export function saveProfession(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/save_profession/',data)
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.saveProfessionSuccess(response.data));
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }

export function saveProfession(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_industries/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.saveProfessionSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function saveFirstProfession(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dev_save_profession/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.saveFirstProfessionSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function updateSubCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const dataArray = {
      talent_industries_array:data
    }
    try {
      const response = await axios.post('/save_talent_industries/',dataArray)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.CategoryUpdateSecSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function sendParamsToCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryParamsSucc(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SetPrimaryProfession(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/set_primary_profession/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.PrimaryProfessionSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function deleteProfession(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_profession/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.ProfessionDeleteSuccesss(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getProfessionDepartment(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_departments/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.ProfessionDepartmentSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getProfessionRoleType(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_profession_role_type/',data)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.GetProfessionRoleTypeSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function clearFirstProfession() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveFirstProfessionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearDepartProfessionData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ProfessionDepartmentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSetPrimaryData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.professionSetPrimarySucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearProfessionRoleType() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.GetProfessionRoleTypeSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearDeleteProfessionData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ProfessionDeleteSuccesss([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearCategoryProfData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.professionCategoryDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearTalentProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTalentProfessionsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearOnlyProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getOnlyProfessionsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearParamsToCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ClearCategoryParamsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearPrimaryProfessionSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.PrimaryProfessionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearUpdateCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearUpdateSubCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryUpdateSecSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearSaveProfession(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveProfessionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearProfessionData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.professionDataSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}