import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  getagentData: [],
  SaveUserInformResp: [],
  isOpenModal: false, 
};

const slice = createSlice({
  name: 'Register',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
 
   
    getAgentDetailsSucc(state, action) {
      state.isLoading = false;
      state.getagentData = action.payload;
    },
 
 
   
    getCheckAgentCodeSucc(state, action) {
      state.isLoading = false;
      state.getcheckAgentCodeResp = action.payload;
    },
 
   
    SaveUserInformationSucc(state, action) {
      state.isLoading = false;
      state.SaveUserInformResp = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getAgentDeta(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/verify_agent/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getAgentDetailsSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function CheckAgentCode(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/verify_agent/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getCheckAgentCodeSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function SaveUserInformation(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/collect_user_information/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.SaveUserInformationSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleargetAgentDeta(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAgentDetailsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearSaveUserInform(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SaveUserInformationSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearCheckAgentCode(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCheckAgentCodeSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



