
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Box, Stack, AppBar, Toolbar, Badge } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useDispatch, useSelector } from "react-redux";
import { getCartCount, getBillingInfo } from '../../../redux/slices/subscription'
import { PATH_DASHBOARD } from '../../../routes/paths';





const CartCount = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getCartCount());
    }, []);

    const [countData, setCountData] = useState(0)

    const { cartCountSucc } = useSelector((state) => {
        return {
            cartCountSucc: state && state.subscription && state.subscription.cartCount && state.subscription.cartCount.response
        }
    });

    console.log(cartCountSucc, 'df0g4fgfdg04dg', countData, countData > 0);

    useEffect(() => {
        if (cartCountSucc && Object.keys(cartCountSucc).length > 0) {
            setCountData(cartCountSucc.count_of_cart)
        }
    }, [cartCountSucc])


    const handleCheckoutClick = () => {
        if (countData && countData > 0) {
            // navigate(PATH_DASHBOARD.setting);
            navigate(PATH_DASHBOARD.setting.billingInfo);
            // const payload = {
            //     "flag": 'cartIcon'
            // };
            dispatch(getBillingInfo());
        }
    };


    return (
        <Box sx={{ cursor: 'pointer', }} onClick={handleCheckoutClick}>
            <Badge badgeContent={countData} color="primary" anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} >
                <ShoppingCartOutlinedIcon color="action" />

            </Badge>
        </Box>
    )
}

export default CartCount