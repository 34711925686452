import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
//
import VideoBlockContent from './VideoBlockContent';
import UploadImage from '../../images/PortfolioUploadImage.png';

import VideoRejectionFiles from './VideoRejectionFiles';
import MultiFilePreviewVideo from './MultiFilePreviewVideo';

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: theme.palette.background.neutral,
    // border: `1px dashed ${theme.palette.grey[500_32]}`,
    // '&:hover': { opacity: 0.72, cursor: 'pointer' },
    border: '4px dashed #d3e1ea',
}));

const UploadPorfoliovideoMultiFileMobile = ({
    error,
    showPreview = false,
    files,
    saveVideo,
    onRemove,
    onRemoveAll,
    onOpenModel,
    helperText,
    sx,
    ...other
}) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
    });
    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    }),
                }}
            >
                <input {...getInputProps()} />

                {/* <VideoBlockContent /> */}
                <Box >
                    <Box component='img' src={UploadImage} alt='Upload Image' className='PortfolioUploadImg' />

                    <Box sx={{ p: 1.2 }}>
                        <Typography gutterBottom variant="body1" color={'#44577a'}>  Browse or Drag & Drop Video Files  </Typography>

                        <Typography variant="body2" sx={{ color: '#44577a', mt: 2 }} textAlign={'center'} >
                            Allowed formats *.webm, *.html5, *.3gp, *.mpeg2,*.mp4 videos,*.mov videos<br />
                            Max Video Size 100MB<br />
                        </Typography>
                    </Box>
                </Box>
            </DropZoneStyle>

            {fileRejections.length > 0 && <VideoRejectionFiles fileRejections={fileRejections} />}

            {/* <MultiFilePreviewVideo  onOpenModel={onOpenModel} files={files} showPreview={showPreview} onRemove={onRemove} onRemoveAll={onRemoveAll}  saveVideo={saveVideo}/> */}

            {helperText && helperText}
        </Box>
    )
}
UploadPorfoliovideoMultiFileMobile.propTypes = {
    error: PropTypes.bool,
    showPreview: PropTypes.bool,
    files: PropTypes.array,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    helperText: PropTypes.node,
    sx: PropTypes.object,
};
export default UploadPorfoliovideoMultiFileMobile




// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
