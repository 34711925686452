
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import AudioBlockContent from './AudioBlockContent';
import AudioRejectionFiles from './AudioRejectionFiles';
import MultiFilePreviewAudio from './MultiFilePreviewAudio';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

const UploadPortfolioAudioMultiFile = ({
    error,
    showPreview = false,
    files,
    onRemove,
    onRemoveAll,
    saveAudio,
    onOpenModel,
    helperText, 
    sx,
    ...other
  }) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
      });
    return (
        <Box sx={{ width: '100%', ...sx }}>
          <DropZoneStyle
            {...getRootProps()}
            sx={{
              ...(isDragActive && { opacity: 0.72 }),
              ...((isDragReject || error) && {
                color: 'error.main',
                borderColor: 'error.light',
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <input {...getInputProps()} />
    
            <AudioBlockContent />
          </DropZoneStyle>
    
          {fileRejections.length > 0 && <AudioRejectionFiles fileRejections={fileRejections} />}
    
          {/* <MultiFilePreviewAudio  onOpenModel={onOpenModel} files={files} showPreview={showPreview} onRemove={onRemove} onRemoveAll={onRemoveAll}  saveAudio={saveAudio}/> */}
    
          {helperText && helperText}
        </Box>
      );
}

export default UploadPortfolioAudioMultiFile

