import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess:[],
  cities:[],
  verifySlugResp:[],
  verifycheckSlugResp:[],
  errorVerifySlug:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasErrorSave(state, action) {
      state.isLoading = false;
      state.errorVerifySlug = action.payload;
    },
    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
    
    verifySlugSuccess(state, action) {
      state.isLoading = false;
      state.verifySlugResp = action.payload;
    },
    
    verifycheckSlugSuccess(state, action) {
      state.isLoading = false;
      state.verifycheckSlugResp = action.payload;
    },
    userAgentUpdateSuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------
 
export function getTicketId() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_tk_id/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getEventsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function verifySlug(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/verify_slug/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.verifySlugSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasErrorSave(error));
          dispatch(slice.actions.hasError(error))
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

export function verifyCheckSlug(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/verify_slug/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.verifycheckSlugSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasErrorSave(error));
          dispatch(slice.actions.hasError(error))
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function clearVerifySlugSuccess() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getEventsSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearVerifyCheckSlugSuccess() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.verifycheckSlugSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearVerifySuccess() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.verifySlugSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  

  export function clearVerifyError() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasErrorSave([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }