import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  uploadPortResponse: [],
  events: [],
  deletemultiPhotos: [],
  UpdatePhotoRes:[],
  ParameterForRoute:[],
  statess:[],
  cities:[],
  deleteResponse:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    deleteSuccess(state, action) {
      state.isLoading = false;
      state.deleteResponse = action.payload;
    },
    // GET EVENTS
    uploadPortfolioSuccess(state, action) {
      state.isLoading = false;
      state.uploadPortResponse = action.payload;
    },
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    deletePortfolioPhoto(state, action) {
      state.isLoading = false;
      state.deletemultiPhotos = action.payload;
    },
    
    userAgentUpdateSuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    UploadParamsSucc(state, action) {
      state.isLoading = false;
      state.ParameterForRoute = action.payload;
    },
    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    updatePhotoSuccess(state, action) {
      // const newEvent = action.payload;
      state.isLoading = false;
      state.UpdatePhotoRes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------
export function getPortfolio(data) {
  const tokenVal = window.localStorage.getItem("accessToken")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_portfolio/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getEventsSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function deleteportfolioImg(data) {
  const tokenVal = window.localStorage.getItem("accessToken")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_multiple_portfolio/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.deletePortfolioPhoto(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
 
export function uploadPortfolio(data) {
  const tokenVal = window.localStorage.getItem("accessToken")
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/upload_portfolio/',data,{headers: {"Content-type": "multipart/form-data"}})
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.uploadPortfolioSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function ClearUploadPortfolio(data) {
    const tokenVal = window.localStorage.getItem("accessToken")
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
           dispatch(slice.actions.uploadPortfolioSuccess([]));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };
    }

  export function ClearPortfolio(data) {
    const tokenVal = window.localStorage.getItem("accessToken")
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
           dispatch(slice.actions.Stack([]));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };
    }

export function updatePhotoData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/update_talent_portfolio/',data,{headers: {"Content-type": "multipart/form-data"}})
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.updatePhotoSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
       } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }
  
  export function sendParamsToUpload(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.UploadParamsSucc(data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearParamsToUpload(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.UploadParamsSucc([])); 
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearUpload(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.uploadPortfolioSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  export function clearDeleteResp(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.deletePortfolioPhoto([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearPortfoliImgResp(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getEventsSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function deletePortfolio(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_talent_portfolio/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.deleteSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }