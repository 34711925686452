// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_DASHBOARD = '';
const PROFILE_ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/invite'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resetConfirmPassword: path(ROOTS_AUTH, '/reset-confirm-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  userDone: path(ROOTS_AUTH, '/user-done'),
};

export const PATH_PROFILE = {
  locationsDetails: ROOTS_DASHBOARD,
  ProfilesDetails: '/profile-details',
  resumePreview: '/resumePreview',
};


export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/dashboard'),
  // setting: path(ROOTS_DASHBOARD, '/setting'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  activityLog: path(ROOTS_DASHBOARD, '/activity-log'),
  myProject: path(ROOTS_DASHBOARD, '/my-project'),
  myProjectList: path(ROOTS_DASHBOARD, '/my-project/List'),
  myProjectView: path(ROOTS_DASHBOARD, '/my-project/view'),
  templates: {
    root: path(ROOTS_DASHBOARD, '/theme'),
    templates: path(ROOTS_DASHBOARD, '/theme/templates'),
    resumes: path(ROOTS_DASHBOARD, '/theme/resumes'),
    resumeCreate: path(ROOTS_DASHBOARD, '/theme/resumeCreate'),
    // resumePreview: path(ROOTS_DASHBOARD, '/theme/resumePreview'),
    resumeTemplates: path(ROOTS_DASHBOARD, '/theme/resumeTemplates'),
    resumeTemplateData: path(ROOTS_DASHBOARD, '/theme/resumeTemplateData'),
    coverLetter: path(ROOTS_DASHBOARD, '/theme/coverLetter'),
    CreateCoverLetter: path(ROOTS_DASHBOARD, '/theme/CreateCoverLetter'),
    CoverLetterView: path(ROOTS_DASHBOARD, '/theme/CoverLetterView'),
    resumeTemplateRecreate: path(ROOTS_DASHBOARD, '/theme/resumeTemplateRecreate'),
    themeCreate: path(ROOTS_DASHBOARD, '/theme/themeCreate'),

    themeCreation: path(ROOTS_DASHBOARD, '/theme/themeCreation'),
    themeView: path(ROOTS_DASHBOARD, '/theme/themeView'),
  },
  themeTemplates: {
    root: path(ROOTS_DASHBOARD, '/profile-theme'),
    themeView: path(ROOTS_DASHBOARD, '/profile-theme/themeView'),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/setting'),
    profile: path(ROOTS_DASHBOARD, '/setting/profileVisibility'),
    account: path(ROOTS_DASHBOARD, '/setting/account'),
    notification: path(ROOTS_DASHBOARD, '/setting/notification'),
    manageAccount: path(ROOTS_DASHBOARD, '/setting/manageAccount'),
    userAgent: path(ROOTS_DASHBOARD, '/setting/userAgent'),
    subscriptionPlans: path(ROOTS_DASHBOARD, '/setting/subscription'),
    // subscriptionPlans: path(ROOTS_DASHBOARD, '/setting/subscription/plans'),
    // subscriptionTable: path(ROOTS_DASHBOARD, '/setting/subscription/subscriptionTable'),
    billingInfo: path(ROOTS_DASHBOARD, '/setting/subscription/billingInfo'),
    invoiceDetails: path(ROOTS_DASHBOARD, '/setting/subscription/invoiceDetails'),
    payment: path(ROOTS_DASHBOARD, '/setting/payment'),
  },

  inBox: {
    root: path(ROOTS_DASHBOARD, '/inbox'),
    inbox: path(ROOTS_DASHBOARD, '/inbox'),
    notification: path(ROOTS_DASHBOARD, '/inbox/notification'),
    enquiry: path(ROOTS_DASHBOARD, '/inbox/enquiry'),
  },

  // subscription: path(ROOTS_DASHBOARD, '/subscription/plans'),
  // notification: path(ROOTS_DASHBOARD, '/notification'),
  networking: path(ROOTS_DASHBOARD, '/networking'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/profile'),
    general: {
      profile: path(ROOTS_DASHBOARD, '/profile/personal-info'),
    },
    category: path(ROOTS_DASHBOARD, '/profile/category'),
    subCategory: path(ROOTS_DASHBOARD, '/profile/category/sub-category'),
    talentType: path(ROOTS_DASHBOARD, '/profile/category/sub-category-type'),
    langAndInd: path(ROOTS_DASHBOARD, '/profile/language-industry'),
    profession: path(ROOTS_DASHBOARD, '/profile/profession'),
    professions: path(ROOTS_DASHBOARD, '/profile/professions'),
    professionCategory: path(ROOTS_DASHBOARD, '/profile/profession-category'),
    professionIndustry: path(ROOTS_DASHBOARD, '/profile/profession-industry'),
    professionData: path(ROOTS_DASHBOARD, '/profile/profession-data'),
    EduAndTraining: path(ROOTS_DASHBOARD, '/profile/education-training'),
    physical: path(ROOTS_DASHBOARD, '/profile/physical'),
    skillset: path(ROOTS_DASHBOARD, '/profile/skillset'),
    portfolio: {
      root: path(ROOTS_DASHBOARD, '/profile/portfolio'),
      imgaeUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/imageUpload'),
      photos: path(ROOTS_DASHBOARD, '/profile/portfolio/photos'),
      photoUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/photoUpload'),
      album: path(ROOTS_DASHBOARD, '/profile/portfolio/photo-album'),
      albumphotoorganization: path(ROOTS_DASHBOARD, '/profile/portfolio/photo-album-organizer'),

      imageAlbumUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/imageAlbumUpload'),
      albumphotoadd: path(ROOTS_DASHBOARD, '/profile/portfolio/photo-album-add'),
      albumphotoedit: path(ROOTS_DASHBOARD, '/profile/portfolio/photo-album-edit'),

      // Audio
      audioUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/audioUpload'),
      audioGallery: path(ROOTS_DASHBOARD, '/profile/portfolio/audios'),
      audioAlbum: path(ROOTS_DASHBOARD, '/profile/portfolio/audio-album'),
      audioAlbumOrganization: path(ROOTS_DASHBOARD, '/profile/portfolio/audio-album-organizer'),

      audioalbumUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/audioalbumUpload'),
      audioAlbumadd: path(ROOTS_DASHBOARD, '/profile/portfolio/audio-album-add'),
      audioAlbumedit: path(ROOTS_DASHBOARD, '/profile/portfolio/audio-album-edit'),

      // Video
      videos: path(ROOTS_DASHBOARD, '/profile/portfolio/videos'),
      videoUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/videoUpload'),
      videoalbumUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/videoUploadalbum'),
      videoAlbum: path(ROOTS_DASHBOARD, '/profile/portfolio/video-album'),
      videoAlbumOrganization: path(ROOTS_DASHBOARD, '/profile/portfolio/video-album-organizer'),

      videoAlbumadd: path(ROOTS_DASHBOARD, '/profile/portfolio/video-album-add'),
      videoAlbumedit: path(ROOTS_DASHBOARD, '/profile/portfolio/video-album-edit'),

      // Awards
      awards: path(ROOTS_DASHBOARD, '/profile/portfolio/awards'),
      awardsUpload: path(ROOTS_DASHBOARD, '/profile/portfolio/award-add'),
      awardsAlbumOrganization: path(ROOTS_DASHBOARD, '/user/portfolio/awardAlbumOrganization')
    },
    work: path(ROOTS_DASHBOARD, '/profile/work'),
    profileIntro: path(ROOTS_DASHBOARD, '/profile/profile-intro'),
    professionAgent: path(ROOTS_DASHBOARD, '/profile/profession-agent'),
    profAgentList: path(ROOTS_DASHBOARD, '/profile/profession-agents'),
    professionAgentInvitation: path(ROOTS_DASHBOARD, '/profile/profession-agent-invitation'),
    professionAgentInviReqDetails: path(ROOTS_DASHBOARD, '/profile/profession-agent-invitation-details'),
    AgentInviReqDetails: path(ROOTS_DASHBOARD, '/profile/agent-invitation-details'),
    AgentDetails: path(ROOTS_DASHBOARD, '/profile/agent-details'),
    AgentEdit: path(ROOTS_DASHBOARD, '/profile/agent-details-edit'),
    AgentExits: path(ROOTS_DASHBOARD, '/profile/profession-agent-detail'),
    agent: {
      root: path(ROOTS_DASHBOARD, '/profile/main/agent-info'),
      userDone: path(ROOTS_DASHBOARD, '/profile/main/user-done')
    },
    themeChoose: path(ROOTS_DASHBOARD, '/profile/themeChoose'),
  },

  jobs: {
    root: path(ROOTS_DASHBOARD, '/jobs'),
    job_search: path(ROOTS_DASHBOARD, '/jobs/job-search'),
    job_seek: path(ROOTS_DASHBOARD, '/jobs/job-seek'),
    job_seek_enable: path(ROOTS_DASHBOARD, '/jobs/job-seek-enable'),
    jobApplication: path(ROOTS_DASHBOARD, '/jobs/jobApplication'),
    recommendations: path(ROOTS_DASHBOARD, '/jobs/recommendations'),
    pause_job_search: path(ROOTS_DASHBOARD, '/jobs/pause-job-search'),
    quick: path(ROOTS_DASHBOARD, '/jobs/job-search'),
    advanced: path(ROOTS_DASHBOARD, '/jobs/advanced'),
    saved: path(ROOTS_DASHBOARD, '/jobs/saved'),
    details: path(ROOTS_DASHBOARD, '/jobs/details'),
    quick_list: path(ROOTS_DASHBOARD, '/jobs/quick-search'),
    // extra tab pages
    JobReviewed: path(ROOTS_DASHBOARD, '/jobs/Reviewed'),
    JobContacting: path(ROOTS_DASHBOARD, '/jobs/Contacting'),
    JobRejected: path(ROOTS_DASHBOARD, '/jobs/Rejected'),
    JobHired: path(ROOTS_DASHBOARD, '/jobs/Hired'),
    JobInterested: path(ROOTS_DASHBOARD, '/jobs/Interested'),
    JobHold: path(ROOTS_DASHBOARD, '/jobs/Hold'),
    JobInterview: path(ROOTS_DASHBOARD, '/jobs/Interview'),
    JobExpired: path(ROOTS_DASHBOARD, '/jobs/Expired'),
    UserAppliedJob: path(ROOTS_DASHBOARD, '/jobs/jobApplied-dashboard')
  },
  // portfolio: {
  //   root: path(ROOTS_DASHBOARD, '/portfolio'),
  //   gallery: path(ROOTS_DASHBOARD,'/portfolio/gallery'),
  //   photos: path(ROOTS_DASHBOARD,'/portfolio/photos'),    
  //   photoUpload: path(ROOTS_DASHBOARD,'/portfolio/photoUpload'), 
  //   album: path(ROOTS_DASHBOARD,'/portfolio/album'), 
  //   audioAlbum: path(ROOTS_DASHBOARD,'/portfolio/audioAlbum'), 
  //   portfolio: path(ROOTS_DASHBOARD,'/portfolio/portfolio'), 
  //   audioUpload: path(ROOTS_DASHBOARD, '/portfolio/audioUpload'),
  //   audioGallery: path(ROOTS_DASHBOARD, '/portfolio/audioGallery'),
  //   portfolioImageGallery : path(ROOTS_DASHBOARD, '/portfolio/portfolioImageGallery'),
  //   awards: path(ROOTS_DASHBOARD, '/portfolio/awards'),
  //   portfolioAwards: path(ROOTS_DASHBOARD,'/portfolio/portfolioAwards'),
  //   albumphotoorganization: path(ROOTS_DASHBOARD,'/portfolio/albumphotoorganization'),
  //   videoUpload: path(ROOTS_DASHBOARD,'/portfolio/videoUpload'),
  //   videoGallery: path(ROOTS_DASHBOARD,'/portfolio/videoGallery'),
  //   videos: path(ROOTS_DASHBOARD,'/portfolio/videos'),
  //   videoAlbum: path(ROOTS_DASHBOARD,'/portfolio/videosAlbum'),
  //   videoAlbumOrganization: path(ROOTS_DASHBOARD,'/portfolio/videosAlbumOrganization'),
  //   audioAlbumOrganization: path(ROOTS_DASHBOARD,'/portfolio/audioAlbumOrganization'),
  //   awardsUpload: path(ROOTS_DASHBOARD,'/portfolio/awardsUpload'),
  //   awardsAlbum: path(ROOTS_DASHBOARD,'/portfolio/awardAlbum'),
  //   awardsAlbumOrganization: path(ROOTS_DASHBOARD,'/portfolio/awardAlbumOrganization')
  // },
  connectnetwork: {
    root: path(ROOTS_DASHBOARD, '/network'),
    network: path(ROOTS_DASHBOARD, '/network/network'),
    // followers: path(ROOTS_DASHBOARD, '/network/followers'),
    // following: path(ROOTS_DASHBOARD, '/network/following'),
    followers: {
      root: path(ROOTS_DASHBOARD, "/network/followers"),
      talents: path(ROOTS_DASHBOARD, "/network/followers/talents"),
      companies: path(ROOTS_DASHBOARD, "/network/followers/companies"),
      institution: path(ROOTS_DASHBOARD, "/network/followers/institution"),
    },
    following: {
      root: path(ROOTS_DASHBOARD, "/network/following"),
      talents: path(ROOTS_DASHBOARD, "/network/following/talents"),
      companies: path(ROOTS_DASHBOARD, "/network/following/companies"),
      institution: path(ROOTS_DASHBOARD, "/network/following/institution"),
    },
  },
  connect: {
    root: path(ROOTS_DASHBOARD, '/network/connect'),
    new: path(ROOTS_DASHBOARD, '/network/connect/new'),
    conversation: path(ROOTS_DASHBOARD, '/network/connect/:conversationKey')
  },

};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
