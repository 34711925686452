import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
//
import Image from '../Image';
import ProfileImage from '../ProfileImage';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import AwardsBlock from './AwardsBlock';
import Iconify from '../Iconify';
import Logo from '../../images/avatar.jpg';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadProfileFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  // position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: "100%",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&::before": {

  //   width:40,
  //   height:40,
  // },
  '&:hover': { opacity: 0.72, },
}));


export default function UploadProfileFile({ error = false, file, helperText, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });
  const fileCon = file ? null : `url(${Logo})`;
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle className='image-container-main imgupload-container'
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',

          }),
          ...(file && {
            padding: '0',
          }),
        }}
      >
        <input {...getInputProps()} />

        <AwardsBlock />

        {file && (
          <Box style={{ position: "relative", height: '100%' }}>
            <ProfileImage className="BrowseImage"
              alt="file preview"
              src={isString(file) ? file : file.preview}
              sx={{
                top: 8,
                borderRadius: 1,
                padding: 1
              }}
            />
            <Box className="imageUploaderProfilePageBox">
              <span className="imageUploadBtnProfilepage">
                <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 30, height: 30}} />
              </span>
            </Box>
          </Box>
        )}

        {/* <PlaceholderStyle
            className="placeholder"
            sx={{
              backgroundRepeat: "no-repeat",
              // backgroundImage:`url(${Logo})`,
              // backgroundImage : fileCon,
              // ...(file && {
              //   opacity: 0,
              //   color: 'common.white',
              //   bgcolor: 'grey.900',
              //   '&:hover': { opacity: 0.72 },
              // }),
              // ...((isDragReject || error) && {
              //   bgcolor: 'error.lighter',
              // }),
            }}
          >
            <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 500, height: 500, mb: 1 }} />
            <Typography variant="caption">{file ? 'Change' : 'Upload photo'}</Typography>
          </PlaceholderStyle> */}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
