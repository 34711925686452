import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, IconButton } from '@mui/material';
//
import Image from '../Image';
import ProfileImageMobile from '../ProfileImageMobile';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import AwardsBlock from './AwardsBlock';
import Iconify from '../Iconify';
import { Icon } from '@iconify/react';
import Logo from '../../images/avatar.jpg';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

const DropZoneStyleMobile = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  // padding: theme.spacing(5, 1),
  // borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  // backgroundColor: theme.palette.background.neutral,
  // border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadProfileFileMobile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  // height: "100%",
  // width:"100%",
  color: theme.palette.text.secondary,
  backgroundColor: '#8f8f8f',
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&::before": {

  //   width:40,
  //   height:40,
  // },
  '&:hover': { opacity: 0.72 },
}));


export default function UploadProfileFileMobile({ error = false, file, helperText, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });
  const fileCon = file ? null : `url(${Logo})`;

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    })
  }, [width])

  // const leftpadding = () => {
  //   if (width > 410 && width < 430) {
  //     return '20%'
  //   } else if(width > 380 && width < 410){
  //     return '19%'
  //   } else {
  //     return '17%'
  //   }
  // }


  const leftpadding = (width) => {
    if (width >= 400 && width < 415) {
      return '21%';
    } else if (width >= 380 && width < 400) {
      return '19%';
    } else if (width > 365 && width < 380) {
      return '18%';
    } else if (width >= 340 && width < 365) {
      return '17%';
    } else if (width >= 415 && width < 440) {
      return '23%';
    } else if (width >= 440 && width < 460) {
      return '24%';
    } else if (width >= 460 && width < 490) {
      return '26%';
    } else {
      return '28%';
    }
  };


  return (
    <Box sx={{ width: '100%', ...sx }}>
      {file &&
        <DropZoneStyle className='image-container-main imgupload-container'
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',

            }),
            ...(file && {
              padding: '0',
            }),
          }}
        >
          <input {...getInputProps()} />

          {/* <AwardsBlock /> */}

          {file && (
            <>
              <Box className='ProfileImgMobile' style={{ position: "relative", height: '100%' }} >
                <ProfileImageMobile
                  // className="BrowseImage"
                  alt="file preview"
                  src={isString(file) ? file : file.preview}
                  sx={{
                    top: 8,
                    // borderRadius: 1,
                    // padding: 1,
                    height: '250px', borderRadius: '10px', width: '200px', marginLeft: 'auto', marginRight: 'auto'
                  }}
                />
                <Box className="imageUploaderProfilePageBoxMobile">
                  <span className="imageUploadBtnProfilepagemobile">
                    {/* <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 30, height: 30 }} /> */}
                    <Iconify icon={'mdi:camera-plus'} sx={{ width: 30, height: 30 }} />
                  </span>
                </Box>

              </Box>

              {/* ----------------------------------------------------------------------below onee working--------------------- */}
              {/* <Box sx={{ position: "absolute", height: '250px', borderRadius: '10px', width: '200px', top: '0%', left: leftpadding(width), background: 'rgba(0, 0, 0, 0.2)' }} variant="caption" ><Typography position={'absolute'} sx={{ right: '34%', color: 'white', top: '39%', fontSize: '19px', }}  >{file ? 'Change' : 'Upload photo'} </Typography></Box> */}
              {/* <IconButton>
                <Icon icon="mdi:camera-plus" color="black" width="24" height="24" />
              </IconButton> */}
            </>
          )}
        </DropZoneStyle>
      }
      {
        !file &&
        <DropZoneStyleMobile
          // className='image-container-main imgupload-container'
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              // bgcolor: 'error.lighter',

            }),
            ...(file && {
              padding: '0',
            }),
          }}
        >
          <input {...getInputProps()} />

          {/* <AwardsBlock /> */}

          {!file && (
            <>
              <Box style={{ position: "relative", height: '100%' }} >
                <ProfileImageMobile

                  alt="file preview"
                  sx={{
                    top: 8,
                    height: '250px', borderRadius: '10px', width: '200px', marginLeft: '4rem'
                  }}
                />
              </Box>
              <Box sx={{ position: "absolute", height: '250px', borderRadius: '10px', width: '200px', top: '0%', left: '19% ', background: 'rgba(0, 0, 0, 0.6)' }} variant="caption" ><Typography position={'absolute'} sx={{ right: '20%', color: 'white', top: '44%', fontSize: '19px', }} >{file ? 'Change' : 'Upload photo'} </Typography></Box>
            </>
          )}
        </DropZoneStyleMobile>
      }
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box >
  );
}
