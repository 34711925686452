import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  educationData: [],
  EducationPostData:[],
  EducationDeleteData:[],
  EducationupData:[],
  responseLogged:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    responseLogin(state, action) {
      state.isLoading = false;
      state.responseLogged = action.payload;
    },
    postEducationSucc(state, action) {
        state.isLoading = false;
        state.EducationPostData = action.payload;
      },
      upEducationSucc(state, action) {
        state.isLoading = false;
        state.EducationupData = action.payload;
      },

      DeleteEduSucc(state, action) {
        state.isLoading = false;
        state.EducationDeleteData = action.payload;
      },
   
   // CREATE EVENT
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getResponseOnLogin(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.responseLogin(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

