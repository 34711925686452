import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  notificationData: [],
  notificationJobData: [],
  SingleJobNotification: [],
  enquiryData: [],
  inboxData: [],
  inboxNotiData: [],
  deleteEnquiryData: [],
  EnquiryLikeData: [],
  SendReplyData: [],
  deleteNotificationResData: [],
  getJobsGetData: [],
  deleteJobMsgResp: [],
  deleteJobNotificationResp: [],
  seenJobNotificationResp: [],
  likeJobNotificationResp: [],
  notificationReplyJob: '',
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS   
    getReplyJobNotification(state, action) {
      state.isLoading = false;
      state.notificationReplyJob = action.payload;
    },
    getdeleteJobMsg(state, action) {
      state.isLoading = false;
      state.deleteJobMsgResp = action.payload;
    },
    getNotificationSucc(state, action) {
      state.isLoading = false;
      state.notificationData = action.payload;
    },
    getSingleJobNotification(state, action) {
      state.isLoading = false;
      state.SingleJobNotification = action.payload;
    },
    getJobNotificationSucc(state, action) {
      state.isLoading = false;
      state.notificationJobData = action.payload;
    },
    getEnquirySucc(state, action) {
      state.isLoading = false;
      state.enquiryData = action.payload;
    },
    getInboxSuccess(state, action) {
      state.isLoading = false;
      state.inboxData = action.payload;
    },
    getNotiInboxSuccess(state, action) {
      state.isLoading = false;
      state.inboxNotiData = action.payload;
    },
    DeleteEnquirySucc(state, action) {
      state.isLoading = false;
      state.deleteEnquiryData = action.payload;
    },
    DeleteNotification(state, action) {
      state.isLoading = false;
      state.deleteNotificationResData = action.payload;
    },
    EnquiryLikeSucc(state, action) {
      state.isLoading = false;
      state.EnquiryLikeData = action.payload;
    },
    SendReplyEnquiry(state, action) {
      state.isLoading = false;
      state.SendReplyData = action.payload;
    },
    getJobsValues(state, action) {
      state.isLoading = false;
      state.getJobsGetData = action.payload;
    },
    DeleteJobNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.deleteJobNotificationResp = action.payload;
    },
    SeenJobNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.seenJobNotificationResp = action.payload;
    },
    LikeJobNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.likeJobNotificationResp = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getJobsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobsValues(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getJobNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_notification/', data);
      // const response = await axios.get('/get_job_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobNotificationSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getSingleJobNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_single_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSingleJobNotification(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function replyJobNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/reply_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getReplyJobNotification(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function DeleteJobMsg(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getdeleteJobMsg(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearJobNotification() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getJobNotificationSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearReplySuccess() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getReplyJobNotification(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearDeleteJobMsg() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getdeleteJobMsg(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_job_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getNotificationSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_enquiry_notification/', data);
      // const response = await axios.get('/get_enquiry_notification/');
      // const response = await axios.get('/get_enquiry/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEnquirySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getNotificationInboxData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getInboxSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getInboxNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getNotiInboxSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function ClearNotiInbox(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getNotiInboxSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DoEnquiryLike(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/like_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.EnquiryLikeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function ClearDoEnquiryLike(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.EnquiryLikeSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DeleteEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteEnquirySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function clearDeleteEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteEnquirySucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DeleteNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteNotification(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function clearDeleteNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteNotification([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SendReplyToPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/reply_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SendReplyEnquiry(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function ClearSendReplyToPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SendReplyEnquiry([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearInboxHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearEnquirySucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEnquirySucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DeleteJobNotifications(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_inbox_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteJobNotificationsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function ClearDeleteJobNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteJobNotificationsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SeenJobNotifications(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_seen_status/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SeenJobNotificationsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function ClearSeenJobNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SeenJobNotificationsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function LikeJobNotifications(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/like_inbox_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.LikeJobNotificationsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function ClearLikeJobNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.LikeJobNotificationsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}