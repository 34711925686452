import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';




const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess: [],
  cities: [],
  profileUpdate: [],
  ImageIndex: [],
  profileUpdateSuccess: [],
  profileEditSuccess: [],
  deleteProfileResp: [],
  DashboardNotificationSucc: [],
  ViewAllRecentNotiRespSucc: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};


const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {

      state.isLoading = false;
      state.events = action.payload;

    },

    DashNotification(state, action) {
      state.isLoading = false;
      state.DashboardNotificationSucc = action.payload;
    },

    ViewAllRecentNotiSucc(state, action) {
      state.isLoading = false;
      state.ViewAllRecentNotiRespSucc = action.payload;
    },

  },
});


// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;


export function getRecentNotification(data) {
  return async () => {
    //   dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_talent_recentnotification/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DashNotification(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ViewAllRecentNotification(data) {
  return async () => {
    //   dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/seen_all_notification/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ViewAllRecentNotiSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function ClearViewAllRecentNoti() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ViewAllRecentNotiSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}