import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Link } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH, PATH_PROFILE } from '../../../routes/paths';
import { getProfileData, clearGetProfileData, talentLogout } from '../../../redux/slices/createProfile';
import { ClearPublishSaveSelected } from '../../../redux/slices/theme';
import { clearProjectList } from '../../../redux/slices/Projects';
import { useDispatch, useSelector } from '../../../redux/store';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user, logout } = useAuth();
  const [openProfile, setOpenProfile] = useState('No');
  const { currentUser, languageTypeData, educationTypeData, getProfessionsData, portfolio, profileMainData,
    currentProfile, UpdateProdileImg, themeProfiledata, calendardata, quickMainData,
    AdvancedMainData, SavedMainData, Resumedata, themeCoverdata, appliedJobRes, dashboardResponse,
    getAccount, getNotification, ProfileVisibility, UpdateSlug, updatePauseJobSearch, talentthemeProfile } = useSelector((state) => {
      // console.log("ffjffdg",state)
      return {
        currentProfile: state && state.profile && state.profile.events && state.profile.events.response,
        // language tab
        languageTypeData: state && state.langIndustry && state.langIndustry.languageData && state.langIndustry.languageData.response,
        // education tab
        educationTypeData: state && state.educationAndTrainig && state.educationAndTrainig.educationData && state.educationAndTrainig.educationData,
        // profession tab
        getProfessionsData: state && state.industries && state.industries.talentprofessionsData
          && state.industries.talentprofessionsData.responseData,
        // portfolio
        portfolio: state && state.portfolio && state.portfolio.events && state.portfolio.events.response,
        // Profile Intro
        profileMainData: state && state.intro && state.intro.profileIntroData && state.intro.profileIntroData.response,
        currentUser: state && state.profile && state.profile.profileImagelog,
        UpdateProdileImg: state && state.profile && state.profile.updateProfilePicRes && state.profile.updateProfilePicRes.response,
        //theme tab
        themeProfiledata: state && state.themeProfile && state.themeProfile.themeGetData && state.themeProfile.themeGetData.response,
        Resumedata: state && state.themeProfile && state.themeProfile.resumeGetData,
        themeCoverdata: state && state.themeProfile && state.themeProfile.coverGetData && state.themeProfile.coverGetData.response,
        talentthemeProfile: state && state.themeProfile && state.themeProfile.ThemeDataResp && state.themeProfile.ThemeDataResp.response,
        //calendar tab
        calendardata: state && state.calendar && state.calendar.events,
        //quick search
        quickMainData: state && state.quickSearch && state.quickSearch.getSuccessRes && state.quickSearch.getSuccessRes.response,
        //Advance search
        AdvancedMainData:
          state && state.advanceSearchdata && state.advanceSearchdata.getSearchSuccessRes && state.advanceSearchdata.getSearchSuccessRes.response,
        //saved tab
        SavedMainData: state && state.savedSearch && state.savedSearch.saveSearchData,
        //job application
        appliedJobRes: state && state.JobsApplied && state.JobsApplied.jobAppliedData && state.JobsApplied.jobAppliedData.response,
        //dashboard tab
        dashboardResponse: state && state.profile && state.profile.dashboardIntro && state.profile.dashboardIntro.response,
        //setting
        getAccount: state && state.settings && state.settings.account && state.settings.account.data &&
          state.settings.account.data.response,
        getNotification: state && state.settings && state.settings.notification && state.settings.notification.data,
        ProfileVisibility: state && state.settings && state.settings.profileVisibility && state.settings.profileVisibility.data,
        UpdateSlug: state && state.doneUser && state.doneUser.events && state.doneUser.events.response,
        updatePauseJobSearch: state && state.settings && state.settings.deActivatePauseJob,

      };
    });

  // console.log(updatePauseJobSearch,'themeProfiledata13')

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const [toPhoto, setToPhoto] = useState(PATH_PROFILE.locationsDetails);
  const [updateProfile, setUpdateProfile] = useState([])
  const userSlug = localStorage.getItem('userSlug');
  const userSlugImg = localStorage.getItem('userPhotourl');
  const [disName, setDisName] = useState("");

  useEffect(() => {
    if (userSlug && userSlug !== "" || userSlug !== undefined) {
      setDisName(userSlug)
    }
  }, [userSlug])

  useEffect(() => {
    if (currentProfile && currentProfile.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [currentProfile])

  useEffect(() => {
    if (languageTypeData && languageTypeData !== undefined && languageTypeData.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [languageTypeData])

  useEffect(() => {
    if (educationTypeData && educationTypeData !== undefined && educationTypeData.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [educationTypeData])

  useEffect(() => {
    if (getProfessionsData && getProfessionsData !== undefined && getProfessionsData.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [getProfessionsData])

  useEffect(() => {
    if (portfolio && portfolio !== undefined && portfolio.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [portfolio])

  useEffect(() => {
    if (profileMainData && profileMainData !== undefined && profileMainData.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [profileMainData])

  useEffect(() => {
    if (themeProfiledata && themeProfiledata !== undefined && themeProfiledata.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [themeProfiledata])
  useEffect(() => {
    if (talentthemeProfile && talentthemeProfile !== undefined && talentthemeProfile.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [talentthemeProfile])

  useEffect(() => {
    if (calendardata && calendardata !== undefined && calendardata.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [calendardata])

  useEffect(() => {
    if (quickMainData && quickMainData !== undefined && quickMainData.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [quickMainData])

  useEffect(() => {
    if (AdvancedMainData && AdvancedMainData !== undefined && AdvancedMainData.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [AdvancedMainData])

  useEffect(() => {
    if (SavedMainData && SavedMainData !== undefined && SavedMainData.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [SavedMainData])

  useEffect(() => {
    if (Resumedata && Resumedata !== undefined && Resumedata.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [Resumedata])

  useEffect(() => {
    if (themeCoverdata && themeCoverdata !== undefined && themeCoverdata.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [themeCoverdata])

  useEffect(() => {
    if (appliedJobRes && appliedJobRes !== undefined && appliedJobRes.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [appliedJobRes])

  useEffect(() => {
    if (dashboardResponse && dashboardResponse !== undefined && dashboardResponse.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [dashboardResponse])

  useEffect(() => {
    if (getAccount && getAccount !== undefined && getAccount.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [getAccount])

  useEffect(() => {
    if (getNotification && getNotification !== undefined && getNotification.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [getNotification])

  useEffect(() => {
    if (ProfileVisibility && ProfileVisibility !== undefined && ProfileVisibility.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [ProfileVisibility])

  useEffect(() => {
    if (UpdateSlug && UpdateSlug !== undefined && UpdateSlug.verify_slug === 1) {
      setOpenProfile('Yes');
    }
  }, [UpdateSlug])

  useEffect(() => {
    if (UpdateProdileImg && UpdateProdileImg !== undefined && UpdateProdileImg.avatar_url) {
      setUpdateProfile(UpdateProdileImg.avatar_url)
    }
  }, [UpdateProdileImg])

  // useEffect(() => {
  //   if(userSlug && userSlug!=="" && userSlug!==undefined)
  //   {
  //     setDisName(userSlug)
  //   } 
  // },[userSlug && userSlug!==undefined && userSlug!==""]) 
  // console.log(currentProfile && currentProfile.verify_slug ,'currentProfile')
  useEffect(() => {
    if (open && open === true) {
      setDisName(userSlug)
    }
  }, [open, userSlug])

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      const tkEncID = localStorage.getItem('userEncId');
      const payload = {
        "enc_id": tkEncID,
        "device_type": "web"
      };
      dispatch(talentLogout(payload));
      await logout();
      navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
      localStorage.removeItem('statususer');
      dispatch(clearGetProfileData())
      dispatch(clearProjectList())
      dispatch(ClearPublishSaveSelected())
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (currentUser !== "") {
      setTimeout(() => {
        const userPhotourl = localStorage.getItem('userPhotourl');
        setUpdateProfile(userPhotourl)
      }, 1000);
    }
  }, [currentUser]);

  const userPhotourl1 = localStorage.getItem('userPhotourl');
  useEffect(() => {
    if (userPhotourl1 !== "") {
      setTimeout(() => {
        setUpdateProfile(userPhotourl1)
      }, 1000);
    }
  }, [userPhotourl1]);

  console.log(openProfile, 'disNamedisNamedisName', disName)


  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar userPhotourl={updateProfile} />
      </IconButtonAnimate>

      <MenuPopover
        // className='Menupopover'
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >

        {(disName) && openProfile && openProfile === 'Yes' ?
          <MenuItem >
            <Link sx={{ width: '100%', textAlign: 'start' }} mt={'14px'} component={RouterLink} to={`${toPhoto}/${disName}`} target='_blank'>
              Profile
            </Link>
          </MenuItem> : null}


        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ my: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover >
    </>
  );
}
