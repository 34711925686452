import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Link, ListItemText, Typography, Tooltip, IconButton } from '@mui/material';
//
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useDispatch, useSelector } from '../../../redux/store';
import Iconify from '../../Iconify';
import { getForMenuCheck } from '../../../redux/slices/ChecksMenu';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { isExternalLink } from '..';


// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }) {
  const { title, path, info, children, isDisabled } = item;

  const renderContent = (
    <>
      {/* {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>} */}
      {console.log(isDisabled, 'isDisabled67465')}
      <ListItemTextStyle className='RootChild1' disableTypography primary={title} isCollapse={isCollapse} />
      {!isCollapse && (
        <>
          {info && info}
          {children && isDisabled && isDisabled === 'No' && <ArrowIcon open={isDisabled && isDisabled === 'No' ? open : ''} />}
        </>
      )}
    </>
  );

  if (children && isDisabled && isDisabled === 'No') {
    return (
      <ListItemStyle className='RootChild' style={{ color: active ? "#fff" : "#85a094" }} onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <>
      {isDisabled && isDisabled === 'No' ?
        <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
          {renderContent}
        </ListItemStyle>
        :
        <ListItemStyle
        // component={Link} href={path} target="_blank"
        //  rel="noopener"
        >
          {renderContent}
        </ListItemStyle>
      }
    </>
  ) : (
    <>
      {isDisabled && isDisabled === 'No' ?
        <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
          {renderContent}
        </ListItemStyle>
        :
        <ListItemStyle activeRoot={active} disableTypography>
          <Tooltip title="Coming Soon" placement="bottom-end">
            <IconButton style={{ color: '#ffffff', fontSize: '15px', marginLeft: '-8px', fontFamily: 'Poppins,sans-serif',borderRadius:'0px' }}>
              <span style={{ color: active ? "#fff" : "#85a094" }}>
                {renderContent}
              </span>
            </IconButton>
          </Tooltip>
        </ListItemStyle>
      }
    </>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen, blur, checkTkMenu }) {
  const { title, path, info, children } = item;
  const [passValue, setPassValue] = useState("")
  const [navPath, setNavPath] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    setPassValue(checkTkMenu)
  }, [checkTkMenu])

  const OnRouteMain = (path, e) => {
    // if(checkTkMenu && checkTkMenu.isSelected === true ){
    //   if (title === "Skillset"){
    //     navigate(path)
    //   }else if (title === "Profession"){
    //     navigate(path)
    //   }
    //   else if (title === "Physical Appearance"){
    //     navigate(path)
    //   }
    //   else if (title === "Languages"){
    //     navigate(path)
    //   }
    //   else if (title === "Education & Training"){
    //     navigate(path)
    //   }
    //   else if (title === "Portfolio"){
    //     navigate(path)
    //   }
    //   else if (title === "Profile Intro"){
    //     navigate(path)
    //   }
    //   else if (title === "Agent Info"){
    //     navigate(path)
    //   }
    //   else if (title === "Work Credits"){
    //     navigate(path)
    //   }
    // }
    if (title === "Skillset") {
      navigate(path)
    } if (title === "Profession") {
      navigate(path)
    }
    if (title === "Physical Appearance") {
      navigate(path)
    }
    if (title === "Languages") {
      navigate(path)
    }
    if (title === "Education & Training") {
      navigate(path)
    }
    if (title === "Portfolio") {
      navigate(path)
    }
    if (title === "Profile Intro") {
      navigate(path)
    }
    if (title === "Agent Info") {
      navigate(path)
    }
    if (title === "Work Credits") {
      navigate(path)
    }



    if (title === "Personal Information") {
      navigate(path)
    }
    if (title === "Category & Profession") {
      navigate(path)
    }
    if (title === "Job Search") {
      navigate(path)
    }
    if (title === "Job Seek") {
      navigate(path)
    }
    if (title === "Job Search") {
      navigate(path)
    }
    if (title === "Job Applications") {
      navigate(path)
    }
    if (title === "Recommendations") {
      navigate(path)
    }
    if (title === "Pause Job Search") {
      navigate(path)
    }
    if (title === "Followers") {
      navigate(path)
    }
    if (title === "Following") {
      navigate(path)
    }
    if (title === "Network") {
      navigate(path)
    }
    // if (title){
    //   navigate(path)
    // }
    // setNavPath(path)
    // console.log("asdfssahdj",checkTkMenu,title)
  }

  const renderContent = (
    <>
      <DotIcon active={active} sx={{ color: active && active === true ? '#FFC300' : '' }} />
      <Box >
        <ListItemText disableTypography primary={title} />
      </Box>

      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} sx={{ color: active && active === true ? '#FFC300' : '' }} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={passValue ? path : ""} target="_blank" rel="noopener" subItem>
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle onClick={e => OnRouteMain(path, e)} sx={{ color: active && active === true ? '#FFC300' : '' }} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 8,
          height: 8,
          borderRadius: '',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: '#ffffff',
            width: 4,
            height: 4,
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
